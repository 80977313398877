/* =================reset css starts here=================  */
* {
  margin: 0;
  padding: 0;
}
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
body,
html,
form,
fieldset {
  margin: 0;
  padding: 0;
  outline: none;
  border: 0;
}
a {
  text-decoration: none;
  border: 0;
  outline: 0;
}
ul {
  list-style: none;
}
a:focus,
input:focus,
textarea:focus,
*:focus {
  outline: 0 !important;
}

/* =================reset css ends here================= */

/* ================= custom scroll css start here================= */
[data-simplebar] {
  position: relative;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}
.simplebar-wrapper {
  overflow: hidden;
  width: inherit;
  height: inherit;
  max-width: inherit;
  max-height: inherit;
}
.simplebar-mask {
  direction: inherit;
  position: absolute;
  overflow: hidden;
  padding: 0;
  margin: 0;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: auto !important;
  height: auto !important;
  z-index: 0;
}
.simplebar-offset {
  direction: inherit !important;
  box-sizing: inherit !important;
  resize: none !important;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  padding: 0;
  margin: 0;
  -webkit-overflow-scrolling: touch;
}
.simplebar-content-wrapper {
  direction: inherit;
  box-sizing: border-box !important;
  position: relative;
  display: block;
  height: 100%;
  width: auto;
  max-width: 100%;
  max-height: 100%;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.simplebar-content-wrapper::-webkit-scrollbar,
.simplebar-hide-scrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.simplebar-content:after,
.simplebar-content:before {
  content: " ";
  display: table;
}
.simplebar-placeholder {
  max-height: 100%;
  max-width: 100%;
  width: 100%;
  pointer-events: none;
}
.simplebar-height-auto-observer-wrapper {
  box-sizing: inherit !important;
  height: 100%;
  width: 100%;
  max-width: 1px;
  position: relative;
  float: left;
  max-height: 1px;
  overflow: hidden;
  z-index: -1;
  padding: 0;
  margin: 0;
  pointer-events: none;
  flex-grow: inherit;
  flex-shrink: 0;
  flex-basis: 0;
}
.simplebar-height-auto-observer {
  box-sizing: inherit;
  display: block;
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 1000%;
  width: 1000%;
  min-height: 1px;
  min-width: 1px;
  overflow: hidden;
  pointer-events: none;
  z-index: -1;
}
.simplebar-track {
  z-index: 1;
  position: absolute;
  right: 0;
  bottom: 0;
  pointer-events: none;
  overflow: hidden;
}
[data-simplebar].simplebar-dragging .simplebar-content {
  pointer-events: none;
  user-select: none;
  -webkit-user-select: none;
}
[data-simplebar].simplebar-dragging .simplebar-track {
  pointer-events: all;
}
.simplebar-scrollbar {
  position: absolute;
  left: 0;
  right: 0;
  min-height: 10px;
}
.simplebar-scrollbar:before {
  position: absolute;
  content: "";
  background: #000;
  border-radius: 7px;
  left: 2px;
  right: 2px;
  opacity: 0;
  transition: opacity 0.2s linear;
}
.simplebar-scrollbar.simplebar-visible:before {
  opacity: 0.5;
  transition: opacity 0s linear;
}
.simplebar-track.simplebar-vertical {
  top: 0;
  width: 11px;
}
.simplebar-track.simplebar-vertical .simplebar-scrollbar:before {
  top: 2px;
  bottom: 2px;
}
.simplebar-track.simplebar-horizontal {
  left: 0;
  height: 11px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar:before {
  height: 100%;
  left: 2px;
  right: 2px;
}
.simplebar-track.simplebar-horizontal .simplebar-scrollbar {
  right: auto;
  left: 0;
  top: 2px;
  height: 7px;
  min-height: 0;
  min-width: 10px;
  width: auto;
}
[data-simplebar-direction="rtl"] .simplebar-track.simplebar-vertical {
  right: auto;
  left: 0;
}
.hs-dummy-scrollbar-size {
  direction: rtl;
  position: fixed;
  opacity: 0;
  visibility: hidden;
  height: 500px;
  width: 500px;
  overflow-y: hidden;
  overflow-x: scroll;
}
.simplebar-hide-scrollbar {
  position: fixed;
  left: 0;
  visibility: hidden;
  overflow-y: scroll;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

/* ================= custom scroll css ends here================= */

/* =================smartmenus css start here================= */
.navbar-nav.sm-collapsible .sub-arrow {
  position: absolute;
  top: 50%;
  right: 0;
  margin: -0.7em 0.5em 0 0;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
  padding: 0;
  width: 2em;
  height: 1.4em;
  font-size: 1.25rem;
  line-height: 1.2em;
  text-align: center;
}
.navbar-nav.sm-collapsible .sub-arrow::before {
  content: "+";
}
.navbar-nav.sm-collapsible .show > a > .sub-arrow::before {
  content: "-";
}
.navbar-dark .navbar-nav.sm-collapsible .nav-link .sub-arrow {
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-nav.sm-collapsible .has-submenu {
  padding-right: 3em;
}
.navbar-nav.sm-collapsible .nav-link,
.navbar-nav.sm-collapsible .dropdown-item {
  position: relative;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: inline-block;
  width: 0;
  height: 0;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-left: 0.3em solid transparent;
}
.fixed-bottom .navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow,
.fixed-bottom
  .navbar-nav:not(.sm-collapsible):not([data-sm-skip])
  .dropdown-toggle::after {
  border-top: 0;
  border-bottom: 0.3em solid;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item .sub-arrow,
.navbar-nav:not(.sm-collapsible):not([data-sm-skip])
  .dropdown-menu
  .dropdown-toggle::after {
  position: absolute;
  top: 50%;
  right: 0;
  width: 0;
  height: 0;
  margin-top: -0.3em;
  margin-right: 1em;
  border-top: 0.3em solid transparent;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.navbar-nav:not(.sm-collapsible) .dropdown-item.has-submenu {
  padding-right: 2em;
}
.navbar-nav .scroll-up,
.navbar-nav .scroll-down {
  position: absolute;
  display: none;
  visibility: hidden;
  height: 20px;
  overflow: hidden;
  text-align: center;
}
.navbar-nav .scroll-up-arrow,
.navbar-nav .scroll-down-arrow {
  position: absolute;
  top: -2px;
  left: 50%;
  margin-left: -8px;
  width: 0;
  height: 0;
  overflow: hidden;
  border-top: 7px solid transparent;
  border-right: 7px solid transparent;
  border-bottom: 7px solid;
  border-left: 7px solid transparent;
}
.navbar-nav .scroll-down-arrow {
  top: 6px;
  border-top: 7px solid;
  border-right: 7px solid transparent;
  border-bottom: 7px solid transparent;
  border-left: 7px solid transparent;
}
.navbar-nav.sm-collapsible .dropdown-menu .dropdown-menu {
  margin: 0.5em;
}
.navbar-nav:not([data-sm-skip]) .dropdown-item {
  white-space: normal;
}
.navbar-nav:not(.sm-collapsible) .sm-nowrap > li > .dropdown-item {
  white-space: nowrap;
}

/* =================smartmenus css ends here================= */

/* =================slick css ends here================= */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  position: relative;
  display: block;
  overflow: hidden;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}
.slick-track {
  position: relative;
  top: 0;
  left: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;
  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}
.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}
.slick-slide img {
  display: block;
  width: 100%;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/*.slick-loading .slick-list{background:#fff url('../images/ajax-loader.gif') center center no-repeat;}*/
.slick-prev,
.slick-next {
  z-index: 99;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  float: left;
  padding: 0;
  margin-top: -25px\9; /*lte IE 8*/
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  width: 62px;
  height: 63px;
}
.slick-prev:hover,
.slick-prev:focus,
.slick-next:hover,
.slick-next:focus {
  color: transparent;
  outline: none;
  background: transparent;
}
.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.3;
}
.slick-prev:before,
.slick-next:before {
  font-family: "font Awesome 5 Free";
  font-weight: 900;
  font-size: 50px;
  line-height: 1;
  opacity: 0.9;
  color: #fff;
  text-shadow: 0 5px 5px rgba(0, 0, 0, 0.2);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 0;
}
.slick-prev {
  left: 15px;
}
.slick-prev:before {
  content: "\f053";
}
.slick-next {
  right: 15px;
}
.slick-next:before {
  content: "\f054";
}
.slick-slider {
  margin-bottom: 0;
  padding-bottom: 45px;
}
.slick-dots {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  padding: 0;
  list-style: none;
  text-align: center;
  vertical-align: top;
}
.slick-dots li {
  position: relative;
  display: inline-block;
  margin: 0 0px;
  padding: 0;
  text-align: center;
  cursor: pointer;
  vertical-align: top;
}
.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  padding: 0;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #e0e0e0;
  opacity: 1;
  border: solid 1px #e0e0e0;
  border-radius: 50%;
  vertical-align: top;
  margin: 5px 6px;
}
.slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 1;
  background-color: #297ab9;
}

/*.white-dots .slick-dots li button {border-color:#fff; background:  #297AB9; opacity: 0.4;}
.white-dots  .slick-dots li.slick-active button, .white-dots  .slick-dots li:hover button{ background-color: #297AB9; opacity: 1;}*/
.full-height .slick-track {
  display: flex;
}
.full-height .slick-slide {
  height: auto;
}
/* =================slick css end here=================  */

/* =================core css starts here================= */
body {
  padding-top: 82px;
  font-size: 14px;
  line-height: 26px;
  font-weight: 400;
  color: #000;
  font-family: "Open Sans", sans-serif;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body.has-map {
  padding-top: 120px;
}
.container {
  width: calc(100% - 100px);
  max-width: 1470px;
}
.container-fluid {
  width: calc(100% - 35px);
  max-width: 100%;
}
a,
input,
button {
  display: inline-block;
  text-decoration: none;
  color: inherit;
  outline: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
a img {
  border: 0px none;
}
a:hover {
  outline: none;
  color: #297ab9;
  text-decoration: none;
}
a:active {
  outline: none;
  text-decoration: none;
}
a:focus {
  outline: none;
  outline-offset: 0px;
  text-decoration: none;
  color: inherit;
}
::-webkit-input-placeholder {
  color: #000;
  opacity: 0.5;
}
::-moz-placeholder {
  color: #000;
  opacity: 0.5;
}
:-moz-placeholder {
  color: #000;
  opacity: 0.5;
}
:-ms-input-placeholder {
  color: #000;
  opacity: 0.5;
}
.os-animation {
  opacity: 0;
}
.os-animation.animated {
  opacity: 1;
}
img {
  max-width: 100%;
  border: 0;
  height: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
  line-height: 1.2;
  font-weight: 700;
  color: inherit;
}
h1 {
  font-size: 33px;
}
h2 {
  font-size: 30px;
}
h3 {
  font-size: 28px;
}
h4 {
  font-size: 24px;
}
h5 {
  font-size: 22px;
}
h6 {
  font-size: 20px;
}
p {
  margin: 0px;
  padding: 0px;
  margin-bottom: 24px;
}
strong {
  font-weight: 700;
}
b {
  font-weight: 400;
}
p:last-child {
  margin-bottom: 0;
}
.btn {
  border-radius: 5px;
  font-weight: 600;
  padding: 10px 15px;
  min-width: 140px;
  font-size: 16px;
  line-height: 18px;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.btn2 {
  border-radius: 5px;
  font-weight: 600;
  padding: 20px 25px;
  min-width: 140px;
  font-size: 18px;
  text-align: center;
  line-height: 18px;
  box-shadow: none;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.btn-default,
.btn-default:focus {
  color: #fff;
  background-color: #297ab9;
  border: solid 1px #297ab9;
}
.btn-default:hover,
.btn-default:active {
  color: #fff;
  background-color: #205e8f;
  border-color: #297ab9;
}
.btn-primary,
.btn-primary:focus {
  color: #fff;
  background-color: #333;
  border: solid 1px #333;
}
.btn-primary:hover,
.btn-primary:active {
  color: #333;
  background-color: transparent;
  border-color: #333;
}
.btn-secondary,
.btn-secondary:focus {
  color: #297ab9;
  background-color: #f4f4f4;
  border: solid 1px #f4f4f4;
}
.btn-secondary:hover,
.btn-secondary:active {
  color: #fff;
  background-color: #297ab9;
  border-color: #f4f4f4;
}
.btn-white,
.btn-white:focus {
  color: #297ab9;
  background-color: #fff;
  border: solid 1px #297ab9;
}
.btn-white:hover,
.btn-white:active {
  color: #fff;
  background-color: #297ab9;
  border-color: #297ab9;
}
.btn-outline,
.btn-outline:focus {
  color: #297ab9;
  background-color: transparent;
  border: solid 2px #297ab9;
}
.btn-outline:hover,
.btn-outline:active {
  color: #fff;
  background-color: #297ab9;
  border-color: #297ab9;
}
.btn-link-text {
  color: #297ab9;
  font-weight: 400;
  font-size: 18px;
}
.btn-link-text:hover,
.btn-link-text:focus,
.btn-link-text:active {
  box-shadow: none;
  text-decoration: none;
}
.btn-blue,
.btn-blue:focus {
  color: #fff;
  background-color: #0c4475;
  border: solid 1px #0c4475;
}
.btn-blue:hover,
.btn-blue:active {
  color: #0c4475;
  background-color: #fff;
  border-color: #0c4475;
}
.btn.btn-block {
  min-width: 100%;
}
.btn.btn-xs {
  min-width: 115px;
  font-size: 13px;
  padding: 10px;
  line-height: 13px;
}
.btn + .btn {
  margin-left: 25px;
}
.btn.focus,
.btn:focus {
  box-shadow: none !important;
}
ul:not([class]) {
  list-style: none;
  margin-bottom: 15px;
  text-align: left;
  line-height: 22px;
}
ul:not([class]) > li {
  padding-left: 24px;
  position: relative;
  margin-bottom: 14px;
}
ul:not([class]) > li:last-child {
  margin-bottom: 0;
}
ul:not([class]) > li:before {
  content: "";
  position: absolute;
  left: 2px;
  top: 8px;
  background: #c4c4c4;
  height: 8px;
  width: 8px;
  border-radius: 50%;
}
.grey-bg {
  background: #f5f5f5;
}
.close {
  opacity: 1;
}
.close:hover {
  opacity: 0.7;
}
.no-shadow {
  box-shadow: none !important;
}

/* =================core css ends here================= */

/* =================global css start here================= */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(1turn);
    transform: rotate(1turn);
  }
}
.btn-loading {
  transition: none;
}
.btn-loading img {
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}
.btn-link {
  min-width: auto;
  padding: 0;
  line-height: 26px;
  border-bottom-color: #297ab9;
  border-radius: 0;
  color: #297ab9;
}
.btn-link img {
  height: 11px;
  width: auto;
  object-fit: contain;
  margin-left: 8px;
}
.btn-link:hover,
.btn-link:active {
  text-decoration: none;
  color: #297ab9;
}
.btn-link.btn-link2 img {
  height: 8px;
  margin-left: 6px;
}
.btn.btn-block {
  min-width: 100%;
}
.ui-kit-container {
  width: 100%;
  max-width: 530px;
  margin: 0 auto;
}
.ui-kit-head {
  margin-bottom: 40px;
}
.ui-kit-head h1 {
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 46px;
  letter-spacing: -0.8px;
  margin-bottom: 0;
}
.ui-kit-body h6 {
  font-size: 13px;
  line-height: 14px;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #969696;
  margin-bottom: 16px;
}
.ui-kit-body h6 + hr {
  margin-bottom: 20px;
}
.form-group {
  margin-bottom: 32px;
  position: relative;
}
.schedule-box .form-group:last-child {
  margin-bottom: 0;
}
.form-control {
  height: 50px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 15px;
  color: #333;
  letter-spacing: 0.5px;
}
.form-control:focus {
  box-shadow: none;
  border-color: #297ab9;
}
textarea.form-control {
  height: 180px;
  resize: none;
}
select.form-control {
  background-image: url(../images/down-arrow.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px auto;
  background-position-x: calc(100% - 15px);
  -webkit-appearance: none;
  cursor: pointer;
}
.custom-input label:not(.error) {
  position: absolute;
  top: 18px;
  left: 16px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 400;
  color: #333;
  cursor: text;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  margin: 0;
  letter-spacing: 0.5px;
}
.custom-input.on label:not(.error),
.custom-input.show label:not(.error),
.custom-input label small {
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
  top: 8px;
}
.custom-input label.error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
}
.custom-input option {
  color: #3d3d3d;
}
.custom-input .watermark {
  color: rgba(0, 0, 0, 0.5);
}
.custom-input.on .form-control,
.custom-input.show .form-control {
  caret-color: #007bff;
  padding-top: 22px;
}
.custom-input.has-select label:not(.error) {
  opacity: 0;
  visibility: hidden;
}
.custom-input.has-select.on label:not(.error),
.custom-input.has-select.show label:not(.error) {
  opacity: 1;
  visibility: visible;
}
.form-check {
  padding-left: 35px;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  text-align: left;
}
.form-check input[type="checkbox"].form-check-input {
  display: none;
}
.form-check input[type="checkbox"] + .form-check-label {
  cursor: pointer;
}
.form-check input[type="checkbox"] + .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 4px;
  width: 20px;
  height: 20px;
  transition: all 0.2s ease-in;
}
.form-check
  input[type="checkbox"].form-check-input:checked
  + .form-check-label::before {
  border-color: #297ab9;
  background: #297ab9;
}
.form-check input[type="checkbox"] + .form-check-label::after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  display: block;
  background: url(../images/checkbox-tick-icon.svg) no-repeat center/10px 7px;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease-in;
}
.form-check
  input[type="checkbox"].form-check-input:checked
  + .form-check-label::after {
  opacity: 1;
  transform: scale(1);
}
.form-check
  input[type="checkbox"].form-check-input:disabled
  + .form-check-label::before {
  background: #f6f6f6;
}
.form-check input[type="radio"].form-check-input {
  display: none;
}
.form-check input[type="radio"] + .form-check-label {
  cursor: pointer;
}
.form-check input[type="radio"] + .form-check-label::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #e4e4e4;
  border-radius: 50%;
  transition: all 0.2s ease-in;
}
.form-check
  input[type="radio"].form-check-input:checked
  + .form-check-label::before {
  background: #297ab9;
  border-color: #297ab9;
}
.form-check input[type="radio"] + .form-check-label::after {
  content: "";
  position: absolute;
  left: 6px;
  top: 6px;
  width: 8px;
  height: 8px;
  display: block;
  background: #fff;
  border-radius: 50%;
  opacity: 0;
  transform: scale(0);
  transition: all 0.2s ease-in;
}
.form-check
  input[type="radio"].form-check-input:checked
  + .form-check-label::after {
  opacity: 1;
  transform: scale(1);
}
.badge-group {
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
}
.badge-info {
  color: #297ab9;
  background-color: #f2f2f2;
}
.badge-group .badge {
  margin: 2px 0;
  padding: 5px 25px 5px 8px;
  border-radius: 4px;
  font-size: 11px;
  line-height: 13px;
  font-weight: 400;
  position: relative;
  text-align: left;
  min-width: 59px;
}
.badge + .badge {
  margin-left: 8px;
}
.badge-group .badge [data-role="remove"] {
  cursor: pointer;
}
.badge-group .badge [data-role="remove"]:after {
  content: "";
  background: url(../images/badge-close-icon.svg) no-repeat center/contain;
  width: 12px;
  height: 12px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
}
.badge-group .tag {
  display: inline-block;
  vertical-align: baseline;
  background: #fff;
  border: 1px solid #297ab9;
  border-radius: 15px;
  font-size: 13px;
  line-height: 16px;
  letter-spacing: -0.3px;
  color: #297ab9;
  min-width: 65px;
  padding: 6px 12px;
  position: relative;
}
.badge-group .tag + .tag {
  margin-left: 12px;
}
.badge-group .tag [data-role="remove"] {
  cursor: pointer;
  background: url(../images/tag-close-icon.svg) no-repeat center/contain;
  width: 10px;
  height: 10px;
  display: inline-block;
  vertical-align: baseline;
  margin-left: 10px;
}
.alert {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  border: 0;
  border-radius: 6px;
  padding: 12px 57px 10px;
  margin-bottom: 10px;
}
.alert-icon {
  position: absolute;
  left: 11px;
  top: 11px;
}
.alert h5 {
  font-size: 15px;
  line-height: 18px;
  font-weight: 600;
  margin-bottom: 0px;
}
.alert p {
  margin-bottom: 0;
}
.alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 15px;
  right: 24px;
}
.alert-error {
  background: #feeaea;
  color: #fa3434;
}
.alert-info {
  background: #f1f8ff;
  color: #007bff;
}
.alert-success {
  background: #e5f7e7;
  color: #00b517;
}
.alert-warning {
  background: #fff3e7;
  color: #ff9017;
}
.alert.alert-sm {
  padding: 10px 36px;
}
.alert.alert-sm h5 {
  font-size: 13px;
}
.alert.alert-sm .alert-icon {
  top: 8px;
  left: 10px;
  width: 18px;
}
.alert.alert-sm .alert-right {
  top: 7px;
  right: 13px;
}
.alert-container {
  position: fixed;
  top: 50px;
  right: 20px;
  z-index: 99;
}
.alert.alert-white {
  background: #fff;
  border: 0;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  color: #000;
  font-size: 15px;
  line-height: 20px;
  padding: 22px 50px 22px 60px;
  font-weight: 400;
}
.alert.alert-white p {
  margin-bottom: 0;
}
.alert-success.alert-white .alert-icon {
  color: #26ca97;
}
.alert-white .alert-icon {
  margin-right: 0;
  font-size: 20px;
  position: absolute;
  left: 18px;
  top: 23px;
}
.alert-white .alert-right {
  padding-left: 0;
  margin-left: auto;
  position: absolute;
  top: 18px;
  right: 25px;
  font-size: 25px;
}
.alert-white .alert-close {
  font-size: 16px;
  line-height: 24px;
  font-weight: 300;
}
.alert.alert-white.alert-danger .alert-icon {
  color: #ba0303;
}
.modal {
  z-index: 9999999999;
}
.modal-dialog {
  max-width: 525px;
  margin-top: 45px;
}
.modal-content {
  border: 0;
  border-radius: 8px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 12px 20px;
}
.modal-header {
  border-bottom: none;
  position: relative;
  padding: 0;
}
.modal-body {
  padding: 15px 15px;
  padding-top: 0px;
}
.varification-popup {
  padding: 0 20px 35px;
}
.varification-popup h4 {
  line-height: 24px;
  margin-bottom: 30px;
}
.varification-popup p {
  font-size: 16px;
  line-height: 20px;
}
.load-msg {
  position: relative;
  color: #8798ad;
  font-style: italic;
  font-size: 16px;
  line-height: 20px;
  font-weight: 400;
  padding-left: 40px;
}
.load-msg .btn-loader {
  left: 0;
}
.modal-backdrop:before {
  content: "";
  width: 100%;
  height: 100%;
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.85;
}
.modal-backdrop:after {
  content: "";
  width: 100%;
  height: 100%;
  background: #2e5bff;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0.08;
}
.modal-alert-box p {
  margin-bottom: 10px;
  font-weight: 400;
}
.modal-action-btn {
  margin-top: 30px;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.btn-delete {
  background: #ba0303;
  color: #fff;
}
.btn-delete:hover,
.btn-delete:focus {
  background: #ba0303;
  color: #fff;
}
.modal-action-btn .btn + .btn {
  margin-left: 10px;
}
.alert-close {
  opacity: 0.75;
}

/* =================global css ends here================= */
.modal.left-collapse .modal-dialog {
  position: fixed;
  margin: auto;
  width: 100%;
  max-width: 450px;
  height: 100%;
  -webkit-transform: translate3d(0%, 0, 0);
  -ms-transform: translate3d(0%, 0, 0);
  -o-transform: translate3d(0%, 0, 0);
  transform: translate3d(0%, 0, 0);
}
.modal.left-collapse .modal-content {
  height: 100%;
  overflow-y: auto;
  border: 0;
  border-radius: 0;
  box-shadow: none;
  padding: 0;
}
.modal.left-collapse.fade .modal-dialog {
  left: -100%;
  -webkit-transition: opacity 0.3s linear, left 0.3s ease-out;
  -moz-transition: opacity 0.3s linear, left 0.3s ease-out;
  -o-transition: opacity 0.3s linear, left 0.3s ease-out;
  transition: opacity 0.3s linear, left 0.3s ease-out;
}
.modal.left-collapse.fade.show .modal-dialog {
  left: 0;
}

/*New Pagination start*/
.pagination-wrapper .page-link {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #333;
  border-radius: 4px;
  border-color: rgb(51 51 51 / 20%);
  padding: 4.5px 10px;
}
.pagination-wrapper .page-item.active .page-link {
  background-color: #0c4475;
  border-color: #0c4475;
  font-weight: 600;
}
.pagination-wrapper .page-item {
  padding: 0 5px;
}
.page-item.prev {
  padding-right: 25px;
}
.page-item.next {
  padding-left: 25px;
}
.pagination-wrapper .page-link:hover {
  color: #fff !important;
  text-decoration: none;
  background-color: #0c4475;
  border-color: #0c4475;
}
[aria-label="Previous"],
a[aria-label="Next"] {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0c4475;
  display: flex;
  align-items: center;
}
[aria-label="Previous"] > i {
  margin-right: 5px;
}
[aria-label="Next"] > i {
  margin-left: 5px;
}
[aria-label="Previous"] > i,
[aria-label="Next"] > i {
  display: none !important;
}

/* ================= main-container Start ================= */
.main-container {
  padding: 0px 15px;
}

/* ================= Header Start ================= */
header {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.1);
}
#header {
  color: #778790;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
}
.fixed #header {
}
.navbar {
  padding: 0;
  width: 100%;
  height: 82px;
}
.navbar-brand {
  width: 125px;
  padding: 0;
  margin: 0;
  position: relative;
  z-index: 999;
}
.navbar-expand-md .navbar-nav .nav-item {
  margin: 0 9px;
}
.navbar-expand-md .navbar-nav .nav-link {
  font-size: 13px;
  line-height: 18px;
  font-weight: 400;
  padding: 0;
  color: #778790;
  margin: 0;
}
.navbar-expand-md .navbar-nav .nav-item:hover .nav-link,
.navbar-expand-md .navbar-nav .nav-item.active .nav-link {
  color: #297ab9;
}
.dropdown-toggle::after {
  display: none !important;
}
.navbar-nav:not(.sm-collapsible) .nav-link .sub-arrow {
  display: none;
}
.nav-inside {
  width: 100%;
  padding: 0;
}
.header-right {
  margin: 0px 11px;
}
.hdr-logout img {
  width: 50px;
  height: auto;
  object-fit: cover;
  object-fit: cover;
  border-radius: 50%;
}
.nav-inside-content {
  padding: 30px 0;
}
#main {
  min-height: calc(100vh - 120px);
  padding-bottom: 60px;
  position: relative;
}
button#siderMenu {
  display: none !important;
}

/* ================= Header end ================= */

/* ================= Header-2 Start ================= */
.header-right .hdr-info {
  margin: 0 11px;
}
.header-right .hdr-info span {
  display: block;
  font-size: 14px;
  line-height: 19px;
}
.header-right .hdr-info a {
  color: #297ab9;
  font-size: 12px;
  line-height: 16px;
}
.has-header-top ul.navbar-nav {
  font-size: 14px;
  font-weight: 400;
}
.has-header-top .navbar-inside {
  padding-right: 15px;
}

/* ================= Header-2 Start ================= */
body.cart_page {
  padding-top: 120px;
}
.cart-container {
  width: 100%;
  min-height: calc(100vh - 180px);
}
.empty-cart-content {
  width: 100%;
  max-width: 500px;
  text-align: center;
  font-size: 14px;
  line-height: 21px;
  text-align: center;
  color: rgb(0 0 0 / 50%);
  margin: 0 auto;
}
.empty-cart-content h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 18px;
  color: #297ab9;
}
.ec-img-box {
  width: 184px;
  margin: 0 auto 30px;
}

/* ================= Header-3 Start ================= */
#header3 {
  color: #000;
  background: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 99999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
}
.ht-box {
  width: 100%;
}
.header-top {
  background: #297ab9;
  width: 100%;
  padding: 11px 0;
  color: #fff;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.5px;
}
.header-main {
  background: #fff;
}
.progressbar-box {
  width: 100%;
  max-width: 525px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
ul.progressbar li {
  width: auto;
  text-align: center;
  position: relative;
  padding: 0 110px 0 10px;
  display: flex;
  align-items: center;
}
ul.progressbar li:last-child {
  padding-right: 0;
}
ul.progressbar .progressbar-icon {
  width: 33px;
  height: 33px;
  border: 1px solid #979797;
  background: #fff;
  display: inline-block;
  border-radius: 50%;
  margin-right: 10px;
}
ul.progressbar li.active .progressbar-icon {
  box-shadow: 0 4px 2px 0 rgb(41 122 185 / 30%);
  border: 0;
}
ul.progressbar li.complete .progressbar-icon {
  border-color: #297ab9;
  background: #297ab9;
}
ul.progressbar li.active .progressbar-icon > svg > path {
  fill: #297ab9;
}
ul.progressbar li.complete .progressbar-icon > svg > path {
  fill: #fff;
}
ul.progressbar li.complete:not(:last-child):before {
  background: #297ab9;
}
ul.progressbar li span {
  display: inline-block;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #898989;
}
ul.progressbar li.active span,
ul.progressbar li.complete span {
  color: #297ab9;
}
ul.progressbar li:not(:last-child):before {
  content: "";
  width: 100px;
  height: 1px;
  background: #979797;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.user-intro-box span {
  font-weight: 600;
}
.header-top ul.query-list li + li {
  margin-left: 25px;
}
.header-top ul.query-list li {
}
.header-top ul.query-list li > i {
  margin-right: 6px;
}
.header-top ul.query-list li a {
  color: #fff;
}
.cart-icon {
  position: relative;
  cursor: pointer;
}
.price-icon {
  width: 17px;
  height: 17px;
  background: #297ab9;
  position: absolute;
  top: -11px;
  right: -5px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 13px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
}
.cart-icon > i {
  position: relative;
  cursor: pointer;
}
.cart-icon > i + span {
  display: inline-block;
  padding-left: 15px;
  font-size: 14px;
  line-height: 18px;
  color: #297ab9;
}

/* ================= Header-3 End ================= */
.form-check-outer .form-check + .form-check {
  margin-left: 25px;
}

/* ================= checkout page Start ================= */
.progressbar-container {
  display: none;
}
.progressbar-container {
  padding: 40px 0;
}
.content-container {
  padding: 79px 0;
}
.form-group.form-button2 {
  max-width: 402px;
}
.form-group.form-button2 .btn {
  padding: 20px 25px;
}
.cc-box strong {
  font-weight: 600;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgb(51 51 51 / 50%);
}

/* ================= checkout page End ================= */
ul.available-list li {
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  padding-left: 25px;
  font-size: 13px;
  color: #4f4f4f;
}
.al-icon {
  width: 15px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}
.product-details .add-to-cart-dropdown.dropdown,
.product-details .add-to-cart-dropdown.dropup {
  max-width: 170px;
}
.product-details-tab.nav {
  border: 0;
  width: calc(100% + 34px);
  margin-left: -17px;
  position: relative;
}
.product-details-tab.nav:before {
  content: "";
  width: calc(100% - 34px);
  height: 1px;
  background: #979797;
  position: absolute;
  top: 100%;
  left: 50%;
  right: 0;
  transform: translateX(-50%);
}
.product-details-tab.nav-tabs .nav-item {
  margin-bottom: 1px;
  padding: 0 17px;
}
.product-details-tab.nav-tabs .nav-link {
  border: 0;
  border-radius: 0;
  font-size: 18px;
  color: #000;
  font-weight: 400;
  position: relative;
  padding: 0 0 16px;
}
.product-details-tab.nav-tabs .nav-link.active:before,
.product-details-tab.nav-tabs .nav-link:hover:before {
  width: 100%;
  opacity: 1;
  visibility: visible;
}
.product-details-tab.nav-tabs .nav-link:before {
  content: "";
  width: 0%;
  height: 2px;
  background: #297ab9;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s ease-in;
}
.product-details-tab.nav-tabs .nav-link.active {
  font-weight: 600;
  color: #297ab9;
}
.product-details-tab.nav-tabs .nav-link:hover {
  color: #297ab9;
}
.product-details-container .tab-content {
  padding: 40px 0;
}
.product-details-container .seller-details li {
  position: relative;
}
.product-details-container .seller-details li {
  margin-bottom: 15px !important;
  position: relative;
}
.product-details-container .card-body {
  padding: 0;
  width: 65%;
}
.product-details-container .nav-tabs {
  display: none;
}
.product-details-container .table {
  margin-bottom: 0;
  color: #4f4f4f;
}
.card-body ul.hrs-operation {
  max-width: 300px;
}
.card-body ul.hrs-operation li {
  color: #4f4f4f;
  font-weight: 400;
}
.card-body ul.hrs-operation li p {
  font-size: 14px;
}
.card-body ul.hrs-operation li.active {
  font-weight: 600;
}
.product-details-container .table td,
.product-details-container .table th {
  border: 0;
  padding: 6px 15px;
  font-size: 12px;
}
.cr-head {
  margin-bottom: 25px;
}
.cr-progress-left {
  width: 300px;
}
.cr-progress-right {
  width: calc(100% - 300px);
  padding-left: 70px;
}
.cr-progress-left .progress {
  flex-grow: 1;
  margin-left: 25px;
}
.cr-progress-left .crp-item + .crp-item {
  margin-top: 20px;
}
.cr-progress-left .crp-item > span {
  font-size: 16px;
  color: #000;
}
.cr-progress-left .progress-bar {
  background: #ffc531;
}
.cr-progress-right p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 11px;
  color: #333;
}
.cr-progress-right h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 11px;
  color: #333;
}
.tab-body {
  max-width: 515px;
}
.large-modify-sidebar {
  max-width: 619px !important;
}
.large-modify-sidebar .tab-modify-box {
  padding: 43px 40px;
}
.large-modify-sidebar h3 {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 50px;
}
.tab-modify-box .form-group.form-button {
  margin-top: 30px;
}
.tab-header strong {
  font-weight: 600;
  display: inline-block;
  font-size: 13px;
  line-height: 18px;
  text-align: center;
  letter-spacing: 0.5px;
  text-transform: uppercase;
  color: rgb(51 51 51 / 50%);
}
.feedback_ratings {
  width: calc(100% + 50px);
  margin-left: -25px;
  margin-bottom: 44px;
}
.fr-left {
  padding: 0 25px;
  position: relative;
}
.fr-left:before {
  content: "";
  width: 1px;
  height: calc(100% - 5px);
  background: #c4c4c4;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.fr-right {
  padding: 0 25px;
}
.fr-right .feedback_rating + .feedback_rating {
  margin-left: 15px;
}
.feedback_rating {
  cursor: pointer;
  border: 1px solid #c4c4c4;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 6px 6px;
  font-size: 18px;
  color: #333333;
  width: 61px;
  height: 45px;
  text-align: center;
}
.feedback_rating.rating_active {
  border-color: #297ab9;
}
.feedback_rating > i {
  color: #ffc531;
  margin-left: 5px;
}
.review-box p {
  font-size: 16px;
  font-weight: 400;
  color: #333;
}
.review-box p:not(:last-child) {
  margin-bottom: 20px;
}
.review-box h6 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 15px;
  color: #333;
}
.review-box {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  padding: 35px 28px;
}
.review-box + .review-box {
  margin-top: 35px;
}
.review-box span.reviews-text {
  font-size: 21px;
  letter-spacing: 0.5px;
}
.review-box .star-list {
  font-size: 27px;
  margin-bottom: 25px;
}
@media (min-width: 768px) {
  .product-details-container .nav-tabs {
    display: flex;
  }
  .product-details-container .card {
    border: none;
  }
  .product-details-container .card .card-header {
    display: none;
  }
  .product-details-container .card .collapse {
    display: block;
  }
}

/* ================= Byer Start ================= */
.conetnt-box h3 {
  font-size: 21px;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 53px;
}
.sidebar {
  width: 245px;
}
.main-content-box {
  width: calc(100% - 245px);
  padding: 30px 0 30px 45px;
}
.sidebar {
  width: 245px;
  border-right: 1px solid #e0e0e0;
  padding: 30px 45px 30px 0px;
}
.menu-item.active a.menu-link,
.menu-item:hover a.menu-link {
  background: rgb(41 122 185 / 10%);
  color: #297ab9;
}
.menu-item.active a.menu-link > svg,
.menu-item:hover a.menu-link > svg {
  fill: #297ab9;
}
ul.menu-list li.menu-item:not(:last-child) {
  margin-bottom: 15px;
}
.menu-link {
  width: 100%;
  position: relative;
  font-size: 14px;
  white-space: nowrap;
  line-height: 18px;
  display: inline-block;
  padding: 12px 32px 12px 50px;
  border-radius: 6px;
  border-radius: 6px;
}
.menu-link > svg {
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  fill: #000;
}
.empty-order-box {
  width: 100%;
  max-width: 518px;
}
.eob-icon {
  width: 184px;
  margin: 0 auto 38px;
}
.empty-order-box h4 {
  font-size: 24px;
  line-height: 18px;
  color: #297ab9;
  font-weight: 600;
  margin-bottom: 24px;
}
.empty-order-box p {
  font-size: 14px;
  line-height: 21px;
  color: rgb(0 0 0 / 50%);
}
.empty-order-box .seprator {
  margin: 35px auto 45px;
}
.account-details-box {
  max-width: 670px;
}
.account-details-box .form-group.form-button {
  margin: 50px 0 0;
}
.address-box {
  width: 100%;
  max-width: 275px;
}
.address-box h4 {
  color: #297ab9;
  font-weight: 600;
  font-size: 18px;
  margin-bottom: 30px;
}
.address-text-box {
  padding: 23px;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 21px;
  margin-bottom: 60px;
}
.address-text-box h4 {
  font-size: 14px;
  font-weight: 700;
  color: #000;
  margin-bottom: 3px;
}
.address-text-box .btn-links {
  color: #297ab9;
  font-size: 13px;
  text-decoration: none;
  font-size: 13px;
  line-height: 21px;
  font-weight: 700;
}
.address-text-box .btn-links {
  color: #297ab9;
  font-size: 13px;
  text-decoration: none;
  font-weight: 600;
}
.btn-links + .btn-links {
  margin-left: 25px;
}
.address-billing-box {
  cursor: pointer;
  padding: 23px;
  width: 100%;
  height: 189px;
  border: 1px dashed #e0e0e0;
  border-radius: 6px;
  text-align: center;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
}
.plus-icon {
  width: 25px;
  margin: 0 auto 21px;
}
.address-billing-box h6 {
  font-size: 14px;
  color: #297ab9;
  font-weight: 600;
  margin-bottom: 0;
}
.address-box .form-group.form-button {
  margin-top: 60px;
}
.order-box {
  width: 100%;
  border: 1px solid #e0e0e0;
  border-radius: 6px;
}
.order-box + .order-box {
  margin-top: 35px;
}
.ob-head {
  width: 100%;
  padding: 21px 15px 20px 25px;
  background: rgb(41 122 185 / 11%);
}
.obh-left h6 {
  font-size: 14px;
  font-weight: 600;
  color: #297ab9;
}
.obh-right {
  text-align: right;
}
.obh-right h6 {
  font-size: 13px;
  color: #297ab9;
  font-weight: 600;
}
.obh-left p {
  font-size: 12px;
  color: #297ab9;
}
.ob-body {
  padding: 27px 0 0px 25px;
}
.btn.btn-white {
  font-size: 12px;
  padding: 8px 15px;
}
.obh-right .btn.btn-white {
  min-width: 162px;
}
.table-responsive .small.table tr td:first-child span {
  width: 18px;
  height: 37px;
}
.table-responsive .small.table tr:first-child td:nth-child(2) b {
  font-size: 15px;
}
.table-responsive .small.table thead th {
  padding: 10px 15px 15px 15px;
}
.table-responsive .small.table tr td {
  padding: 20px 15px 20px;
}
.table-responsive .small.table tr td:first-child {
  padding-left: 15px;
}
.table-responsive .small.table tr:last-child td b {
  font-size: 18px;
  font-weight: 600;
}
.table-responsive .small.table.no-total-td tr:last-child td b {
  font-size: 16px;
  font-weight: 400;
}
.small.table .sub-code {
  line-height: 1.5;
}
.table-responsive .small.table td[colspan="2"] {
  padding-left: 0 !important;
}
.table-responsive .small.table tr:last-child td {
  border-bottom: 0;
}
.stars-icon span > i {
  color: #c4c4c4;
  font-size: 17px;
}
.stars-icon span.filles > i {
  color: #ffc531;
}
.stars-icon span + span {
  margin-left: 6px;
}
.code-dispute {
  color: #f81818 !important;
  font-size: 13px;
  font-weight: 400;
}
.small .dropdown-menu {
  min-width: 555px !important;
  padding: 0 !important;
  border-radius: 5px !important;
  border: 1px solid #d8d8d8 !important;
}
.dm-header-text {
  margin-bottom: 0;
  padding: 29px 20px 15px 32px !important;
  position: relative;
}
.dm-header-text .close-icon {
  position: absolute;
  right: 20px;
  top: 15px;
}
.table.small form {
  padding: 29px 50px 25px 32px !important;
}
.table.small textarea.form-control {
  height: 105px;
}
.dm-header-text > span {
  font-size: 14px;
  font-weight: 600;
}
.code-resolved {
  font-size: 13px;
  color: #27ae60;
}
td .color-text {
  font-size: 15px;
  color: rgb(0 0 0 / 60%);
}
table.table.small.no-total-td tr:last-child td {
  border-bottom: 1px solid #e0e0e0 !important;
}
.small.no-total-td .dropdown-menu {
  min-width: 100% !important;
}

/* ================= Byer Start ================= */

/* ================= Header Start ================= */
.fixed .search-bar-container {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.05);
}
.search-bar-container {
  height: 74px;
  position: fixed;
  top: 118px;
  right: 0;
  left: 37.5%;
  background: #fff;
  z-index: 9;
}
.search-bar-content {
  margin-top: 20px;
  width: calc(100% - 80px);
  background-color: #f5f9fc;
  border-radius: 5px;
}
.input-group input {
  background: transparent;
  border: none;
  border-radius: 0px;
  height: 50px;
  font-size: 13px;
  font-weight: 400;
  padding: 15px;
}
.input-group input:focus {
  background: #f5f9fc;
}
.input-group-text {
  padding: 0;
  padding-left: 20px;
  background-color: transparent;
  border: none;
}
.search-bar-content .input-group:last-child {
  border-left: 1px solid #dbe3ff;
}
.search-bar-content .input-group:last-child input {
  border-radius: 0 5px 5px 0;
}
.search-bar-content .input-group:first-child {
  width: 100%;
}
.search-bar-content .input-group .close {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  z-index: 5;
}
.search-bar-content .input-group:last-child {
  border-top: none;
}
.view-type {
  display: flex;
  width: 80px;
  margin-top: 20px;
  max-width: 100%;
  margin-left: 13px;
}
.view-type li a {
  display: block;
  height: 30px;
}
.view-type li a b {
  width: 22px;
  background: #bdbdbd;
  height: 2px;
  top: 13px;
  display: flex;
  line-height: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
}
.view-type li {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  float: left;
}
.view-type li a b::after {
  content: "";
  width: 18px;
  height: 2px;
  background: inherit;
  position: absolute;
  top: -5px;
}
.view-type li a b::before {
  content: "";
  width: 18px;
  height: 2px;
  background: inherit;
  position: absolute;
  bottom: -5px;
}
.view-type li .grid-type {
  transform: rotate(90deg);
}
.view-type li.active b {
  background-color: #297ab9;
}
.result-item-number .view-type {
  display: none;
}
.result-item-number {
  padding-top: 15px;
  padding-right: 8%;
}
.result-item-number span {
  margin-top: 0;
  display: block;
  color: #4f4f4f;
}

/* ================= Header end ================= */

/* ================= no-result-foound Start ================= */
.no-result-container {
  padding-top: 175px;
}
.no-result-img img {
  margin-bottom: 30px;
}
.no-result-content {
  text-align: center;
  padding: 30px 0;
}
.no-result-text h2 {
  font-weight: 400;
  margin-bottom: 10px;
  line-height: 31px;
}
.no-result-text p {
  font-size: 13px;
  max-width: 375px;
  margin: 0 auto;
  line-height: 26px;
}

/* ================= no-result-foound end ================= */

/* ================= map Start ================= */
body.has-map {
  padding-left: 37.5%;
}
.has-map #header,
.has-map #header3 {
  left: 37.5%;
}
.has-map #mainNav {
  left: 37.5%;
}
.map {
  width: 37.5%;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  overflow: hidden;
}
.map img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.has-map .container {
  width: calc(100% - 30px);
}

/* ================= list-item Start ================= */
.list-item-container {
  padding-top: 176px;
}
ul.dots-list li {
  position: relative;
  padding-left: 16px;
  font-size: 12px;
  color: #4f4f4f;
}
ul.dots-list li:before {
  content: "";
  width: 8px;
  height: 8px;
  background: #297ab9;
  position: absolute;
  left: 0;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}
.list-details-star .seller-name {
  margin-bottom: 0;
  margin-right: 10px;
}
.list-details-header i {
  color: #ffc531;
}
.list-img {
  width: 150px;
}
.list-details {
  width: calc(100% - 150px);
}
.list-box {
  padding: 25px 0;
}
.filter-list-container ul.list-content li.list-item {
  border-bottom: 1px solid #e4e4e4;
  padding: 0 15px;
}
.main-content.no-padd {
  padding: 0 !important;
}
.list-price {
  text-align: right;
}
.list-details-header {
  width: calc(100% - 220px);
  padding: 0 40px;
}
.list-bottom-details {
  width: 220px;
}
.add-to-cart-dropdown.dropdown,
add-to-cart-dropdown.dropdup {
  flex-grow: 1;
}
.add-to-cart-dropdown.dropdown > button,
.add-to-cart-dropdown.dropdup > button {
  height: 100%;
  width: 100%;
  flex-grow: 1;
  font-size: 13px;
  padding: 9px 15px;
}
.no-total-td .add-to-cart-dropdown.dropdown > button,
.no-total-td .add-to-cart-dropdown.dropup > button {
  width: auto;
}
.add-to-cart-dropdown button > img {
  margin-left: 5px;
}
.add-to-cart-dropdown .dropdown-menu {
  min-width: 100%;
  background: #fff;
  border: 1px solid #d8d8d8;
  border-radius: 5px;
  margin-top: 7px;
}
.list-dropdown-box .border-btn {
  margin-right: 7px;
}
.list-dropdown-box {
  margin-top: 28px;
}
.list-details-header h3 {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  color: #000;
}
.list-details-header p {
  line-height: 26px;
  font-size: 13px;
  color: #4f4f4f;
}
.list-price h4 {
  font-size: 18px;
  margin-bottom: 5px;
  color: #000;
}
.list-price .avability {
  font-size: 13px;
  color: #898989;
}
.grid-item-container {
  padding-top: 80px;
}
.grid-content {
  width: calc(100% + 40px);
  margin-left: -20px;
  min-width: 100%;
}
.grid-item {
  width: 33.33%;
  padding: 20px;
  position: relative;
  display: flex;
  flex-flow: column;
  border: 1px solid #e4e4e4;
  border-top: 0;
  border-left: 0;
}
.grid-img {
  position: relative;
  width: 100%;
  padding: 0;
  margin-bottom: 20px;
  height: 0;
  display: flex;
  align-items: center;
  padding-bottom: 100%;
}
.grid-img > a {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}
.grid-img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.grid-details .seller-name {
  margin-bottom: 0px;
  color: #4f4f4f;
  font-size: 12px;
}
.grid-details .grid-details-header h3 {
  line-height: 26px;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 4px;
  color: #000;
  min-height: 52px;
}
.grid-price h4 {
  font-size: 17px;
  margin-right: 13px;
  margin-bottom: 0px;
}
.grid-price {
  width: 100%;
  color: #000;
}
.grid-price .avability {
  line-height: 20px;
  font-size: 13px;
  color: #898989;
  margin-bottom: 0;
}
.star-list i {
  color: #ffc531;
}
.grid-star p + i {
  margin-left: 15px;
}
del {
  color: #da1a1a;
  font-weight: 300;
  font-size: 14px;
  line-height: 1;
}
.reviews-text {
  margin-left: 15px;
  font-size: 13px;
}
span.reviews-text small {
  margin-left: 5px;
}
.reviews-text small {
  font-size: 13px;
  color: #297ab9;
}
.gh-icon {
  height: 100%;
  display: flex;
  align-items: center;
  flex-grow: 1;
  text-align: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 600;
}
.star-list {
  margin-bottom: 12px;
}
.border-btn {
  width: 60px;
  height: 50px;
  border: 1px solid #c4c4c4;
  background: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 7px;
  text-align: center;
  line-height: 45px;
}
.gh-icon img {
  margin: 10px 0 0 15px;
}
.grid-hover-content {
  padding-top: 25px;
  margin-top: auto;
}
.grid-hover-content > .dropdown > button {
  height: 100%;
  flex-grow: 1;
  width: 100%;
  font-size: 13px;
}
.grid-hover-content > .dropdown {
  flex-grow: 1;
}
.grid-hover-content > .dropdown > button > img {
  /* margin-top:8px; */
  margin-left: 10px;
}
.atc-dropdown .drop-link {
  width: 100%;
  padding: 7px 19px;
}
.atc-dropdown .drop-link svg {
  margin-right: 15px;
}
.btn-info,
.btn-info:hover,
.btn-info:active {
  color: #000;
  background-color: transparent;
  border: 0;
}
.btn-info.focus,
.btn-info:focus,
.btn-info:not(:disabled):not(.disabled).active,
.btn-info:not(:disabled):not(.disabled):active,
.show > .btn-info.dropdown-toggle {
  outline: none !important;
  color: #000;
  background: transparent;
  box-shadow: none;
}
.btn-info:not(:disabled):not(.disabled).active:focus,
.btn-info:not(:disabled):not(.disabled):active:focus,
.show > .btn-info.dropdown-toggle:focus {
  box-shadow: none;
}
.popover-content {
  display: none;
}
.popover {
  border: 0;
  box-shadow: 5px 15px 25px rgba(0, 0, 0, 0.1);
}
.popover .arrow:before {
  border-color: transparent;
}
.form-check-outer .form-check input[type="checkbox"] + label {
  color: #4f4f4f;
  font-size: 12px !important;
}

/* ================= footer Start ================= */
#footer,
#footer1 {
  background: #f5f9fc;
  color: #4f4f4f;
  padding: 21px 0;
  font-size: 13px;
  letter-spacing: 0.5px;
  line-height: 18px;
  font-weight: 400;
  position: absolute;
  width: 100%;
  bottom: 0;
}
#footer.footer.sticky {
  position: fixed;
  left: 37.5%;
  right: 0;
  width: auto;
  bottom: 0;
  z-index: 9999;
}

/* ====================== PRODUCT PAGE =================== */

/* ================= breadcrumb Start ================= */
.breadcrumbs li a img {
  width: 8px;
  margin-right: 10px;
}
.breadcrumbs li a {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #297ab9;
  line-height: 47px;
  padding: 0 10px;
  display: inline-block !important;
}

/* ================= breadcrumb End ================= */

/* ================= product Start ================= */
.product-img {
  margin-bottom: 40px;
}
.product-slide .slick-slide img {
  display: block;
  width: 100%;
  max-width: 207px;
  margin: 0px auto;
}
.product-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 70px 0;
  margin-bottom: 23px;
}
.thumbnail-list {
  overflow-y: hidden;
}
.thumbnail-list .thumbnail-item {
  min-width: 80px;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 30px;
  padding: 4px;
}
.thumbnail-list .thumbnail-item:last-child {
  margin-right: 0;
}
.thumbnail-list .thumbnail-item.active {
  border: 1px solid #297ab9;
  border-radius: 5px;
}
.thumbnail-list .slick-slide img {
  width: 80px !important;
  height: 80px !important;
}
.product-title h4 {
  font-weight: 300;
  margin-bottom: 16px;
}
.product-details {
  padding: 24px 0 20px 0;
}
.product-price {
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 13px;
}
.avability {
  margin-bottom: 15px;
  color: #828282;
  font-size: 16px;
  line-height: 21px;
  font-weight: 500;
}
.product-list ul {
  margin: 0;
}
.product-list {
  color: #333;
  border-bottom: 1px solid #ededed;
  margin-bottom: 18px;
}
.product-list:last-child {
  border: none;
}
.product-description {
  color: #4f4f4f;
  margin-bottom: 13px;
}
.product-description h5 {
  font-size: 14px;
  margin-bottom: 0;
  font-weight: 400;
  line-height: 18px;
}
.link {
  color: #297ab9 !important;
  cursor: pointer;
  transition: 0.3s ease;
}
.link:hover {
  text-decoration: underline;
}
.product-details .pd-profile-box {
  width: 50px;
  height: 50px;
}
.product-details .pd-profile-box img {
  width: 100%;
  object-fit: cover;
}
.seller-man {
  margin-bottom: 14px;
}
.seller-profile-name {
  margin-left: 12px;
  line-height: 20px;
  font-size: 16px;
}
.product-list li {
  margin-bottom: 15px !important;
  position: relative;
}
.seller-details li {
  padding-left: 35px !important;
}
.seller-details li img {
  margin-right: 12px;
  position: absolute;
  left: 4px;
  top: 5px;
}
.delivery li {
  margin-bottom: 7px !important;
  font-size: 13px;
  line-height: 21px;
}
.delivery li img {
  margin-right: 8px;
}

/* ================= product End ================= */

/* ================= search content Start ================= */
.search-header h5 {
  line-height: 33px;
  font-weight: 400;
  margin-bottom: 0;
  padding-bottom: 7px;
  display: inline-block;
  position: relative;
  color: rgba(41, 122, 185, 1);
}
.search-header {
  border-bottom: 1px solid rgba(224, 224, 224, 1);
}
.search-header h5::before {
  content: "";
  position: absolute;
  width: 100%;
  left: 0;
  bottom: -3px;
  bottom: -1px;
  border: 1px solid rgba(41, 122, 185, 1);
}
.search-content {
  padding: 22px 0 55px;
}
.search-content .search-list {
  margin-bottom: -40px;
}
.search-item {
  width: 25%;
  padding: 0px 14px;
  margin-bottom: 40px;
}
.search-item-img {
  padding: 38px;
  text-align: center;
  margin-bottom: 10px;
}
.search-item-info .slr-name {
  font-size: 12px;
  color: rgba(79, 79, 79, 1);
}
.search-item-info p {
  margin-bottom: 8px;
  font-size: 16px;
  line-height: 26px;
  margin-top: 3px;
  color: #000;
}
.item-price {
  font-size: 16px;
  font-weight: 600;
  margin-right: 15px;
  color: #000;
}
.search-item-lower {
  display: flex;
}
.item-Availablity {
  font-size: 13px;
  color: #898989;
}

/* ================= search content End ================= */
.search-item-content .input-group {
  border-radius: 4px;
}
.product-list ul .dropdown-menu {
  width: 316px !important;
  padding: 20px 25px;
  border: none;
  box-shadow: 5px 15px 25px 0 rgb(0 0 0 / 10%);
  background: #fff;
  margin-top: -20px;
  margin-bottom: -20px;
}
.product-list ul .dropdown-menu.large {
  width: 330px !important;
  font-size: 14px;
}
.hrs-operation h6 {
  font-size: 16px;
  margin-bottom: 10px;
}
.hrs-operation li {
  display: flex;
  justify-content: space-between;
  padding-right: 30px;
}
.hrs-operation li p {
  margin-bottom: 7px;
  font-size: 12px;
}
.hrs-operation button.close {
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
}

/* ================= search content End ================= */
.new-header .navbar-inside {
  padding-left: 115px;
}
.new-header .carets {
  position: absolute;
  right: -18px;
  top: 50%;
  transform: translateY(-50%);
}
.new-header .dropdown-menu {
  background: #fff;
  box-shadow: 5px 25px 15px 0 rgb(0 0 0 / 10%);
  font-size: 12px;
  color: #4f4f4f;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  padding: 0;
  min-width: 215px !important;
  max-width: 100% !important;
}
.new-header ul.navbar-nav {
  color: #828282;
  font-size: 16px;
  font-weight: 400;
}
.new-header ul.navbar-nav .nav-item:first-child .nav-link {
  margin-left: 0;
}
.new-header ul.navbar-nav .nav-link {
  position: relative;
  line-height: 82px;
  padding: 0 !important;
  margin: 0 35px;
}
.new-header .dropdown-item.active,
.new-header .dropdown-item:active,
.new-header .dropdown-item:focus,
.new-header .dropdown-item:hover {
  color: #297ab9;
  text-decoration: none;
  background-color: rgb(196 196 196 / 20%);
}
.new-header .hdr-logout span.carets {
  width: 16px;
  right: -15px;
}
.new-header .hdr-logout {
  height: 82px;
}
a.search-text {
  white-space: nowrap;
  margin-left: 29px;
  text-decoration: underline;
  color: #297ab9;
}
.btn-default.outline,
.btn-default.outline:focus {
  color: #297ab9;
  background-color: transparent;
  border: solid 1px #297ab9;
}
.btn-default.outline:hover {
  color: #fff;
  background: #297ab9;
}
.pending {
  color: #de7777;
}
.fulfilled {
  color: #27ae60;
}
.tab-modify-box .qps-group.ams-group .price {
  width: 50%;
}
.tab-modify-slidebar.tab-modify-slidebar2 {
  max-width: 736px;
}
.disputed-text {
  color: #ff0000 !important;
}
ul.tab-query-list {
  width: calc(100% + 30px);
  margin-left: -15px;
}
ul.tab-query-list li {
  width: 50%;
  padding: 25px 15px;
}
.tql-box {
  font-size: 12px;
  color: rgb(0 0 0 / 80%);
  display: flex;
  flex-flow: column;
}
.tql-box h5 {
  font-size: 16px;
  font-weight: 600;
  color: #000;
  margin-bottom: 15px;
}
.tql-box h6 {
  font-size: 12px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.dispute-dropdown .dropdown-menu {
  min-width: 455px !important;
  width: 100%;
}
.form-group-box {
  padding: 15px;
}
.form-group-box .btn-default {
  color: #fff;
  background-color: #297ab9;
  border: solid 1px #297ab9;
}
.form-group-box p {
  font-size: 12px;
  color: #000;
}
.tab-modify-box .drop-content-box {
  padding: 0;
}
.form-group-box p > span {
  color: #4d4d4d;
}
ul.tpi-list li {
  width: auto;
  padding: 5px 15px;
}
ul.tpi-list li > img {
  width: 18px;
}
ul.tpi-list li + li {
  margin-left: 25px;
}

/* ================= cart page ================= */
.cc-left-content {
  width: 65%;
  padding-right: 40px;
}
.cc-right-content {
  width: 35%;
  padding-left: 40px;
  border-left: 1px solid #e0e0e0;
}
.cc-right-content h3,
.cc-left-content h3 {
  font-size: 18px;
  line-height: 18px;
  font-weight: 600;
  color: #000;
}
.table-box {
  margin-bottom: 35px;
}
.table-responsive2 tr:first-child td {
  color: #000;
  border-top: 1px solid #e0e0e0;
  padding-top: 26px;
}
.table-responsive2 tbody tr:last-child td {
  padding-bottom: 30px !important;
}
.table-responsive2 tr td {
  font-size: 14px;
  line-height: 18px;
  color: #808080;
  padding: 16px 0;
}
.table-responsive2 {
  border-collapse: collapse;
  width: 100%;
}
tfoot {
  border-bottom: 1px solid #e0e0e0;
  font-size: 18px;
  font-weight: 600;
  color: #000;
}
tfoot tr td {
  padding: 30px 0 30px !important;
  font-size: 18px !important;
}
.checkout-btn .btn {
  padding: 20px 20px;
  font-size: 18px;
}
.table-dropdown .dropdown-menu {
  transform: translate3d(5px, 35px, 0px) !important;
}
.table-dropdown {
  display: block;
}
.table-dropdown .dropdown-menu {
  background: #fff;
  padding: 0;
  box-shadow: 5px 25px 15px 0 rgb(0 0 0 / 10%);
  min-width: 252px;
  border-radius: 5px;
  border: 1px solid #d8d8d8;
  z-index: 999;
}
.drop-link:hover,
.drop-link:active,
.drop-link:focus {
  color: #297ab9;
  text-decoration: none;
  background-color: rgb(196 196 196 / 20%);
}
.drop-link {
  width: 100%;
  padding: 6px 15px;
  font-size: 12px;
  color: #4f4f4f;
}
.drop-content-header {
  padding: 0 15px;
  margin-bottom: 15px;
}
.dispute-dropdown .drop-content-header {
  padding: 28px 15px 0;
}
.drop-content-header h4 {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.drop-content-box {
  padding: 21px 0;
}
.drop-link svg {
  margin-right: 11px;
}
.close-icon {
  cursor: pointer;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255 255 255 / 70%);
  z-index: 999999;
  display: none;
}
.show-menu .overlay,
.show.overlay {
  display: block;
}
.table2 {
  width: 100%;
  margin-bottom: 1rem;
  color: #000;
}
.table2 b {
  font-weight: 600;
  font-size: 16px;
}
.table2 tr td:nth-child(2) b {
  font-size: 15px;
}
.table2 tr td:nth-child(4) b {
  font-size: 16px;
}
.table2 .table-heading {
  font-size: 13px;
  font-weight: 600;
}
.table2 thead th:first-child {
  padding-left: 0;
}
.table2 thead th {
  padding-bottom: 5px;
  color: #8798ad;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  padding: 0px 20px 15px;
  font-size: 13px;
}
.table2 tr td {
  padding: 16px 20px 20px;
  vertical-align: middle;
  font-size: 13px;
}
.table2 td,
.table2 th {
  border-top: none;
  border-bottom: 1px solid #e0e0e0;
}
.table2 tr td:nth-child(2) {
  padding-left: 0;
}
.table2 tr td:nth-child(2) > b {
  white-space: nowrap;
}
.table2 tr:last-child td {
  border-bottom: 0;
}
.table2 tr td:first-child span {
  width: 31px;
  height: 68px;
}
.table2 tr td:first-child {
  padding-left: 0px;
  width: 105px;
}
.table2 tr td:first-child span {
  width: 31px;
  height: 63px;
  display: inline-block;
}
.table2 tr td:first-child .coupon-code-box span {
  width: auto;
  height: auto;
}
.has-total-row.table2 tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}
.sub-code {
  font-size: 12px;
  color: rgb(0 0 0 / 50%);
}
.smp-table-box .table2 tr:last-child td {
  border-bottom: 1px solid #e0e0e0;
}
.smp-table-box table.table2 {
  margin-bottom: 50px;
}
.smpf-right .table-box {
  margin-bottom: 0;
}
.resolved-text {
  font-size: 12px;
  color: #27ae60;
}
button.data-button {
  width: 30px;
  background: #ececec;
  color: #535353;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  font-size: 20px;
  border: 0;
  height: 30px;
}
.number-input {
  width: 50px;
  padding: 0 12px;
  vertical-align: top;
  text-align: center;
  outline: none;
  border-radius: 4px;
  margin: 0 5px;
  border: 1px solid #ececec;
  background: transparent;
  font-size: 14px;
  color: #000;
  height: 30px;
}
.table-dropdown button {
  font-size: 13px;
  color: #828282;
  border: 0;
  background: transparent;
}
.table-add-dropdown b {
  font-weight: 600;
  color: #000;
}
.table-notes-text {
  color: #898989;
}
.notes > b {
  font-weight: 600;
  color: #000;
}
.table-add-icon {
  display: inline-block;
  cursor: pointer;
}
.coupon-text {
  font-size: 16px;
  color: #297ab9;
  letter-spacing: 0.5px;
}
form.promo-code-form {
  width: 100%;
  min-width: 357px;
}
form.promo-code-form .form-control {
  max-width: 295px;
  flex-grow: 1;
}
.b-core-ui-select {
  width: 50px;
  height: 50px;
  background: #297ab9;
  border: 0;
  border-radius: 5px;
  outline: none;
  margin-left: 12px; /*flex-grow:1;*/
}
.table-add-dropdown {
  position: relative;
}
.table-add-dropdown .table-dropdown {
  position: static;
}
.table-add-dropdown .table-dropdown .dropdown-menu {
  min-width: 100%;
  width: 100%;
}
.table-dropdown-form {
  padding: 15px 31px 0px;
}
.table-dropdown-form .btn {
  background: #297ab9;
  color: #fff;
}
.tab-modify-slidebar.advance-modify-search-sidebar {
  left: -100%;
  right: auto;
}
.tab-modify-slidebar.advance-modify-search-sidebar.show {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.tab-modify-slidebar.view-order-modify-search-sidebar.show {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.stripe-account-container {
  padding-top: 38px;
  height: 100%;
}
.sac-content-box {
  padding: 50px 40px;
}
.sac-img {
  width: 184px;
  margin: 0 auto 40px;
}
.seprator {
  width: 194px;
  height: 1px;
  background: #e0e0e0;
  margin: 35px auto 70px;
}
.sac-content-box h4 {
  font-size: 36px;
  line-height: 54px;
  color: #297ab9;
  font-weight: 600;
}
.sac-content-box p {
  font-size: 14px;
  line-height: 21px;
  color: rgb(0 0 0 / 50%);
  margin-bottom: 0;
}
.sac-btn {
  width: 100%;
  max-width: 365px;
  margin: 0 auto;
}
.sac-btn a + a {
  margin: 25px 0 0 !important;
}

/* ====================== cart page =================== */
.header-intro-box {
  width: 100%;
  background: #ff6262;
  color: #fff;
  font-size: 16px;
  line-height: 18px;
  padding: 10px 0;
  text-align: center;
}
.header-intro-box a {
  color: #fff;
}
.header-intro-box a:hover {
  text-decoration: underline;
}

/* ====================== review page =================== */
.product-list-item-content .table-responsive .table tr td:nth-child(2) {
  padding-left: 20px;
}
.product-list-item-content .table tr td small {
  font-size: 12px;
  display: block;
  color: rgb(51 51 51 / 70%);
}
.order-search-item-container {
  margin-bottom: 36px;
}
.form-group.search_price {
  width: 100%;
  max-width: 200px; /*margin-top: 50px;*/
}
.search_price .btn {
  padding: 15px 20px;
}
.success-modal-popup .modal-dialog {
  max-width: 1024px;
}
.smp-icon {
  width: 125px;
  margin: 0 auto 25px;
}
.smp-box h4 {
  color: #297ab9;
  font-size: 36px;
  margin-bottom: 0;
  line-height: 1.5;
}
.smp-box h6 {
  font-size: 24px;
  color: #297ab9;
}
.smp-footer {
  width: calc(100% + 60px);
  margin-left: -30px;
}
.smpf-left {
  width: 50%;
  padding: 0 30px;
}
.smpf-left a + a {
  margin: 25px 0 0 !important;
}
.smpf-left .btn {
  padding: 20px 25px;
  font-size: 18px;
  font-weight: 600;
}
.smpf-right {
  width: 50%;
  padding: 0 30px;
}
.smpf-right h3 {
  font-size: 18px;
  color: #000;
  font-weight: 600;
}
.ccr-text-box {
  font-size: 14px;
  line-height: 21px;
  color: rgb(0 0 0 / 60%);
}
.ccr-text-box h5 {
  line-height: 21px;
  color: #000;
  margin-bottom: 0;
  font-size: 14px;
}
.ccr-text-box {
  margin-bottom: 50px;
}
.ccr-text-box span {
  font-size: 13px;
  color: #000;
}
.ccr-text-box span > b {
  font-weight: 600;
}
.question_icon {
  width: 15px;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 9;
}
tr .form-check-group .form-check {
  display: inline-block;
}
.form-check-group label.form-check-label {
  white-space: nowrap;
}
tr .form-check-group .form-check + .form-check {
  margin-top: 18px;
}
.form-check-group {
  margin-top: 20px;
}
.question_icon .dropdown-menu {
  background: #fff;
  border-radius: 5px;
  border-color: #e6e6e6;
  box-shadow: 5px 25px 5px 0 rgb(0 0 0 / 10%);
  padding: 11px 23px;
  font-size: 13px;
  line-height: 26px;
  color: #333;
  min-width: 260px;
}
.success-modal-popup .modal-body {
  padding: 0;
}
.success-modal-popup .modal-content {
  padding: 100px 90px 100px;
}
.modal-backdrop {
  z-index: 99999;
}

/* ====================== review page =================== */

/* ====================== dashboard-01  start =================== */
.dashboard-container {
  padding-top: 88px;
}
.submenu-nav-list {
  overflow-x: auto;
}
.submenu-nev-item a {
  line-height: 18px;
}
.submenu-nev-item {
  margin-right: 44px;
  line-height: 58px;
  white-space: nowrap;
}
.submenu-nev-item:last-child {
  margin-right: 0;
}
.submenu-container {
  background: #f5f9fc;
  position: fixed;
  width: 100%;
  top: 82px;
  z-index: 99;
}
.submenu-nev-item.active a {
  color: #297ab9;
  border-bottom: 3px solid;
  font-weight: 600;
}
.submenu-nev-item a {
  line-height: 57px;
  font-size: 16px;
  font-weight: 400;
  color: #828282;
}
.form-container {
  padding-bottom: 60px;
}
.seller-info {
  margin-bottom: 20px;
}
.seller-info .seller-info {
  margin-bottom: 25px;
}
.seller-info .seller-img {
  position: relative;
  width: 80px !important;
  height: 80px;
}
.seller-info .seller-img img {
  width: 100%;
  height: auto;
  object-fit: cover;
}
.seller-img .editable {
  width: 24px;
  height: 24px;
  background: #fff;
  position: absolute;
  border-radius: 50%;
  border: 2px solid #f2f2f2;
  text-align: center;
  line-height: 17px;
  top: 0px;
  right: 0px;
}
.seller-img .editable img {
  width: 12px;
  height: 12px;
}
#country-code {
  width: 80px;
  margin-right: 11px;
}
.form-group .verified {
  font-size: 14px;
  color: #27ae60;
  position: absolute;
  top: 50%;
  right: -80px;
  display: flex;
}
.form-group .verified img {
  margin-right: 5px;
}
.form-check-group .form-check {
  margin-right: 30px;
}
.form-check-group .form-check:last-child {
  margin-right: 0;
}
.save-change-btn .btn:last-child {
  color: #297ab9;
}
.form-group > input + span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9;
}

/* ====================== dashboard-01  end =================== */

/* ====================== operation-container start =================== */
.operation-container {
  padding-bottom: 50px;
}
.operation-list .operation-item {
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  font-size: 16px;
  line-height: 66px;
}
.operation-list .operation-item .day {
  font-weight: 600;
  width: 56px;
}
.operation-list .operation-item .small {
  margin: 0px 15px;
  color: #828282;
}
.operation-list .timing-panel,
.operation-list .operation-item .small {
  line-height: 30px;
}
.close-day {
  color: #4f4f4f;
  letter-spacing: 0.5px;
}

/* ===================== operation-container end =================== */

/* ====================== schedule-container start =================== */
.schedule-container {
  padding-top: 8px;
}
.schedule-content {
  padding-bottom: 50px;
}
.schedule-list {
  padding-bottom: 29px;
  border-bottom: 1px solid #e0e0e0;
  min-height: 80px;
}
.schedule-list .schedule-day {
  width: 100px;
}
.schedule-list + .schedule-list {
  margin-top: 25px;
}
.form-check.type2 input[type="checkbox"] + .form-check-label::before {
  border-color: #4d4d4d;
}
.form-check.type2 input[type="checkbox"] + .form-check-label::after {
  background: url(../images/check-dark.svg) no-repeat center/10px 7px;
}
.form-check.type2
  input[type="checkbox"].form-check-input:checked
  + .form-check-label::before {
  background-color: #fff;
  border-color: #4d4d4d;
}
.form-check.type2 input[type="checkbox"] + label {
  font-size: 16px !important;
  color: #000;
  margin-right: 35px;
}
.form-control.small {
  width: 120px;
  padding: 10px;
}
.schedule-time span.small,
.schedule-time .space {
  margin: 0px 13px;
  text-align: center;
  display: flex;
  align-items: center;
}
.schedule-time span small {
  color: #828282;
}
.add-more-field {
  color: #297ab9;
  margin-left: 15px;
}
.delete-this-field img {
  margin-left: 15px;
  width: 20px;
}

/* ====================== schedule-container end =================== */

/* ====================== product-listing start =================== */
.search-item-content .input-group {
  max-width: 400px;
  background: #f5f9fc;
}
.search-item-content .result-number {
  margin-right: 20px;
}
.product-list-item-container {
  padding: 12px 0 50px;
}
.table-responsive .table thead th:first-child {
  padding-left: 0;
}
.table-responsive .table thead th {
  /* white-space: nowrap; */
  padding-bottom: 5px;
  color: #8798ad;
  font-weight: 600;
  border-bottom: 1px solid #e0e0e0;
  padding: 10px 20px;
  font-size: 13px;
}
.table-responsive .table tr td {
  padding: 13px 20px 5px;
  vertical-align: middle;
  font-size: 14px;
  color: #000; /* white-space: nowrap; */
  padding-bottom: 10px;
}
.table-responsive .table tr td:nth-child(2) {
  padding-left: 0;
}
.table-responsive .table tr td:last-child {
  /* min-width: 100px; */
}
.table-responsive .table tr td.editable {
  text-align: right;
}
.table-responsive .table tr td b {
  font-size: 16px;
}
.table-responsive .table tr td .code {
  font-size: 13px;
  color: #828282;
}
.table-responsive .table tr td:first-child {
  padding-left: 0px;
  width: 50px;
}
.w25txtright {
  width: 25%;
  text-align: right;
}
.table-responsive .table tr td:first-child span {
  width: 48px;
  height: 48px;
  background-color: #f2f2f2;
  border-radius: 8px;
  display: block;
  text-align: center;
  line-height: 46px;
}
.table-responsive .editable .delete {
  margin-left: 15px;
}
.table-responsive .editable .delete img {
  min-width: 20px;
}
.table td,
.table th {
  border-top: none;
  border-bottom: 1px solid #e0e0e0;
}
.table-bottom nav {
  margin-top: 20px;
}
.pagination .page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #297ab9;
}
.pagination .page-link:hover {
  background-color: transparent;
}
.pagination .page-link {
  width: 24px;
  height: 24px;
  text-align: center;
  margin: 0 2px;
  border: 0;
  padding: 5px;
  font-size: 13px;
  color: #000;
  border-radius: 4px;
  background: transparent;
  line-height: 14px;
}
.pagination .page-link.prev,
.pagination .page-link.next {
  color: #297ab9;
  font-size: 13px;
  line-height: 15px;
  margin: 0px 15px;
}
.pagination .page-link:focus {
  box-shadow: none;
}
.table_wrap.table-responsive {
  overflow: inherit;
}

/* ====================== contact-slidebar start =================== */
body.toggled-tab::before {
  content: "";
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 9999;
  top: 0;
}
body.toggled-tab {
  position: fixed;
  width: 100%;
}
.tab-modify-slidebar {
  width: 100%;
  max-width: 550px;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #fff;
  z-index: 9999999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.tab-modify-box {
  width: 100%;
  height: 100%;
  padding: 43px 65px;
  overflow: auto;
  position: relative;
}
.tab-header a {
  position: absolute;
  top: 0;
  right: 0;
}
.tab-header {
  position: relative;
  padding-right: 50px;
}
.toggled-tab .tab-modify-slidebar {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.tab-modify-slidebar h3 {
  font-weight: 400;
  margin-bottom: 40px;
}
.tab-modify-box .qps-group .form-group:first-child {
  margin-bottom: 0;
}
.tab-modify-box .qps-group .qty {
  width: 90px;
  margin-right: 15px;
}
.tab-modify-box .qps-group .price {
  width: 150px;
  margin-right: 15px;
}
.tab-modify-box .qps-group .sales-tex {
  width: 150px;
}
.custom-upload label:not([class]) {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}
.custom-upload label:not([class]) span {
  margin-left: 5px;
  color: #bdbdbd;
}
.btn-upload,
.btn-upload:hover {
  border: 1px dashed #e0e0e0;
  font-weight: 400;
  flex-flow: row;
  color: #d9d9d9;
  line-height: 30px;
  margin-bottom: 0;
  border-radius: 5px;
}
.tab-modify-box .btn-upload {
  line-height: 48px;
}
.form-group .note {
  display: block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #bdbdbd;
  margin-top: 7px;
}
.btn-upload strong {
  margin: 0px 7px;
  color: #297ab9;
  font-weight: 600;
}
.delete-img-container {
  margin-bottom: 30px;
  margin-top: 25px;
}
.delete-img-container li .delete-img-box {
  width: 70px;
  margin-right: 20px;
  position: relative;
  border-radius: 5px;
}
.delete-img-container li:last-child .delete-img-box {
  margin-right: 0;
}
.delete-img-container .delete-img {
  position: absolute;
  background: #fff;
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  border: 1px solid #f2f2f2;
}
.delete-img-container li .delete-img-box img {
  border-radius: 5px;
  overflow: hidden;
}
.form-group.price .input-group input {
  padding: 5px 10px;
}
.form-group.price input:focus {
  background-color: transparent;
}
.tab-modify-box .qps-group .price .input-group-text {
  padding: 0 0 0 12px;
}
.form-group.price .input-group input {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 10px 5px 25px;
  color: #333;
  letter-spacing: 0.5px;
}
.form-group.price .input-group-prepend {
  position: absolute;
  height: 100%;
}
.form-group.price .form-control:focus {
  box-shadow: none;
  border-color: #297ab9 !important;
}
.input-group-prepend + div {
  width: 1%;
  flex: 1 1 auto;
}

/* ====================== product-listing end =================== */

/* ====================== contact-slidebar start =================== */
body.toggled-tab::before {
  content: "";
  width: 100%;
  min-height: 100vh;
  background: rgba(255, 255, 255, 0.7);
  position: fixed;
  z-index: 99999;
  top: 0;
}
body.toggled-tab {
  position: fixed;
  width: 100%;
}
.contact-slidebar-container {
  width: 100%;
  max-width: 550px;
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #fff;
  z-index: 99999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  box-shadow: 0 0 25px rgba(0, 0, 0, 0.1);
}
.contact-slide-inner {
  width: 100%;
  height: 100%;
  padding: 43px 65px;
  overflow: auto;
  position: relative;
}
.tab-header a {
  position: absolute;
  top: 0;
  right: 0;
}
.tab-header {
  position: relative;
  padding-right: 50px;
}
.address-box .form-group.sidebar-btn,
.account-details-box .form-group.sidebar-btn {
  margin-top: 50px;
}
input.sidebar-default-btn.btn-block,
.account-details-box input.sidebar-default-btn {
  outline: none;
  border: 0;
}
.sidebar-default-btn {
  background: #297ab9;
  border: 0;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  text-transform: capitalize;
  padding: 17px 15px;
  border-radius: 5px;
  min-width: 200px;
  text-align: center;
}
.sidebar-default-btn:hover,
.sidebar-default-btn:active {
  background-color: #205e8f;
  color: #fff;
}
.form-group.sidebar-btn {
  margin-bottom: 0;
  margin-top: 20px;
}
.tab-header h3 {
  font-size: 28px;
  font-weight: 400;
}
.toggled-tab .contact-slidebar-container {
  right: 0;
}
.tab-modify-slidebar h3 {
  font-weight: 400;
  margin-bottom: 40px;
}
.contact-slide-inner .qps-group .form-group:first-child {
  margin-bottom: 0;
}
.contact-slide-inner .qps-group .qty {
  width: 90px;
  margin-right: 15px;
}
.contact-slide-inner .qps-group .price {
  width: 150px;
  margin-right: 15px;
}
.contact-slide-inner .qps-group .sales-tex {
  width: 150px;
}
.custom-upload label:not([class]) {
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 1px;
}
.custom-upload label:not([class]) span {
  margin-left: 5px;
  color: #bdbdbd;
}
.btn-upload,
.btn-upload:hover {
  border: 1px dashed #e0e0e0;
  font-weight: 400;
  flex-flow: row;
  color: #d9d9d9;
  line-height: 30px;
  margin-bottom: 0;
  border-radius: 5px;
}
.contact-slide-inner .btn-upload {
  line-height: 48px;
}
.form-group .note {
  display: block;
  font-size: 11px;
  line-height: 16px;
  letter-spacing: 0.6px;
  color: #bdbdbd;
  margin-top: 7px;
}
.btn-upload strong {
  margin: 0px 7px;
  color: #297ab9;
  font-weight: 600;
}
.delete-img-container {
  margin-bottom: 30px;
  margin-top: 25px;
}
.delete-img-container li .delete-img-box {
  width: 70px;
  margin-right: 20px;
  position: relative;
  border-radius: 5px;
}
.delete-img-container li:last-child .delete-img-box {
  margin-right: 0;
}
.delete-img-container .delete-img {
  position: absolute;
  background: #fff;
  width: 24px;
  cursor: pointer;
  height: 24px;
  top: -10px;
  right: -10px;
  border-radius: 50%;
  text-align: center;
  line-height: 19px;
  border: 1px solid #f2f2f2;
}
.delete-img-container li .delete-img-box img {
  border-radius: 5px;
  overflow: hidden;
}
.form-group.price .input-group input {
  padding: 5px 10px;
}
.form-group.price input:focus {
  background-color: transparent;
}
.contact-slide-inner .qps-group .price .input-group-text {
  padding: 0 0 0 12px;
}
.form-group.price .input-group input {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 16px;
  padding: 5px 10px 5px 25px;
  color: #333;
  letter-spacing: 0.5px;
}
.form-group.price .input-group-prepend {
  position: absolute;
  height: 100%;
}
.form-group.price .form-control:focus {
  box-shadow: none;
  border-color: #297ab9 !important;
}
.grid-star p + i {
  color: #ffc531;
}

/* ====================== product-listing end =================== */
.btn.btn-sm {
  padding: 7px 14px;
}
.showing-result .btn-outline {
  font-size: 13px;
  line-height: 17px;
}
.showing-result {
  font-size: 14px;
  color: #4f4f4f;
  letter-spacing: 0.5px;
  white-space: nowrap;
  margin-left: 32px;
}
.custom-modal .modal-content {
  padding: 0;
  box-shadow: 5px 15px 25px rgb(0 0 0 / 10%);
  border-radius: 5px;
}
.custom-modal .modal-dialog {
  max-width: 1025px;
}
.custom-modal .modal-header {
  padding: 25px 50px 25px 50px;
  border-bottom: 1px solid #e0e0e0;
  height: 103px;
  align-items: center;
}
.custom-modal .modal-title {
  margin-bottom: 0;
  font-size: 21px;
  line-height: 18px;
  color: #000;
  font-weight: 400;
}
ul.product-nav-list li {
  padding: 0 25px;
  position: relative;
}
ul.product-nav-list li:not(:last-child):after {
  content: "";
  width: 12px;
  height: 6px;
  background-image: url(../images/chevron-left-1.svg);
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
ul.product-nav-list li .pn-link:before {
  content: "";
  width: 100%;
  height: 2px;
  background: #297ab9;
  position: absolute;
  top: 100%;
  left: 0;
  opacity: 0;
  visibility: hidden;
}
ul.product-nav-list li.active .pn-link:before {
  opacity: 1;
  visibility: visible;
}
ul.product-nav-list li .pn-link {
  font-size: 16px;
  line-height: 84px;
  font-weight: 400;
  position: relative;
}
ul.product-nav-list li.active .pn-link {
  font-weight: 600;
  color: #297ab9;
}
.product-nav-box {
  position: absolute;
  left: 50%;
  padding-top: 15px;
  transform: translateX(-50%);
  margin-left: 40px;
}
.custom-modal .close {
  position: absolute;
  right: 44px;
  padding: 0;
  margin: 0;
  width: 37px;
  top: 38px;
}
.custom-modal .close > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.custom-modal .modal-body {
  padding: 50px 50px 92px;
}
.modal-icon-box {
  width: 40px;
  margin-bottom: 30px;
}
.custom-modal .modal-body h4 {
  font-size: 18px;
  line-height: 1;
  color: #000;
  margin-bottom: 15px;
}
.modal-text-box {
  width: 100%;
  max-width: 507px;
  color: rgba(0, 0, 0, 0.85);
  font-size: 16px;
  margin-bottom: 30px;
}
.modal-btn-box .btn {
  padding: 13px 15px;
  min-width: 205px;
}
.custom-modal .modal-footer button.btn.btn-xs {
  min-width: 150px;
  font-size: 18px;
  line-height: 1;
  padding: 15px 10px;
}
.custom-modal .modal-footer {
  padding: 20px 50px;
}
#choosefile {
  position: relative;
}
#chooseFile {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  z-index: 1;
}
.custom-modal .modal-body h5 {
  font-size: 16px;
  font-weight: 600;
  margin-top: -10px;
}
.file-upload-modal .modal-body,
.map-modal-content .modal-body {
  padding-bottom: 57px;
}
.file-upload-modal .modal-btn-box {
  margin-top: 174px;
}
.mcb-left label {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0;
}
.mcb-left label > span {
  font-weight: 400;
  color: rgb(0 0 0 / 50%);
  display: inline-block;
  padding-left: 18px;
}
.map-content-box + .map-content-box {
  margin-top: 30px;
}
.mcb-left {
  width: 40%;
}
.mcb-right {
  width: 60%;
}
.step-content-box h5 {
  font-size: 21px !important;
  line-height: 18px;
  font-weight: 600 !important;
  color: #297ab9;
  margin-bottom: 0;
}
ul.import-details-list {
  width: 100%;
  max-width: 700px;
  margin: 83px auto 0;
}
ul.import-details-list li {
  width: 33.33%;
  padding: 15px;
}
.id-box b {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 18px;
  display: inline-block;
}
.id-box p {
  font-size: 16px;
  color: #000;
}

/* ====================== product-popup start =================== */
.map-product-popup {
  /*position: absolute;left: 50%;*/
  background: #fff;
  padding: 25px 22px;
  width: 400px; /*top: 24%;*/
  box-shadow: 0 10px 24px 0 rgba(0, 0, 0, 0.19);
  border-radius: 5px; /*transform: translateX(-50%); */
  z-index: 99;
}
.map-product-popup:before {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  border-width: 20px 13px 0px 13px;
  border-color: transparent;
  border-style: solid;
  border-top-color: #fff;
  transform: translateX(-50%);
}
.product-popup-left {
  display: inline-block;
  width: 132px;
}
.product-popup-right {
  width: calc(100% - 160px);
}
.product-popup-details .seller-name {
  margin-bottom: 2px;
  color: #4f4f4f;
  font-size: 12px;
  line-height: 16px;
  margin-top: 5px;
}
.product-popup-details .product-popup-details-header h3 {
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 7px;
  color: #000;
}
.product-popup-price h4 {
  font-size: 17px;
  margin-right: 10px;
  margin-bottom: 0px;
}
.product-popup-price {
  width: 100%;
  color: #000;
}
.product-popup-price .avability {
  line-height: 20px;
  font-size: 13px;
  color: #898989;
  margin-bottom: 5px;
}
.product-popup-details a {
  font-size: 13px;
  font-weight: 600;
}
.map-marker-img {
  position: absolute;
  top: 58%;
  left: 22px;
}
.map-custum-zoom {
  position: absolute;
  left: 30px;
  top: 18px; /* background: #297AB9; */
  border-radius: 4px;
  margin-bottom: 2px;
  height: 64px;
  width: 32px;
}
.map-custum-zoom a {
  width: 32px;
  height: 32px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #297ab9;
  border-radius: 0 0 3px 3px;
}
.map-custum-zoom a:first-child {
  /* border-bottom: 1px solid #E9E9E9; */
  margin-bottom: 1px;
  border-radius: 3px 3px 0 0;
}
.map-custum-zoom a::before {
  content: "";
  width: 16px;
  height: 3px;
  background: #fff;
  position: absolute;
  border-radius: 2px;
}
.map-custum-zoom a::after {
  content: "";
  width: 16px;
  height: 3px;
  background: #fff;
  position: absolute;
  border-radius: 2px;
  transform: rotate(90deg);
}
.map-custum-zoom a.zoom-out::after {
  display: none;
}

/* ====================== product-popup start =================== */
.large-modify-sidebar .form-group.sidebar-btn {
  margin-top: 40px;
}

/*******************************PAGE END***********************************/
.new-search-row .input-group {
  background: #f5f5f5;
  max-width: 434px;
  border-radius: 5px;
  overflow: hidden;
  width: auto;
  flex-grow: 1;
}
.basic-addon-box {
  margin-left: 40px;
}
.new-search-row {
  width: auto;
  padding-left: 10px;
  padding-right: 15px;
  flex-grow: 1;
}
.has-map .header-right-content {
  width: 80px;
  margin-left: auto;
}
.new-search-row img.close {
  right: 20px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 9999;
  cursor: pointer;
}
.basic-addon-box {
  font-size: 13px;
  width: 120px;
  white-space: nowrap;
}
.basic-addon-box img {
  margin-right: 10px;
}
.new-search-row .input-group input {
  padding: 10px 50px 10px 10px;
  letter-spacing: 0;
}
.new-search-row .input-group input::placeholder {
  color: #333;
}
.header-right-content .cart-icon {
  margin-right: 6px;
}
.sbc-box {
  padding: 22px 0;
}
.sbc-box .view-type {
  margin-top: 0;
}
.popover {
  z-index: 99999 !important;
}

/* ================= Login Start ================= */
.member-container {
  width: 100%;
  height: 100vh;
  display: flex;
}
.logo-text {
  color: #fff !important;
}
.member-left {
  width: 500px;
  background: #297ab9;
  padding: 57px 80px 57px 47px;
  font-size: 13px;
  line-height: 26px;
  font-weight: 600;
  color: #fff;
}
.logo-area {
  width: 180px;
  margin-bottom: 56px;
}
.member-left h2 {
  font-size: 28px;
  line-height: 37px;
  letter-spacing: 0.5px;
  margin-bottom: 4px;
  padding-right: 14%;
}
ul.check-list {
  margin-top: 23px;
}
ul.check-list li {
  width: 100%;
  position: relative;
  padding-left: 24px;
  font-size: 13px;
  line-height: 26px;
  font-weight: 400;
  color: #fff;
}
ul.check-list li:not(:last-child) {
  margin-bottom: 6px;
}
ul.check-list li:before {
  content: "";
  width: 15px;
  height: 15px;
  background-image: url("../images/check-icon.svg");
  position: absolute;
  left: 0;
  top: 3px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.member-right {
  width: calc(100% - 500px);
  padding: 0 50px;
  overflow: auto;
}
.signin-form-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 160px 0;
  position: relative;
}
.signin-form {
  max-width: 420px;
  width: 100%;
}
.signin-form h2 {
  font-size: 27px;
  color: #000;
  margin-bottom: 10px;
}
.already-login {
  font-size: 14px;
  letter-spacing: 0.5px;
  color: #4f4f4f;
  margin-bottom: 25px;
}
.sep-login {
  color: #297ab9;
}
.form-group {
  margin-bottom: 20px;
}
label {
  font-size: 13px;
  color: #333;
  font-weight: 600;
}
.input-group > input + span {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 999;
}
.input-group input {
  padding-right: 50px;
  width: 100%;
}
.hide {
  display: none;
}
.toggle .hide {
  display: block;
}
.toggle .show {
  display: none;
}
.toggle-password {
  font-size: 12px;
  line-height: 16px;
  color: #828282;
  font-style: normal;
  cursor: pointer;
}
.question-box {
  cursor: pointer;
}
.custom-dropdown-outer {
  position: relative;
}
.custom-dropdown {
  position: absolute;
  top: calc(100% + 20px);
  right: 0;
  width: 300px;
  background: #fff;
  padding: 11px 23px;
  border-radius: 5px;
  box-shadow: 5px 15px 25px rgb(0 0 0 / 10%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s ease-in;
}
.custom-dropdown-outer .custom-dropdown.show {
  opacity: 1;
  visibility: visible;
  top: calc(100% + 7px);
}
.form-check {
  padding-left: 0;
}
.form-check input[type="checkbox"] {
  display: none;
}
.form-check input[type="checkbox"] + label {
  display: inline-block;
  position: relative;
  padding-left: 34px;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #828282;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.form-check input[type="checkbox"] + label:before {
  content: "";
  display: block;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid #4d4d4d;
  position: absolute;
  left: 0;
  top: 0;
  border-radius: 3px;
}
.form-check input[type="checkbox"] + label:after {
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  display: inline-block;
  font-weight: 900;
  font-size: 13px;
  width: 20px;
  height: 20px;
  line-height: 20px;
  color: #fff;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  transform: scale(0);
  -moz-transition: all 0.12s ease-in;
  -o-transition: all 0.12s ease-in;
  -webkit-transition: all 0.12s ease-in;
  transition: all 0.12s ease-in;
}
.form-check input[type="checkbox"] + label:after {
  color: #4d4d4d;
  background: transparent;
}
.form-check input[type="checkbox"]:checked + label:after {
  transform: scale(1);
}
.country-code-select select {
  max-width: 75px;
  margin-right: 30px;
}
.form-group.agree {
  margin: 10px 0 23px;
}
.form-button .btn {
  min-width: 115px;
  font-size: 13px;
  font-weight: 600;
  padding: 8px 15px;
}
.forgot-password {
  font-size: 12px;
  letter-spacing: 0.6px;
  color: #297ab9;
  margin-top: 12px;
}
form a:hover,
.already-login a:hover {
  color: #297ab9;
  text-decoration: underline;
}
.mr-head svg {
  width: 30px;
  margin-bottom: 20px;
}
.otp-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}
.otp-box input {
  width: 60px;
  margin-right: 25px;
  text-align: center;
}
.otp-box input:last-child {
  margin-right: 0;
}
.enter-otp-box > p {
  font-size: 14px;
  line-height: 25px;
  letter-spacing: 0.6px;
  margin-bottom: 15px;
}
.time-count {
  font-size: 14px;
}
.time-count span {
  color: #f00;
}
.enter-otp-box {
  margin-bottom: 20px;
}
.resend-box {
  font-size: 12px;
  letter-spacing: 0.6px;
}
.resend-box a {
  color: #297ab9;
}
.resend-box a:hover {
  text-decoration: underline;
}
.mr-head p a:hover {
  text-decoration: underline;
}
.form-box {
  max-width: 320px;
}
.mr-icon {
  position: relative;
}
.mr-icon:before {
  content: "";
  width: 95px;
  height: 15px;
  background-image: url(../images/verify.svg);
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
}
.mr-box h2 {
  font-size: 28px;
}
.back-link {
  margin-top: -5px;
}
.btn-link {
  color: #297ab9;
  font-size: 12px;
  letter-spacing: 1px;
}
.btn-link svg {
  width: 15px;
  fill: #297ab9;
  margin-right: 5px;
}
.forgot-password a {
  color: #297ab9;
}
.login-form h2 {
  margin-bottom: 25px;
}
.table-text-group {
  padding-bottom: 36px;
}
.table-responsive .table.right-align-table tr td:not(:first-child) {
  text-align: right;
}
.table-responsive .table.right-align-table tr th:not(:first-child) {
  text-align: right;
}
ul.table-text-list {
  width: calc(100% + 16px);
  margin-left: -8px;
}
ul.table-text-list li {
  width: auto;
  padding: 8px;
}
.tt-box {
  border: 1px solid #297ab9;
  border-radius: 6px;
  min-width: 123px;
  padding: 15px;
  text-align: right;
}
.tt-box h4 {
  font-size: 13px;
  font-weight: 600;
  line-height: 18px;
  color: #8798ad;
  margin: 0 0 6px 0;
}
.tt-box h5 {
  font-size: 18px;
  font-weight: 600;
  line-height: 18px;
  color: #333;
  margin: 0;
}
.right-align-table th > i {
  margin-left: 10px;
}

/* ================= Login End =================== */

/* ================= Home Start ================= */
.media-block-container {
  padding: 30px 0;
  border-bottom: 1px solid #e4e4e4;
}
.mdc-media-box {
  width: 100%;
  position: relative;
  padding-bottom: 46.5%;
  border-radius: 4px;
  overflow: hidden;
  max-width: 512px;
  margin-left: auto;
}
.mdc-media-box > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border: 0;
  outline: none;
}
.mdc-play-btn {
  width: 34px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 2;
  cursor: pointer;
}
.mdc-logo-area {
  margin-bottom: 25px;
}
.mdc-log {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  overflow: hidden;
}
.mdc-text h5 {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 15px;
}
.mdc-text {
  padding-left: 25px;
}
.mdc-logo-area + h5 {
  font-size: 14px;
  line-height: 1.2;
  font-weight: 600;
  margin-bottom: 10px;
}
.has-header-top .navbar-toggler {
  display: none !important;
}
.edit-text {
  font-size: 13px;
  font-weight: 400;
  margin-left: 6px;
  text-decoration: underline;
  color: #297ab9;
}
.filter-sidebar {
  position: relative;
  left: 0;
  padding: 30px 0;
  color: #333;
  border-right: 1px solid #e0e0e0;
  width: 242px;
  top: 0;
  overflow-y: auto; /*height:calc(100vh - 120px);*/
}
.filter-sidebar h4 {
  color: rgb(51 51 51 / 50%);
  font-size: 14px;
  font-weight: 600;
  padding-left: 15px;
}
.main-content {
  padding: 145px 15px 100px 0; /*margin-left:242px;*/
  width: calc(100% - 242px);
  min-height: calc(100vh - 120px);
  overflow: hidden;
  position: relative;
}
.filter-list-container {
  /*padding:0 15px;*/
  padding-bottom: 100px;
}
.filter-list-container .grid-content {
  margin: 0;
  width: 100%;
}
.filter-menu-head {
  position: relative;
  cursor: pointer;
}
.filter-menu-head:before {
  content: "";
  width: 24px;
  height: 24px;
  background: url(../images/plus-icon-1.svg) center/cover;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s all ease-in;
}
.filter-menu-head[aria-expanded="true"]:before {
  background: url(../images/minus-icon.svg) center/cover;
}
.filter-menu-body {
  padding-top: 18px;
  position: relative;
  transition: 0.3s all ease-in;
}
.filter-menu-body .gotomap + .form-check {
  padding-top: 20px;
}
.filter-box {
  margin-bottom: 20px;
  border-bottom: 1px solid #e0e0e0;
  padding: 0 25px 20px 15px;
  position: relative;
}
.filter-box h5 {
  color: rgb(51 51 51 / 85%);
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 0;
}
.form-check input[type="radio"] + label {
  display: inline-block;
  position: relative;
  padding-left: 34px;
  font-size: 14px !important;
  line-height: 23px !important;
  color: #828282;
  margin-bottom: 0;
  font-weight: 400;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.filter-box .form-check + .form-check {
  margin-top: 10px;
}
.filter-box .gotomap {
  position: absolute;
  top: 3px;
  right: 0;
  font-size: 13px;
  color: #297ab9;
  text-decoration: underline;
}
.filter-box .gotomap:hover {
  text-decoration: none;
}
.filter-sidebar label.form-check-label {
  color: #333 !important;
}
ul.categories-list li {
  position: relative;
  padding: 8px 0;
}
ul.categories-list li img {
  position: absolute;
  top: 15px;
  right: 0;
  display: none;
  cursor: pointer;
}
ul.categories-list li:hover img {
  display: block;
}
ul.categories-list li:hover a {
  color: #297ab9;
  font-weight: 600;
}
.show-link,
.view-all {
  color: #297ab9;
  font-weight: 600;
  font-size: 14px;
}
.price-mm-box .input-group {
  width: 76px;
  max-height: 42px;
}
.price-mm-box .form-control {
  background: #f5f5f5;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0.8);
  border-left: none;
  padding: 10px;
  height: 42px;
}
.price-mm-box .input-group-text {
  background: #f5f5f5 !important;
  border-radius: 2px 0 0 2px !important;
  border: 1px solid rgba(228, 228, 228, 0.8);
  border-right: none;
  padding-left: 15px;
  padding-right: 0;
}
.price-range-slider {
  margin-bottom: 20px;
}
.price-range-slider .irs--flat .irs-line {
  height: 2px;
  color: #e4e4e4;
}
.price-range-slider .irs--flat .irs-bar {
  height: 2px;
  background: #297ab9;
}
.price-range-slider .irs--flat .irs-handle {
  width: 20px;
  height: 20px;
  background: #fff;
  border: 1px solid #297ab9;
  border-radius: 50%;
  top: 15px;
  cursor: pointer;
}
.price-range-slider .irs--flat .irs-handle > i:first-child {
  display: none !important;
}
.price-range-slider .irs--flat .irs-min,
.price-range-slider .irs--flat .irs-max {
  display: none;
}
.price-range-slider .irs--flat .irs-from,
.price-range-slider .irs--flat .irs-to,
.vrh-range-slider .irs--flat .irs-single {
  display: none;
}
.price-mm-box .input-group + p {
  margin-bottom: 0;
}
.filter-container {
  position: fixed;
  height: 162px;
  top: 170px;
  background: #fff;
  z-index: 1;
  right: 0;
  left: 242px;
}
.relative-filter .filter-container {
  position: relative;
  height: auto;
  left: 0;
  top: 0;
}

/*.relative-filter .filter-tags{margin-bottom:15px;}*/
#footer.footer.sticky.with-sidebar {
  left: 242px;
}
.filter-container h6 {
  font-size: 14px;
  font-weight: 400;
  color: rgb(51 51 51 / 50%);
  margin-bottom: 0;
  margin-top: 12px;
}
.filter-container {
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 5%);
}
.filter-tags {
  width: 100%;
}
.sc-box-left {
  width: calc(100% - 300px);
}
span.tag-heading {
  border: 0 !important;
}
.tags span {
  padding: 5px 28px 5px 10px;
  font-weight: 400;
}
.tags span {
  display: inline-block;
  background: #fff;
  color: #333;
  padding: 8px 30px 8px 10px;
  font-size: 12px;
  line-height: 16px;
  position: relative;
  min-width: 68px;
  text-align: center;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  margin: 2.5px 0;
}
.tags span > img {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: 12px;
  cursor: pointer;
}
.filter-tags .badge + .badge {
  margin-left: 0;
}
.sc-box-right .btn {
  font-size: 12px;
  margin-right: 15px;
}
select#sortBy {
  -webkit-appearance: none;
  width: auto;
  max-width: 130px;
  font-size: 12px;
  background: #fff url(../images/select-down-icon.svg) no-repeat right center;
  background-size: 13px auto;
  background-position-x: Calc(100% - 12px);
  border-radius: 4px;
  padding: 5px 15px;
  border: solid 1px #e0e0e0;
  padding-right: 35px;
  font-weight: 400;
  color: #333;
  margin-left: auto;
}
.pagination-conatiner {
  margin-top: 20px;
}
.grid-content.has-four-column .grid-item {
  width: 25%;
}
.small.custom-modal .modal-dialog {
  max-width: 905px;
}
.small.custom-modal .modal-header {
  height: 83px;
  padding: 30px 40px;
}
.small.custom-modal .close {
  width: 18px;
}
.small.custom-modal .modal-body {
  padding: 30px;
}
.small.custom-modal .modal-footer {
  padding: 20px 40px;
}
.small.custom-modal .form-check input[type="checkbox"] + label {
  color: #000;
  white-space: nowrap;
}
ul.seller-list {
  width: 100%;
  column-count: 4;
  column-gap: 75px;
}
.map-modal .modal-dialog {
  max-width: 1191px;
}
button.map-close {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  background: #fff;
  box-shadow: 0px 4px 7px rgb(0 0 0 / 10%);
  border-radius: 5px;
  outline: none;
  border: 0;
  position: absolute;
  right: 37px;
  top: 35px;
}
.map-modal .modal-content {
  padding: 0;
  overflow: hidden;
}
.sort-heading h4 {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  margin-bottom: 15px;
}
.sort-box-open {
  background: #fff;
  position: fixed;
  box-shadow: 0px -2px 4px rgb(0 0 0 / 10%);
  width: 100%;
  left: 100%;
  right: auto;
  bottom: 60px;
  padding: 15px 20px;
  z-index: 9999999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
}
.sort-heading h4 span {
  position: relative;
  padding-bottom: 10px;
}
.sort-heading h4 span:before {
  content: "";
  position: absolute;
  background: #e0e0e0;
  width: 40px;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
  bottom: 0;
}
ul.sorting-list li {
  padding: 6px 0;
}
ul.sorting-list li.active a {
  color: #297ab9;
  font-weight: 600;
}
.show-sortby-box .sort-box-open {
  left: 0;
}
.show-sortby-box .home-overlay {
  display: block;
}
.home-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255 255 255 / 70%);
  z-index: 999999;
  display: none;
}
.tab-modify-slidebar.small-tab-modify-sidebar.show {
  right: 0;
  opacity: 1;
  visibility: visible;
}
body.home {
  padding-top: 120px;
}
.edit-text-dropdown .dropdown-menu {
  /*background: linear-gradient(0deg, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0.2)), #297AB9;*/
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.1),
      rgba(255, 255, 255, 0.1)
    ),
    #0c4475;
  color: #fff;
  font-size: 13px;
  line-height: 18px;
  padding: 12px 15px 15px;
  left: -95px;
  border: 0;
  margin-top: 10px;
}
.edit-text-dropdown .dropdown-menu:after {
  content: "";
  position: absolute;
  bottom: 0;
  height: 5px;
  width: 100%;
  background: rgba(255, 255, 255, 0.4);
  left: 0;
}
.edit-text-dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 100px;
  border: 11px solid transparent;
  border-bottom-color: #255783;
}
.dropdown-menu.with-close {
  padding-right: 40px;
}
.dropdown-close {
  position: absolute;
  top: 11px;
  right: 13px;
  filter: brightness(0) invert(1);
}
.dropdown-close img {
  margin: 0;
}
.nsb-head a:before,
ul.nsb-menu-list li.nsb-menu-item > a:before,
ul.sub-menu li.sm-item > a:before {
  font-family: "Font Awesome 5 Free";
  content: "\f078";
  display: inline-block;
  padding-right: 3px;
  vertical-align: middle;
  font-weight: 900;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 10px;
  opacity: 0;
  visibility: hidden;
}
ul.nsb-menu-list li.nsb-menu-item.has-menu > a:before,
ul.sub-menu li.sm-item.has-inner-menu.has-menu > a:before {
  opacity: 1;
  visibility: visible;
}
.color-box {
  width: 20px;
  height: 20px;
  background: red;
  border-radius: 50%;
}
.nsb-header a img,
.nsb-head a img {
  margin-right: 15px;
}
.nsb-head b {
  font-weight: 600;
  color: #297ab9;
}
ul.nsb-menu-list li.nsb-menu-item > a,
.nsb-header a {
  font-size: 14px;
  color: #000;
}
ul.nsb-menu-list li.nsb-menu-item > a {
  width: 100%;
  padding: 17px 23px;
  position: relative;
}
.nsb-body {
  padding: 15px 0;
}
.new-sidemenu-box .close {
  position: absolute;
  right: 25px;
  top: 40px;
}
.am-head {
  padding-bottom: 30px;
  border-bottom: 1px solid #e0e0e0;
}
.am-img-box {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  overflow: hidden;
  margin: 0 auto 20px;
}
.am-img-box > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.am-head p {
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  margin-bottom: 0;
}
.account-member-box .close {
  opacity: 1;
  position: absolute;
  top: 25px;
  left: 25px;
}
.account-member-box {
  width: 100%;
  height: 100%;
  overflow-y: auto;
  padding: 80px 0;
  position: relative;
}
ul.am-menu-list li a {
  font-size: 16px;
  line-height: 18px;
  color: #000;
  padding: 25px 27px;
  display: flex;
  align-items: center;
}
ul.am-menu-list li a > svg {
  fill: #000;
  margin-right: 20px;
}
ul.am-menu-list li.active a {
  color: #297ab9;
}
ul.am-menu-list li.active a > svg {
  fill: #297ab9;
}
.am-body {
  padding: 5px 0;
}
.new-memberlogin-box {
  display: flex;
  flex-flow: column;
  height: 100%;
  overflow: auto;
}
.new-memberlogin-box .close {
  margin-right: 10px;
  margin-top: 10px;
  text-align: right;
}
.nml-head {
  border-bottom: 1px solid #e0e0e0;
  padding: 50px 30px 30px;
  text-align: center;
}
.nml-head-img {
  width: 70px;
  height: 70px;
  margin: 0 auto 20px;
  background: #f5f5f5;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.nml-head h4 {
  font-size: 16px;
  line-height: 18px;
  color: #000000;
  font-weight: 400;
}
.nml-body-btn {
  padding: 20px 15px;
}
.new-memberlogin-box .btn {
  display: block;
  min-width: 203px;
  margin: 0px auto 20px;
  padding: 16px 15px;
  font-size: 16px;
  font-weight: 400;
}
.nml-body-btn .btn-login {
  background: #f5f5f5;
  border-radius: 3px;
  border: 1px solid #f5f5f5;
  color: #297ab9;
}
.nml-footer {
  display: flex;
  margin-top: auto;
  padding: 10px 10px 30px;
  justify-content: center;
}
.color-choose {
  display: flex;
  align-items: center;
}
.color-choose div {
  display: inline-block;
}
.color-choose div + div {
  margin-left: 10px;
}
.color-choose input[type="radio"] {
  display: none;
}
.color-choose input[type="radio"] + label span {
  display: inline-block;
  width: 35px;
  height: 35px;
  margin: -1px 4px 0 0;
  vertical-align: middle;
  cursor: pointer;
  border-radius: 50%;
}
.color-choose input[type="radio"] + label span {
  border: 2px solid #ffffff;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.33);
}
.color-choose input[type="radio"]#red + label span {
  background-color: #4479b4;
}
.color-choose input[type="radio"]#blue + label span {
  background-color: #d83a2f;
}
.color-choose input[type="radio"]#black + label span {
  background-color: #c4c4c4;
}
.color-choose input[type="radio"]#green + label span {
  background-color: #c4c4c4;
}
.color-choose input[type="radio"]#yellow + label span {
  background-color: #5aab68;
}
.color-choose input[type="radio"]#pink + label span {
  background-color: #5aafda;
}
.color-choose input[type="radio"]:checked + label span {
  background-image: url("../images/check-icon.svg");
  background-repeat: no-repeat;
  background-position: center;
}
.product-color span,
.size-config span {
  font-size: 14px;
  font-weight: 400;
  color: #c4c4c4;
  margin-bottom: 0;
  display: inline-block;
  width: 75px;
}
.size-choose button + button {
  margin-left: 10px;
}
.product-options {
  margin-bottom: 35px;
}
.size-choose button {
  border: 2px solid #e4e4e4;
  border-radius: 6px;
  padding: 9px 35px;
  font-size: 14px;
  color: #000;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.5s;
  font-weight: 600;
}
.size-choose button:hover,
.size-choose button:active,
.size-choose button:focus {
  border: 2px solid #297ab9;
  outline: none;
}
.size-config {
  margin-bottom: 15px;
}
.size-config a {
  color: #358ed7;
  text-decoration: none;
  font-size: 12px;
  position: relative;
  margin: 10px 0;
  display: inline-block;
}
.size-config a:before {
  content: "?";
  height: 15px;
  width: 15px;
  border-radius: 50%;
  border: 2px solid rgba(53, 142, 215, 0.5);
  display: inline-block;
  text-align: center;
  line-height: 16px;
  opacity: 0.5;
  margin-right: 5px;
}
.color-choose label {
  margin-bottom: 0;
}

/* ================= Home End ================= */
.tab-slidebar.small-tab-modify-sidebar.show {
  right: 0;
  opacity: 1;
  visibility: visible;
}
.tab-slidebar.small-tab-modify-sidebar {
  max-width: 256px !important;
}
.tab-slidebar {
  width: 100%;
  max-width: 550px;
  opacity: 0;
  visibility: hidden;
  position: fixed;
  right: 0px;
  top: 0;
  bottom: 0;
  right: -100%;
  background: #fff;
  z-index: 9999999;
  transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  box-shadow: 0 0 25px rgb(0 0 0 / 10%);
}
.tab-slidebar.advance-modify-search-sidebar.show {
  left: 0;
  opacity: 1;
  visibility: visible;
}
.tab-slidebar.advance-modify-search-sidebar {
  left: -100%;
  right: auto;
}
.new-sidemenu-box {
  width: 100%;
  height: 100%;
  padding: 96px 0;
  position: relative;
  overflow-y: auto;
}
.nsb-header a {
  border-bottom: 1px solid #e0e0e0;
  padding: 25px 23px;
  width: 100%;
  display: flex;
  align-items: center;
}
.nsb-header,
.nsb-head {
  font-size: 16px;
}
.nsb-head a {
  border-bottom: 1px solid #e0e0e0;
  padding: 25px 23px;
  width: 100%;
  position: relative;
}

/*=========================== New Home Page start ======================================*/
#header4 {
  color: #000;
  background: #fff;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  padding: 0;
  z-index: 999;
  transition: all 0.2s ease-in;
  -webkit-transition: all 0.2s ease-in;
  display: flex;
  align-items: center;
}
#header4 ul.navbar-nav {
  font-size: 14px;
  line-height: 19px;
  text-align: center;
  letter-spacing: 0.5px;
}
#header4 .header-btn + .header-btn {
  margin-left: 20px;
}
#header4 .header-btn {
  padding: 10px 25px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.5px;
}
#header4 .header-grey-btn {
  background: #f5f5f5;
  border-radius: 4px;
  color: #297ab9;
  border: 1px solid #f5f5f5;
}
#header4 .header-grey-btn:hover,
#header4 .header-grey-btn:focus {
  background: #f5f5f5;
  color: #297ab9;
  border-color: #297ab9;
}
#header4 .header-blue-btn {
  background: #297ab9;
  border-radius: 4px;
  color: #fff;
}
#header4 .header-blue-btn:hover,
#header4 .header-blue-btn:focus {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    #297ab9;
  color: #fff;
}
#header4 .header-btn-group {
  margin: 0 30px;
}
.hero-container {
  color: #fff;
  position: relative;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 500px;
  background: linear-gradient(95.48deg, #297ab9 -4.89%, #0e5994 107.51%);
}
.hero-container:before {
  content: "";
  width: 50vw;
  height: 141px;
  background: linear-gradient(
    270deg,
    rgba(12, 68, 117, 0.4) -3%,
    rgba(12, 68, 117, 0) 94.73%
  );
  position: absolute; /* left:-35%; */
  bottom: 0;
  z-index: 0;
  mix-blend-mode: multiply;
  right: 0;
}
.hero-img-outer:before {
  content: "";
  width: 50vw;
  height: 50vw;
  background: linear-gradient(
    270deg,
    rgba(12, 68, 117, 0.4) -3%,
    rgba(12, 68, 117, 0) 94.73%
  );
  position: absolute; /* left:-35%; */
  bottom: -23vw;
  z-index: 0;
  mix-blend-mode: luminosity;
  right: 0;
  display: none;
}
.intro-content {
  width: 100%;
}

.intro-content .intro-text-box {
  max-width: 455px;
}
.intro-content .input-group input:focus {
  background: transparent !important;
}
.intro-content h2 {
  font-size: 48px;
  line-height: 65px;
  margin-bottom: 15px;
}
.intro-content p {
  font-size: 18px;
  line-height: 29px;
  color: rgb(255 255 255 / 70%);
  margin-bottom: 0;
}
.intro-content .input-group {
  background: transparent;
  border-radius: 4px;
  margin-top: 60px;
}
.intro-content .input-group img {
  opacity: 0.2;
}
.intro-content .form-control::placeholder {
  color: rgba(0, 0, 0, 0.4);
}
.intro-content .input-group {
  width: auto;
  flex-grow: 1;
  padding-right: 150px;
}
.input-group-inside {
  background: #fff;
  width: 100%;
  border-radius: 4px;
}
.intro-content .input-group .input-group-append {
  background: #0c4475;
  border-radius: 4px;
  justify-content: center;
  padding: 15px 30px;
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  position: absolute;
  width: 140px;
  cursor: pointer;
  height: 100%;
  right: 0;
}

/*.hero-img{max-width:370px;margin-left:auto;position:relative;}*/
.hero-img-outer {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 50%;
  margin-right: -840px;
}
.hero-img {
  /*max-width: 410px;margin-left: 30px;position:relative; margin-right: 150px; margin-top: -55px;*/
}

/*.add-shadow:after{content:'';width:100%;height:48px;background-image:url(../images/shadow.png);position:absolute;bottom:42px;right:-38%;background-position:center;background-repeat:no-repeat;background-size:cover;mix-blend-mode:multiply;z-index:1;opacity:0.6;}*/
.hero-img.add-shadow img {
  position: relative;
  z-index: 2;
}
.map-pin-one {
  width: 54px;
  position: absolute;
  right: 30%;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
}
.map-pin-two {
  width: 47px;
  position: absolute;
  bottom: 29%;
  left: 21%;
  z-index: 2;
}
.map-pin-three {
  width: 33px;
  position: absolute;
  top: 23%;
  left: 46%;
  z-index: 2;
}
.hpc-heading-box {
  margin-bottom: 15px;
}
.hpc-heading-box > h5 {
  font-weight: 600;
  font-size: 21px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 0;
}
.hpc-heading-box h5 > span {
  font-size: 18px;
  font-weight: 400;
  line-height: 25px;
  color: #297ab9;
}
.border-btn > img {
  width: 15px;
  height: 15px;
}
.add-to-cart-dropdown button > img {
  width: 9px !important;
  display: inline-block;
}
.border-radius-6px {
  border-radius: 6px !important;
}
.custom-slick-arrow {
  background: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  height: 34px;
  width: 34px; /*background-size:cover;*/
  background-position: center;
  background-repeat: no-repeat;
}
.custom-slick-arrow + .custom-slick-arrow {
  margin-left: 20px;
}
.add-custom-arrow .slick-arrow.slick-disabled {
  opacity: 0.5;
  cursor: no-drop;
}
.add-custom-arrow .prev {
  background-image: url("../images/back-arrow.svg");
}
.add-custom-arrow .next {
  background-image: url("../images/next-arrow.svg");
}
.add-custom-arrow + .showall-link {
  margin-left: 30px;
}
.showall-link {
  font-size: 16px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.5);
}
.grey-cta-container {
  /*color:#297AB9;*/
  padding: 65px 0;
}
.cta-content h3 {
  font-size: 24px;
  margin-bottom: 16px;
}
.cta-content p {
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 0;
  opacity: 0.8;
}
.cta-btn {
  margin-top: 40px;
}
.cta-btn .btn {
  font-size: 14px;
  font-weight: 600;
  line-height: 160%;
  padding: 12px;
}
.cta-bg {
  position: absolute;
  right: 8px;
  bottom: 0;
  width: 37.5%;
}
.grey-cta-inner {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  height: 100%;
}
.grey-cta-box {
  padding-right: 60%;
  position: relative;
  z-index: 9;
}
.blue-cta-box {
  position: relative;
  z-index: 9;
}
.blue-cta-container {
  background: linear-gradient(95.48deg, #297ab9 -4.89%, #0e5994 107.51%),
    #c4c4c4;
  color: #fff;
  padding: 55px 0;
}
.blue-cta-container .cta-bg {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
}
.blue-cta-box .cta-content {
  max-width: 375px;
  margin-left: auto;
}
.cta-inner {
  width: 150%;
  position: absolute;
  bottom: 0;
  left: 47%; /* right:0; */
  height: 100%;
  transform: translateX(-50%);
}
.cta-inner svg {
  width: auto !important;
}
.animation {
  background: #f00 !important;
}
.ci-img-box {
  width: calc(100% + 80px);
  margin-left: -80px;
  position: relative;
}

/*.ci-img-box:before{content:'';width:50vw;height:75px;background-image:url(../images/blue-shape.png);position:absolute;right:100%;bottom:0;background-repeat-x:repeat;background-position:center;background-size:contain;z-index:1;}*/
.post-heading-text p {
  font-weight: 600;
  font-size: 21px;
  line-height: 34px;
  color: rgb(0 0 0 / 85%);
  margin-bottom: 18px;
}
.post-list {
  width: calc(100% + 40px);
  margin-left: -20px;
}
.post-list li {
  width: 33.33%;
  padding: 20px;
}
.post-list li .post-box {
  box-shadow: 0px 10px 40px -2px rgba(0, 0, 0, 0.08);
  border-radius: 4px;
  background: #fff;
  overflow: hidden;
  height: 100%;
}
.post-img {
  position: relative;
  padding-bottom: 61%;
  overflow: hidden;
  background-color: #000;
}
.post-img > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
  transform: scale(1);
  transition: 0.2s cubic-bezier(0.33, 0.55, 0.83, 0.67);
  -webkit-transition: 0.2s cubic-bezier(0.33, 0.55, 0.83, 0.67);
}
.post-box:hover .post-img > img {
  transform: scale(1.1);
}
.post-list li .post-content {
  padding: 25px;
}
.post-list li .post-content h6 {
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 0;
}
.tbc-text p {
  font-size: 21px;
  line-height: 34px;
  color: rgb(0 0 0 / 85%);
  margin-bottom: 40px;
}
.tbc-img {
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  padding-bottom: 39.5%;
}
.tbc-img > img {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  object-fit: cover;
}
.tbc-btn {
  margin-top: 40px;
  text-align: center;
}
.tbc-btn .btn {
  max-width: 450px;
  width: 100%;
  border-radius: 4px;
  padding: 15px;
}
#footer1 {
  background: #0c4475;
  color: #fff;
  font-size: 14px;
  line-height: 22px;
  padding: 20px 0;
  position: relative;
}
#footer1 .footer-nav {
  width: calc(100% + 40px);
  margin-left: -20px;
}
#footer1 .footer-nav li {
  padding: 0 20px;
}
#footer1 .footer-nav li a:hover {
  color: rgb(255 255 255 / 61%);
}
@media (min-width: 1439.98px) {
  .grey-cta-container .cta-bg {
    width: 26%;
  }
  .blue-cta-container .cta-bg {
    width: 69%;
  }
}

/*=========================== New Home Page end ======================================*/

/*=========================== New Home Page start ======================================*/
.has-blue-header .header-top {
  background: #0c4475 !important;
}
.has-blue-header .price-icon {
  background: #0c4475;
}

/*.new-wrapper-container{padding-top:105px;}*/
.filter-nav-wrapper {
  padding: 13px 0;
  border-bottom: 1px solid;
  border-color: rgb(228 228 228 / 50%);
}
.fnr-dividor {
  width: 1px;
  background: rgb(228 228 228 / 50%);
  margin: 8px 15px;
  display: inline-block;
}
.form-check-outer + .clear_all {
  margin-left: 25px;
}
.form-check-outer .new-form-check {
  padding: 5px 8px;
}
.filter-nav-row {
  font-size: 12px;
  line-height: 16px;
  color: #333;
}
.fnr-side-nav-btn {
  cursor: pointer;
  background: #fff;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 7%);
  border-radius: 4px;
  padding: 12px 20px;
}
.fnr-side-nav-btn > img {
  margin-right: 10px;
}
.fnr-side-nav-btn.selected {
  background: #0c4475;
  color: #fff;
}
.fnr-side-nav-btn.selected > img {
  filter: brightness(0) invert(1) !important;
}
.new-form-check .form-check-label {
  text-transform: capitalize;
  background: #fff;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 7%);
  border-radius: 4px;
  padding: 12px 15px;
  color: currentColor !important;
  font-size: inherit !important;
  font-weight: 400;
  text-align: center;
  cursor: pointer;
  min-width: 40px;
}
.filter-nav-column .view-type {
  width: 75px;
  margin-right: -8px !important;
}
.new-form-check .form-check-label > img {
  margin-right: 10px;
}
.new-form-check input[type="radio"].form-check-input {
  display: none;
}
.new-form-check
  input[type="radio"].form-check-input:checked
  + .form-check-label {
  background: #0c4475;
  color: #fff !important;
}
.new-form-check
  input[type="radio"].form-check-input:checked
  + .form-check-label
  > img {
  filter: brightness(0) invert(1) !important;
}
.clear_all {
  color: #297ab9;
  text-decoration: underline;
}
.sortby-row b {
  font-weight: 600;
}
.sortby-row .sortby-sep {
  margin-left: 6px;
  color: #000;
}
select.custom-form-control {
  background-color: transparent;
  background-image: url(../images/chevron-down-20x20.svg);
  background-repeat: no-repeat;
  background-position: right center;
  background-size: 15px auto;
  background-position-x: calc(100% - 15px);
  -webkit-appearance: none;
  cursor: pointer;
  padding: 0 40px 0 10px;
  border: 0;
  color: currentColor !important;
}
.product-gridview-list {
  width: calc(100% + 32px);
  margin-left: -16px;
}
.product-gridview-slider.product-gridview-list {
  margin-top: -30px;
}
.pgl-item {
  width: 25%;
  padding: 16px;
}
.pgl-box {
  background: #fff;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 7%);
  border-radius: 4px;
}
.result-status-row {
  font-size: 16px;
  line-height: 22px;
  color: #333;
  padding: 40px 0 25px;
}
.result-status-row > span {
  font-weight: 600 !important;
}
.pgl-img-box {
  position: relative;
  height: 320px;
  width: 100%;
  overflow: hidden;
  display: flex;
}
.pgl-img-box > img {
  width: 100% !important;
  height: 100%;
  object-fit: cover;
  transition: 0.5s all ease;
}
.pgl-box:hover .pgl-img-box > img {
  transform: scale(1.08);
}
.pgl-content-box {
  padding: 25px;
  height: calc(100% - 320px);
  display: flex;
  flex-flow: column;
}
.rating-group {
  display: inline-flex;
  margin-left: -2px;
}
.rating-icon {
  pointer-events: none;
}
.rating-input {
  position: absolute !important;
  left: -9999px !important;
}
.rating-label {
  cursor: pointer;
  padding: 0 2px 0;
  font-size: 11px;
  margin: 0;
}
.rating-icon-star {
  color: #ffc531;
}
.rating-input:checked ~ .rating-label .rating-icon-star {
  color: #e4e4e4;
}
.rating-group:hover .rating-label .rating-icon-star {
  color: #ffc531;
}
.rating-input:hover ~ .rating-label .rating-icon-star {
  color: #e4e4e4;
}
ul.pgl-tags-list {
  width: calc(100% + 10px);
  margin-left: -5px;
  margin-top: auto;
}
ul.pgl-tags-list li {
  padding: 5px;
}
ul.pgl-tags-list li .pgl-tags-box {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #297ab9;
  border-radius: 4px;
  padding: 5px 10px;
  color: #297ab9;
  font-size: 12px;
  line-height: 16px;
}
.pgl-title {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #000;
  margin-bottom: 2px;
  display: block !important;
}
.pgl-price {
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}
.pgl-price > span {
  font-weight: 700;
  display: inline-block;
  margin-left: 5px;
}
.pgl-sub-text span {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: rgb(0 0 0 / 50%);
  text-transform: uppercase;
}
.pgl-sub-text span + span {
  margin-left: 2px;
}
.pgl-rating {
  margin-bottom: 10px;
}
.pgl-sub-text {
  margin-bottom: 13px;
}
.fav-icon-block {
  cursor: pointer;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 26px;
  right: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  overflow: hidden;
}
.fav-icon-block:before {
  content: "";
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  backdrop-filter: blur(10px);
  border-radius: 100%;
  overflow: hidden;
}
.fav-icon-block > img {
  position: relative;
  z-index: 2;
  width: auto !important;
}
.add-button-outer {
  background: #297ab9;
  box-shadow: 0px 5px 10px rgb(0 0 0 / 15%);
  z-index: 2;
  cursor: pointer;
  border-radius: 6px;
  padding: 8px;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  cursor: pointer;
  width: 82px;
  height: 44px;
  position: absolute;
  top: 257px;
  left: 27px;
  right: 25px;
  transition: 0.5s all ease;
}
.bhc-list {
  width: calc(100% + 16px);
  margin-left: -8px;
}
.bhc-item {
  position: relative;
  padding: 0 8px;
}
.bhc-item:not(:last-child):before {
  content: "";
  width: 1px;
  height: calc(100% - 10px);
  background: #fff;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.bhc-link {
  padding: 4.5px;
  border-radius: 6px;
  background: transparent;
  color: currentColor !important;
}
.bhc-link:hover {
  background: rgb(12 68 117 / 30%);
}
.add-button-outer.open {
  width: calc(100% - 50px);
}
.add-button-outer.open .button-hover-content {
  width: auto;
  transition: 0.5s all ease;
}
.button-hover-content {
  width: 0;
  overflow: hidden;
  transition: 0.5s all ease;
}
.add-button-outer.open .add-button-box {
  opacity: 0;
  visibility: visible;
}
.add-button-box {
  width: 100%;
  text-align: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  white-space: nowrap;
}
.add-button-box > img {
  margin-right: 5px;
}

/*New pagination css start here*/
.pagination-wrapper {
  margin-top: 40px;
}
.filtermenu-head {
  padding-right: 30px;
  margin-bottom: 30px;
}
.filtermenu-head h5 {
  font-weight: 600;
  font-size: 24px;
  line-height: 1.4;
  margin-bottom: 0;
}
.filtermenu-tags-head {
  margin-bottom: 15px;
}
.close-sidemenu {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.filtermenu-card-item-header h5,
.filtermenu-tags-head h6 {
  font-weight: 600;
  font-size: 14px;
  line-height: 1.3;
  margin-bottom: 0;
}
.filtermenu-tags {
  margin-bottom: 15px;
}
.filtermenu-tags .tags {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.filtermenu-tags .tags span.badge {
  margin: 5px !important;
}

/*.filtermenu-tags .tags span.badge + span.badge{margin:0;}*/
.filtermenu-card-item {
  border-top: 1px solid #e4e4e4;
}
.filtermenu-box {
  padding: 40px;
}
.filtermenu-card-item:last-child {
  border-bottom: 1px solid #e4e4e4;
}
.filtermenu-card-item-header {
  padding: 22px 0;
  cursor: pointer;
}
.filtermenu-card-item-header[aria-expanded="false"] {
}
.accor-minus-icon {
  display: none;
}
.filtermenu-card-item-header[aria-expanded="true"] .accor-plus-icon {
  display: none;
}
.filtermenu-card-item-header[aria-expanded="true"] .accor-minus-icon {
  display: block;
}
.filtermenu-card-item-header[aria-expanded="true"] .filtermenu-clear-btn {
  display: block;
}
.filtermenu-right {
  position: relative;
  padding-right: 35px;
}
.filtermenu-card-item .filtermenu-clear-btn {
  display: none;
}
.filtermenu-clear-btn {
  font-weight: 400;
  font-size: 14px;
  line-height: 1.3;
  text-decoration-line: underline;
  color: #297ab9;
  cursor: pointer;
}
.card-icon-box {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}
.filtermenu-card-item-body {
  padding-bottom: 22px;
}
.filtermenu-card-item-body .form-check + .form-check {
  margin-top: 20px;
}
.text-black-color.form-check input[type="radio"] + label,
.text-black-color.form-check input[type="checkbox"] + label {
  color: #000 !important;
  line-height: 20px !important;
}
.text-black-color.form-check input[type="radio"] + .form-check-label::before,
.text-black-color.form-check
  input[type="checkbox"]
  + .form-check-label::before {
  border-color: #4d4d4d !important;
}
.text-black-color.form-check
  input[type="radio"].form-check-input:checked
  + .form-check-label::before {
  border-color: #297ab9 !important;
}
.text-black-color.form-check
  input[type="checkbox"].form-check-input:checked
  + .form-check-label::before {
  border-color: #297ab9 !important;
  background: #297ab9 !important;
}
.filtermenu-card-item-body .form-group {
  margin-bottom: 20px;
}
.filtermenu-card-item-body .multiple-input-group {
  margin-top: 30px;
}
.multiple-input-group {
  width: 100%;
  height: 50px;
  border: 1px solid #e4e4e4;
  border-radius: 6px;
  overflow: hidden;
  position: relative;
  padding-right: 55px;
  font-size: 14px;
  line-height: 19px;
}
.mig-input > input {
  border: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  color: rgb(0 0 0 / 85%);
}
.mig-input + .mig-input {
  border-left: 1px solid #e4e4e4;
}
.mig-btn {
  width: 55px;
  height: 100%;
  background: #297ab9;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
.single-range-slider .range-slider {
  position: relative;
  height: 30px;
  margin-bottom: 20px;
}
.single-range-slider .irs--square {
  height: 30px;
}
.single-range-slider .irs--square .irs-min,
.single-range-slider .irs--square .irs-max,
.single-range-slider .irs--square .irs-from,
.single-range-slider .irs--square .irs-to,
.single-range-slider .irs--square .irs-single {
  display: none;
}
.single-range-slider .irs--square .irs-handle {
  border-radius: 4px;
  width: 30px;
  height: 30px;
  top: 50%;
  transform: translateY(-50%);
}
.single-range-slider .irs--square .irs-bar {
  background-color: #297ab9;
}
.single-range-slider .irs--square .irs-handle {
  border-color: #297ab9 !important;
}
.single-range-slider .irs--square .irs-line {
  border-color: #e4e4e4 !important;
}
.single-range-slider .irs--square .irs-line,
.irs--square .irs-bar {
  border-radius: 6px;
  top: 15px;
}
.single-range-slider input.js-input {
  border-radius: 4px;
  outline: none;
  max-width: 159px;
  height: 50px;
  padding: 16px 20px;
  color: #000;
  font-size: 14px;
  line-height: 1;
  border: 1px solid #e4e4e4;
}
.pll-item + .pll-item {
  margin-top: 35px;
}
.pll-box {
  background: #fff;
  box-shadow: 0px 3px 20px rgb(0 0 0 / 7%);
  border-radius: 4px;
}
.pll-img-box {
  width: 320px;
}
.pll-text-box {
  width: calc(100% - 320px);
  padding: 30px 145px 30px 35px;
  position: relative;
}
.pll-text-box h5 {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  margin-bottom: 10px;
}
.pll-text-box h5 + p {
  width: 95%;
}
.pll-text-box p {
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  color: #4f4f4f;
}
.pll-rating {
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  margin-bottom: 20px;
}
.pll-price {
  font-weight: 300;
  font-size: 26px;
  line-height: 26px;
}
.pll-price > span {
  font-weight: 400 !important;
  margin-left: 5px;
}
.pll-sub-text {
  font-weight: 400;
  font-size: 12px;
  line-height: 21px;
  color: #0c4475;
  margin-bottom: 20px;
}
.pll-tags-list {
  width: calc(100% + 10px);
  margin-left: -5px;
}
.pll-tags-list li .pll-tags-box {
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 0.9),
      rgba(255, 255, 255, 0.9)
    ),
    #297ab9;
  border-radius: 4px;
  padding: 5px 10px;
  color: #297ab9;
  font-size: 12px;
  line-height: 16px;
}
.pll-tags-list li {
  padding: 0 5px;
}
.favicon-block {
  position: absolute;
  right: 44px;
  top: 35px;
  cursor: pointer;
}
.pll-img {
  position: relative;
  padding-bottom: 100%;
}
.pll-img > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
.hpc-header {
  max-width: 480px;
  margin: 0 auto 50px;
}
.hpc-header h4 {
  font-weight: 600;
  font-size: 21px;
  line-height: 160%;
  color: rgba(0, 0, 0, 0.85);
  margin-bottom: 10px;
}
.hpc-header h4 + p {
}
.hpc-header p {
  font-size: 18px;
  line-height: 25px;
  color: #297ab9;
}
.product-gridview-slider .slick-slide img {
  width: auto;
}
.add-custom-slick-arrow .slick-prev:before,
.add-custom-slick-arrow .slick-next:before {
  content: none !important;
}
.add-custom-slick-arrow .slick-prev:after {
  background-image: url(../images/chevron-left-icon.svg);
}
.add-custom-slick-arrow .slick-next:after {
  background-image: url(../images/chevron-right-icon.svg);
}
.add-custom-slick-arrow .slick-prev:after,
.add-custom-slick-arrow .slick-next:after {
  content: "";
  width: 24px;
  height: 24px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.add-custom-slick-arrow .slick-prev,
.add-custom-slick-arrow .slick-next {
  width: 44px;
  height: 100px;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(16px);
  border-radius: 4px;
  font-size: 0;
  filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.07));
}
.add-custom-slick-arrow .slick-prev {
  left: -2px;
}
.add-custom-slick-arrow .slick-next {
  right: -2px;
}
.pricing-dropdown {
  margin-right: 15px;
}
.add-new-dropdown .dropdown-toggle {
  padding: 0 41px 0 8px;
  position: relative;
  cursor: pointer;
}
.add-new-dropdown .dropdown-toggle img {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: 20px;
}
.add-new-dropdown .dropdown-menu:before {
  content: "";
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-bottom: 16px solid #fff;
  top: -16px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}
.add-new-dropdown .dropdown-menu {
  border: 0;
  filter: drop-shadow(0px 5px 24px rgb(0 0 0 / 10%));
  margin-top: 25px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #333;
  padding: 12.5px 0;
  min-width: 150px;
}
.add-new-dropdown .dropdown-item {
  padding: 7.5px 15px;
}
.add-new-dropdown .dropdown-item:focus,
.add-new-dropdown .dropdown-item:hover,
.add-new-dropdown .dropdown-item:active {
  color: #fff;
  background-color: rgb(12 68 117 / 80%);
}
.product-gridview-slider .slick-list.draggable {
  padding: 30px 0;
}
#footer3 {
  background: #0c4475;
  font-size: 14px;
  line-height: 160%;
  padding: 19px 0;
}
#footer3 a:hover {
  color: rgb(255 255 255 / 60%) !important;
}

/*=========================== New Home Page end ======================================*/

.empty-product-container {
  min-height: calc(100vh - 258px);
  padding: 30px 0;
}
.epc-box {
  width: 100%;
  max-width: 373px;
  margin: 0 auto;
}
.epc-box h4 {
  font-weight: 400;
  font-size: 23px;
  line-height: 31px;
  margin-bottom: 10px;
}
.epc-box p {
  font-weight: 400;
  font-size: 13px;
  line-height: 26px;
  color: #4f4f4f;
}

@media (min-width: 1900.98px) {
  .large-device-item .pgl-item {
    width: 16.66% !important;
  }
}
@media (min-width: 1600.98px) {
  .large-device-item .pgl-item {
    width: 20%;
  }
}
@media (max-width: 1600.98px) {
  button.map-close {
    width: 35px;
    height: 35px;
    right: 20px;
    top: 20px;
  }
  button.map-close > img {
    width: 14px;
  }
  .grid-content {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .grid-item {
    width: 33.33%;
    padding: 15px;
  }
  .hero-img-outer {
    margin-right: 0;
    right: 0;
  }
}
@media (max-width: 1400.98px) {
  .signin-form-container {
    padding: 100px 0;
  }
  .forgot-password {
    margin-top: 18px;
  }
  .logo-area {
    width: 165px;
    margin-bottom: 45px;
  }
  .success-modal-popup .modal-content {
    padding: 50px 35px;
  }
  .smp-icon {
    width: 100px;
  }
  .smp-box h4 {
    font-size: 28px;
  }
  .smp-box h6 {
    font-size: 20px;
  }
  .smp-table-box .table2 tr td:first-child span {
    width: 25px;
    height: 45px;
  }
  .smp-table-box .table2 tr td {
    padding: 15px 15px 15px;
  }
  .smpf-left .btn {
    padding: 15px 25px;
    font-size: 16px;
  }
  .grey-cta-inner {
    height: auto;
    left: auto;
    right: -10vw;
  }
  .cta-inner {
    height: auto;
  }
}
@media (max-width: 1250.98px) {
  .filter-tags {
    width: 100%;
  }
  .tags span {
    padding: 8px 18px 8px 5px;
  }
  .tags span > img {
    right: 6px;
    width: 10px;
    height: 10px;
  }
}
@media (min-width: 1200.98px) {
  .new-header .navbar-expand-xl .navbar-nav .dropdown-menu {
    left: 35px !important;
  }
  .new-header .dropdown-item {
    padding: 6px 15px;
  }
  #header4 .navbar-expand-xl .navbar-nav .nav-link {
    padding: 0 20px;
  }
  #header4 .navbar-expand-xl .navbar-nav .nav-item:last-child .nav-link {
    padding-right: 0;
  }
}

/* =================================================  
! Small devices (landscape phones, less than 768px)
================================================= */
@media (max-width: 1199.98px) {
  .container-fluid {
    width: calc(100% - 0px);
  }
  .has-map .container {
    width: calc(100% - 0px);
  }
  .has-map .navbar {
    padding: 0;
  }
  .basic-addon-box img {
    margin-right: 8px;
  }
  .basic-addon-box {
    margin-left: 15px;
  }
  .new-search-row .input-group {
    flex-grow: 0;
  }
  .has-header-top .navbar {
    padding: 0;
  }
  .has-header-top .navbar-toggler {
    display: none !important;
  }

  /* #header .navbar{padding:0 15px;} */
  .navbar {
    padding: 0 15px;
    width: 100%;
    height: 82px;
  }
  .navbar-toggler {
    width: 54px;
    height: 82px;
    order-radius: 0;
    cursor: pointer;
    text-decoration: none;
    padding: 0;
    border: 0;
    background: transparent;
    margin: 0;
    position: absolute;
    right: 0;
    top: 50%;
    z-index: 999;
    transform: translateY(-50%);
  }
  .navbar-default .navbar-toggler:hover,
  .navbar-default .navbar-toggler:focus {
    background: transparent;
  }
  .navbar-toggler:not([class="collapsed"]),
  .navbar-toggler:not([class="collapsed"]):focus,
  .navbar-toggler:not([class="collapsed"]):hover {
    background: transparent;
  }
  .navbar-toggler.collapsed,
  .navbar-toggler.collapsed:focus,
  .navbar-toggler.collapsed:hover {
    background: transparent;
  }
  .home .navbar-toggler.collapsed,
  .home .navbar-toggler.collapsed:focus,
  .home .navbar-toggler.collapsed:hover {
    background: transparent;
  }
  .navbar-toggler .navbar-toggler-icon {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 3px;
    color: #243238;
    text-indent: -55px;
    margin-top: 0;
    background: transparent !important;
    transition: all 0.2s ease-out;
    -webkit-transition: all 0.2s ease-out;
    -moz-transition: all 0.2s ease-out;
    -ms-transition: all 0.2s ease-out;
    vertical-align: middle;
  }
  .navbar-toggler .navbar-toggler-icon:before,
  .navbar-toggler .navbar-toggler-icon:after {
    content: "";
    width: 20px;
    height: 3px;
    background: #000;
    position: absolute;
    left: 0;
    transition: all 0.2s ease-out;
  }
  .navbar-toggler.collapsed .navbar-toggler-icon {
    background: #000 !important;
  }
  .navbar-toggler.collapsed .navbar-toggler-icon:before,
  .navbar-toggler.collapsed .navbar-toggler-icon:after {
    background: #000;
  }
  .navbar-toggler.collapsed .navbar-toggler-icon:before {
    top: -7px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  .navbar-toggler .navbar-toggler-icon:before {
    top: 0;
    -webkit-transform: rotateZ(45deg);
    -moz-transform: rotateZ(45deg);
    -ms-transform: rotateZ(45deg);
    -o-transform: rotateZ(45deg);
    transform: rotateZ(45deg);
  }
  .navbar-toggler.collapsed .navbar-toggler-icon:after {
    bottom: -7px;
    -webkit-transform: rotateZ(0deg);
    -moz-transform: rotateZ(0deg);
    -ms-transform: rotateZ(0deg);
    -o-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }
  .navbar-toggler .navbar-toggler-icon:after {
    bottom: 0;
    -webkit-transform: rotateZ(-45deg);
    -moz-transform: rotateZ(-45deg);
    -ms-transform: rotateZ(-45deg);
    -o-transform: rotateZ(-45deg);
    transform: rotateZ(-45deg);
  }
  .header-right {
    position: relative;
    z-index: 999;
    margin-right: 60px;
  }
  .navbar-collapse,
  .navbar-collapse.collapsing {
    position: fixed;
    top: 81px;
    left: 0;
    background: #fff;
    padding: 0;
    z-index: 99;
    margin: 0;
    border: 0;
    overflow-y: auto;
    bottom: 0;
    width: 100%;
    height: 0;
    display: block !important;
    transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
  }
  .navbar .collapse.show {
    height: calc(100vh - 82px);
  }
  .navbar-inside {
    height: calc(100vh - 82px);
    padding: 0 15px;
    display: flex;
    flex-flow: column;
  }
  .navbar-expand-xl .navbar-nav .nav-item {
    padding: 0;
    margin: 0;
    border-bottom: solid 1px rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    font-size: 14px;
    line-height: 24px;
    padding: 15px 0;
    position: relative;
    color: #333;
  }
  .navbar-nav.sm-collapsible .sub-arrow {
    border: 0;
    margin: 0;
    width: auto;
    font-size: 30px;
    line-height: 60px;
    top: 0;
    height: 60px;
    color: #000;
    width: 40px;
  }
  header .dropdown-menu {
    background: transparent;
    border: 0;
    padding: 0;
    margin: 0 0 20px 0;
  }
  header .dropdown-item {
    padding: 7px 20px 7px 40px;
  }
  header .dropdown-item:focus,
  header .dropdown-item:hover {
    color: #000;
    background: transparent;
  }
  .content-container {
    padding: 50px 0;
  }
  .header-right img {
    width: 35px;
  }
  .header-right .hdr-info {
    margin-right: 0;
  }
  .new-header .header-right .hdr-info {
    margin-right: 11px;
  }
  .container {
    width: calc(100% - 5px);
  }
  .list-details-header {
    padding: 0 15px;
  }
  .grid-item {
    width: 50%;
  }
  .new-header .carets {
    display: none;
  }
  .new-header .hdr-logout span.carets {
    display: block;
  }
  .new-header .navbar-inside {
    padding-left: 0;
  }
  .new-header ul.navbar-nav .nav-link {
    line-height: 60px;
    margin: 0;
    padding: 0 15px !important;
  }
  .new-header .dropdown-menu {
    box-shadow: none;
    border: 0;
    margin: 0;
  }
  .new-header .header-right {
    margin-right: 55px;
  }
  .new-header .hdr-logout .dropdown-menu {
    box-shadow: 5px 25px 15px 0 rgb(0 0 0 / 10%);
    border: 1px solid #d8d8d8;
  }
  .table2 .dropdown-menu {
    background: #fff;
  }
  .header-intro-box {
    font-size: 14px;
  }
  .sac-img {
    width: 140px;
    margin: 0 auto 25px;
  }
  .sac-content-box h4 {
    font-size: 30px;
  }
  .stripe-account-container {
    padding-top: 0;
  }
  .has-header-top .navbar-collapse,
  .has-header-top .navbar-collapse.collapsing {
    top: 120px;
  }
  .has-header-top .navbar .collapse.show {
    height: calc(100vh - 120px);
  }
  .has-header-top .navbar-inside {
    padding-right: 0;
  }
  .filter-tags {
    width: 90%;
  }
  .grid-content.has-four-column .grid-item {
    width: 33.33%;
  }
  #header4 .new-header-right {
    margin-right: 60px;
  }
  .hero-container {
    background-position: right;
  }
  .intro-content h2 {
    font-size: 38px;
    line-height: 1.5;
  }
  /*.hero-img-outer{margin-right:-90px;}*/
  .product-gridview-list {
    width: calc(100% + 16px);
    margin-left: -8px;
  }
  .product-gridview-slider.product-gridview-list {
    margin-top: -45px;
  }
  .pgl-item {
    padding: 8px;
  }
  .post-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  /*.pgl-content-box{padding:20px;}*/

  /*.add-button-outer{bottom: 30px;}*/
}
@media (min-width: 991.98px) {
  .has-menu i {
    display: none !important;
  }
  #footer3 .footer-nav-link-list li + li {
    margin-left: 40px;
  }
  .close-menu {
    display: none !important;
  }
  #homeMenuSiderbar {
    left: 0;
    width: 100%;
    min-width: 550px;
    max-width: 100%;
    opacity: 1;
    visibility: visible;
    position: fixed;
    right: 0px;
    top: 120px;
    bottom: auto;
    right: auto;
    height: 50px;
    display: flex;
    align-items: center;
    box-shadow: none;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    z-index: 99;
  }
  .new-sidemenu-box {
    padding: 0;
    overflow: inherit;
  }
  .nsb-header a img,
  .nsb-head a img {
    display: none;
  }
  .nsb-head a,
  .nsb-header a,
  ul.nsb-menu-list li.nsb-menu-item > a {
    border-bottom: 0;
    padding: 0 25px;
    position: relative;
    line-height: 50px;
  }
  .nsb-body {
    padding: 0;
  }
  .nsb-head b {
    font-weight: 400;
    font-size: 14px;
    color: #000;
  }
  li.nsb-menu-item.has-menu {
    position: relative;
  }
  ul.sub-menu {
    background: #fff;
    box-shadow: 5px 25px 15px 0 rgb(0 0 0 / 10%);
    font-size: 12px;
    color: #4f4f4f;
    border-radius: 0;
    border: 1px solid #d8d8d8;
    padding: 0;
    min-width: 215px !important;
    max-width: 100% !important;
    position: absolute;
    top: 100%;
    opacity: 0;
    visibility: hidden;
  }
  ul.nsb-menu-list li.nsb-menu-item.has-menu:hover ul.sub-menu {
    opacity: 1;
    visibility: visible;
  }
  ul.sub-menu li.sm-item.has-inner-menu:hover ul.inner-sub-menu {
    opacity: 1;
    visibility: visible;
  }
  .sub-menu li.sm-item a {
    display: block;
    width: 100%;
    clear: both;
    font-weight: 400;
    color: #000;
    text-align: inherit;
    white-space: nowrap;
    background-color: transparent;
    border: 0;
    padding: 6px 15px;
    white-space: nowrap;
  }
  ul.sub-menu li.sm-item {
    position: relative;
  }
  ul.inner-sub-menu {
    background: #fff;
    box-shadow: 5px 25px 15px 0 rgb(0 0 0 / 10%);
    font-size: 12px;
    color: #4f4f4f;
    border-radius: 0;
    border: 1px solid #d8d8d8;
    padding: 0;
    min-width: 215px !important;
    max-width: 100% !important;
    position: absolute;
    left: 100%;
    top: -1px;
    opacity: 0;
    visibility: hidden;
  }
  .nsb-box {
    white-space: nowrap;
  }
  ul.sub-menu li > a:hover,
  ul.sub-menu li.sm-item a:hover i,
  ul.nsb-menu-list li.nsb-menu-item:hover > a,
  ul.nsb-menu-list li.nsb-menu-item:hover > a i {
    color: #297ab9;
  }
  ul.sub-menu li.sm-item > a:before {
    content: "\f054";
  }
  .new-search-row .input-group + .input-group {
    margin-left: 20px;
  }
  .pll-price {
    position: absolute;
    right: 35px;
    bottom: 110px;
  }
  .pll-box .add-button-outer {
    left: auto;
    bottom: 35px;
    right: 35px;
    max-width: 400px;
  }
}

/*==========================================
! Medium devices (tablets, less than 992px)
========================================== */
@media (max-width: 991.98px) {
  /*body.home{padding-top:120px;}*/
  .has-header-top {
    box-shadow: none !important;
  }
  .has-header-top .navbar-toggler {
    display: block !important;
  }
  .has-header-top .navbar.navbar-expand-xl .cart-icon {
    padding-right: 35px;
  }
  .has-header-top .navbar {
    height: 113px;
    padding: 19px 0;
  }
  .has-header-top .mob-search-box {
    width: 100%;
    margin-top: 15px;
  }
  .has-header-top button.navbar-toggler {
    right: auto;
    left: 0;
    top: 0;
    transform: none;
  }
  .has-header-top .navbar-toggler {
    display: block !important;
    height: 24px;
    width: 35px;
  }
  .has-header-top .navbar-brand {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -5px;
  }
  .has-header-top .price-icon {
    width: 14px;
    height: 14px;
    top: -3px;
    right: -2px;
  }
  .has-header-top .nav-inside {
    position: relative;
  }
  .has-header-top .mob-search-box .input-group {
    background: #f5f5f5;
    border-radius: 5px;
  }
  .has-header-top .basic-addon-box {
    margin-left: 9px;
  }
  .header-top {
    letter-spacing: 0;
    height: 60px;
    display: flex;
    align-items: center;
  }
  .has-header-top .edit-text {
    color: rgb(255 255 255 / 50%);
    margin-left: 13px;
  }
  .header-dropdown-menu img + i {
    margin-left: 8px;
  }
  .has-header-top .cart-icon > i {
    width: 24px;
  }
  .has-header-top .input-group input {
    height: 40px;
    font-size: 12px;
  }
  .has-header-top .input-group img.close {
    right: 26px;
    float: none;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
  .header-bottom .view-type {
    margin: 0;
    display: block !important;
  }
  .filter-result-box {
    font-size: 12px;
    letter-spacing: 0;
    color: rgb(51 51 51 / 50%);
  }
  .header-bottom {
    background: #fff;
    border-top: 1px solid #e0e0e0;
    border-bottom: 1px solid #e0e0e0;
    padding: 10px 0px 10px 5px;
  }
  .header-dropdown-menu {
    cursor: pointer;
  }
  .wrapper-container {
    padding-top: 105px;
    padding-bottom: 60px;
  }
  .filter-tags .tags + span {
    display: none;
  }
  .filter-tags {
    width: 100%;
  }
  .filter-tags .tags {
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
  }

  /*filter sidebar start*/
  .filter-cat-box.tab-modify-slidebar {
    width: 100%;
    max-width: 100% !important;
    height: calc(100vh - 60px);
    top: 60px;
  }
  .filter-cat-box.tab-modify-slidebar.show {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .filter-cat-box .mob-menu {
    height: 100%;
  }
  .filter-cat-box .mb-filter-head {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-right: 50px;
  }
  .filter-cat-box .filter-text {
    font-weight: 600;
    font-size: 14px;
  }
  .filter-cat-box .filter-clear a {
    color: #297ab9;
  }
  .filter-cat-box .mob-menu-body {
    height: calc(100vh - 158px);
    overflow-y: auto;
  }
  .filter-cat-box .mb-filter {
    height: 100%;
  }
  .filter-cat-box .mb-filter-left {
    width: 157px;
    background: #f5f5f5;
  }
  .filter-cat-box .mb-filter-left .nav-tabs {
    flex-flow: column;
    border-bottom: none;
  }
  .filter-cat-box .nav-tabs .nav-item {
    margin-bottom: 0;
  }
  .filter-cat-box .nav-tabs .nav-link {
    border: 0;
  }
  .filter-cat-box .mb-filter-left .nav-tabs button {
    background: transparent;
    border-radius: 0;
    padding: 10px 20px;
    text-align: left;
    color: #333;
    border: 0;
    font-size: 14px;
  }
  .filter-cat-box .mb-filter-left .nav-tabs .nav-link.active {
    border: 0;
    color: #297ab9;
    font-weight: 600;
  }
  .filter-cat-box .mb-filter-right {
    width: calc(100% - 157px);
    padding: 20px;
  }
  .filter-cat-box .mob-filter-footer {
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 100%;
    left: 0;
    right: 0;
    border-top: 1px solid #e0e0e0;
  }
  .filter-cat-box .footer-btn {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background: #fff;
  }
  .filter-cat-box .footer-btn.close {
    font-size: 14px;
    color: #000;
    font-weight: 400;
  }
  .filter-cat-box .filter-btn-apply {
    background: #297ab9;
    color: #fff;
  }
  .filter-cat-box .filter-tab-content .form-check {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .filter-cat-box .form-check-nob {
    position: absolute;
    right: 0;
    margin-bottom: 0;
    font-size: 12px;
    color: rgb(0 0 0 / 50%);
    top: 50%;
    transform: translateY(-50%);
  }
  .filter-cat-box
    .filter-tab-content
    .form-check
    input[type="checkbox"]
    + .form-check-label::before {
    border: 1px solid #4d4d4d;
    border-radius: 3px;
    top: 2px;
  }
  .filter-cat-box
    .filter-tab-content
    .form-check
    input[type="checkbox"]
    + .form-check-label::after {
    top: 2px;
  }
  .filter-cat-box
    .filter-tab-content
    .form-check
    input[type="checkbox"]
    + label {
    color: #000;
  }
  .filter-cat-box
    .filter-tab-content
    .form-check
    input[type="radio"]
    + .form-check-label::before {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    top: 0;
  }
  .filter-cat-box
    .filter-tab-content
    .form-check
    input[type="radio"]
    + .form-check-label::after {
    background: #fff;
    width: 8px;
    height: 8px;
    top: 6px;
    left: 6px;
  }
  .pickup-map a {
    color: #297ab9;
    text-decoration: underline;
    font-size: 16px;
  }
  .filter-cat-box .price-range-slider {
    margin-bottom: 20px;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-line {
    height: 2px;
    color: #e4e4e4;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-bar {
    height: 2px;
    background: #297ab9;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-handle {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #297ab9;
    border-radius: 50%;
    top: 15px;
    cursor: pointer;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-handle > i:first-child {
    display: none !important;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-min,
  .filter-cat-box .price-range-slider .irs--flat .irs-max {
    display: none;
  }
  .filter-cat-box .price-range-slider .irs--flat .irs-from,
  .filter-cat-box .price-range-slider .irs--flat .irs-to,
  .filter-cat-box .price-range-slider .irs--flat .irs-single {
    display: none;
  }
  .filter-cat-box .price-box .input-group {
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    height: 29px;
    width: 78px;
    color: #000;
  }
  .filter-cat-box .price-box .form-control {
    height: 100%;
    padding: 6px;
  }
  .filter-cat-box .price-box p {
    margin-bottom: 0;
  }
  .filter-tab-content .form-check img {
    margin-right: 5px;
  }
  .filter-cat-box.filter-modify-sidebar {
    width: 100%;
    max-width: 100% !important;
    height: calc(100vh - 38px);
    top: 38px;
  }
  .filter-cat-box.tab-modify-slidebar .tab-header a {
    top: 15px;
    right: 5px;
  }

  /*filter sidebar end*/
  .mob-filter-btn-group {
    border-top: 1px solid #e0e0e0;
    width: 100%;
    height: 60px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
  }
  .mob-filter-btn-group a {
    color: #000 !important;
    width: 50%;
    float: left;
    position: relative;
    height: 100%;
    flex-grow: 1;
  }
  .mob-filter-btn-group a img {
    margin-right: 10px;
  }
  .mob-filter-btn-group a p span {
    color: #297ab9;
  }
  .mob-filter-btn-group .sort-btn:before {
    content: "";
    width: 1px;
    height: 100%;
    background: #e0e0e0;
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }
  button#siderMenu {
    display: block !important;
  }
  .eob-icon {
    width: 120px;
    margin: 0 auto 30px;
  }
  .empty-order-box h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }
  .main-content-box {
    width: 100%;
    padding: 50px 0 35px;
  }
  .open-sider-menu .sidebar {
    left: 0;
    visibility: visible;
    opacity: 1;
  }
  .sidebar {
    border: 0;
    padding: 50px 25px 30px;
    opacity: 0;
    visibility: hidden;
    position: fixed;
    top: 0;
    bottom: 0;
    left: -245px;
    background: #fff;
    z-index: 9999999;
    transition: all 0.3s cubic-bezier(0.77, 0.2, 0.05, 1);
    box-shadow: 0 0 25px rgb(0 0 0 / 10%);
  }
  .close-menu {
    position: absolute;
    top: 15px;
    right: 15px;
  }
  .sidebar-menu {
    height: 100%;
    width: 100%;
    overflow-y: auto;
  }
  .conetnt-box h3 {
    font-size: 18px;
    line-height: 1.2;
    margin-bottom: 30px;
  }
  .account-details-box .form-group.form-button {
    margin: 15px 0 0;
  }
  .member-container {
    height: auto;
    flex-flow: column;
    padding-top: 82px;
  }
  .member-left {
    width: 100%;
  }
  .member-right {
    width: 100%;
    padding: 35px 25px;
  }
  .signin-form-container {
    padding: 0;
  }
  .signin-form {
    max-width: 100%;
  }
  .member-left h2 {
    padding-right: 0;
  }
  .logo-area {
    width: 150px;
    margin-bottom: 35px;
  }
  .mob-logo-box .navbar-brand {
    width: 135px;
    margin: 15px;
    padding: 0;
  }
  .mob-logo-box {
    height: 82px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #fff;
    z-index: 99;
  }
  .has-map #header,
  .has-map #header3 {
    left: 0;
  }
  .has-map .container {
    width: 100%;
  }
  body.has-map {
    padding-left: 0%;
  }
  .has-map #mainNav {
    left: 0%;
  }
  #footer.footer.sticky {
    position: absolute;
    left: 0;
    width: 100%;
  }
  .search-bar-container {
    left: 0;
    position: relative;
    top: 0;
  }
  .fixed .search-bar-container {
    box-shadow: none;
  }
  .no-result-container {
    padding-top: 40px;
  }
  .list-item-container,
  .grid-item-container {
    padding-top: 0px;
  }
  .product-list {
    margin-bottom: 40px;
  }
  .search-item {
    width: 33.33%;
  }
  .schedule-day {
    margin-bottom: 20px;
  }
  .form-group .verified {
    font-size: 13px;
    top: 4px;
    right: 0;
  }
  .map {
    width: 100%;
    position: relative;
    top: auto;
    left: auto;
    height: 50vh;
  }
  ul.progressbar li span {
    display: none;
  }
  ul.progressbar li.active span {
    display: block;
  }
  ul.progressbar li {
    padding: 0 60px 0 10px;
  }
  ul.progressbar li:not(:last-child):before {
    width: 50px;
  }
  ul.progressbar .progressbar-icon {
    margin-right: 0px;
  }
  ul.progressbar li.active .progressbar-icon {
    margin-right: 10px;
  }
  .cc-box strong {
    margin-bottom: 20px;
  }
  .cc-left-content,
  .cc-right-content {
    width: 100%;
    padding: 0;
  }
  .cc-left-content {
    margin-bottom: 23px;
  }
  .cc-right-content {
    border-top: 1px solid #e0e0e0;
    border-left: 0;
    padding-top: 30px;
  }
  .mob-no-border .cc-right-content {
    border-top: 0;
    padding-top: 0;
  }
  .success-modal-popup .modal-content {
    padding: 30px 20px;
  }
  .product-details-tab.nav {
    width: calc(100% + 24px);
    margin-left: -12px;
  }
  .product-details-tab.nav-tabs .nav-item {
    padding: 0 12px;
  }
  .product-details-tab.nav-tabs .nav-link {
    font-size: 16px;
    padding: 0 0 14px;
  }
  .product-details-container .tab-content {
    /* padding:0; */
  }
  .form-group.form-button2 {
    max-width: 100%;
  }
  .address-box {
    max-width: 100%;
    width: calc(100% + 30px);
    margin-left: -15px;
    display: flex;
    flex-wrap: wrap;
  }
  .address-box .ab-left {
    width: 50%;
    padding: 0 15px;
  }
  .address-box .ab-right {
    width: 50%;
    padding: 0 15px;
  }
  .address-text-box {
    margin-bottom: 0;
  }
  .address-billing-box {
    height: 175px;
  }
  .address-box .form-group.sidebar-btn,
  .account-details-box .form-group.sidebar-btn {
    margin-top: 30px;
    padding-left: 15px;
  }
  .custom-modal .modal-header {
    padding: 20px 30px;
    height: 70px;
  }
  .custom-modal .modal-title {
    font-size: 20px;
    line-height: 18px;
  }
  .product-nav-box {
    padding-top: 0;
    margin-left: 0;
  }
  ul.product-nav-list li .pn-link {
    font-size: 15px;
    line-height: 66px;
  }
  .custom-modal .close {
    right: 30px;
    width: 26px;
    top: 25px;
  }
  .custom-modal .modal-body {
    padding: 40px 30px 50px;
  }
  .custom-modal .modal-footer {
    padding: 15px 30px;
  }
  .custom-modal .modal-footer button.btn.btn-xs {
    font-size: 16px;
    padding: 12px 10px;
  }
  .file-upload-modal .modal-btn-box {
    margin-top: 100px;
  }
  .mcb-left label {
    font-size: 15px;
  }
  .id-box b {
    font-size: 28px;
  }
  .id-box p {
    font-size: 14px;
  }
  .main-content {
    margin-left: 0;
    width: 100%;
    padding: 0 15px !important;
  }
  .filter-container {
    left: 0;
    position: relative;
    top: auto;
    height: auto;
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .filter-list-container {
    padding-top: 0;
    padding-bottom: 40px;
  }
  ul.seller-list {
    column-count: 3;
  }
  ul.sub-menu {
    display: none;
  }
  ul.sub-menu li > a {
    width: 100%;
    padding: 8px 6px 8px 35px;
    position: relative;
  }
  ul.inner-sub-menu {
    display: none;
    padding-left: 15px;
  }
  ul.nsb-menu-list li.nsb-menu-item > a {
    font-size: 14px;
    color: #000;
  }
  ul.sub-menu li.sm-item > a:before {
    content: "\f078";
  }
  ul.sub-menu li > a.active,
  .nsb-menu-item > a.active {
    color: #297ab9 !important;
  }
  .tags span.tag-heading,
  select#sortBy,
  .sc-box-right .btn {
    display: none !important;
  }
  .mb-filter-right .form-check input[type="radio"] + label {
    color: #000;
  }
  .mb-filter-left .nav-link {
    padding: 12px 15px;
  }
  .avability > span {
    display: none !important;
  }
  .filter-list-container {
    padding-left: 0;
    padding-right: 0;
  }
  .tags span {
    padding: 8px 25px 8px 12px;
  }
  .filter-tags .badge + .badge {
    margin-left: 8px;
  }
  .nsb-header a {
    font-size: 16px;
  }
  .nsb-head a:before,
  ul.nsb-menu-list li.nsb-menu-item > a:before,
  ul.sub-menu li.sm-item > a:before {
    content: none !important;
  }
  .has-menu i {
    position: absolute;
    right: 0;
    top: 0;
    z-index: 99999;
    width: 65px;
    height: 60px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #333;
  }
  .has-menu {
    position: relative;
  }
  .sc-box-left {
    width: 100%;
  }
  #mob-footer {
    position: relative;
    left: auto;
    bottom: auto;
    width: 100%;
  }
  .header-top .edit-text-dropdown .dropdown-menu:before {
    left: 8px;
  }
  .header-top .edit-text-dropdown .dropdown-menu {
    margin-top: 15px;
    margin-left: -10px;
    white-space: normal;
  }
  .filter-list-container .grid-content {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
  .media-block-container {
    padding: 20px 0 25px;
  }
  .mdc-media-box {
    width: 100%;
    position: relative;
    padding-bottom: 46.5%;
    max-width: 500px;
    margin: 25px auto 0;
  }
  #header4 .header-btn-group {
    margin: 0;
    justify-content: center;
  }
  .hpc-heading-box > h5 {
    font-size: 16px;
  }
  .hpc-right {
    justify-content: space-between;
    margin-top: 30px;
  }
  .post-list {
    width: calc(100% + 20px);
    margin-left: -10px;
  }
  .post-list li {
    padding: 10px;
  }
  #footer1 .footer-content {
    padding: 0;
    line-height: 19px;
  }
  #footer1 .footer-nav {
    width: 100%;
    margin-left: 0;
    justify-content: center;
  }
  #footer1 .footer-content-right {
    text-align: center;
    padding-top: 18px;
  }
  .tab-modify-slidebar.tab-bott-modifyer {
    width: 100%;
    max-width: 100%;
    top: auto;
  }
  .tab-modify-slidebar.tab-bott-modifyer.show {
    right: 0;
    opacity: 1;
    visibility: visible;
  }
  .daw-text-box h4 {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #000;
    margin-bottom: 20px;
  }
  .daw-text-box {
    padding: 22px;
  }
  .btn-cancel {
    width: 50%;
    font-weight: 600;
    border-top: 1px solid #e0e0e0;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    padding: 17px 15px;
    color: #333;
  }
  .btn-apply {
    width: 50%;
    color: #fff;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    text-align: center;
    padding: 17px 15px;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
      #297ab9;
  }
  .daw-search input.form-control {
    border-radius: 5px;
    background: #f5f5f5;
    border-color: #f5f5f5;
    font-size: 14px;
    line-height: 19px;
  }
  .daw-search input.form-control::placeholder {
    color: rgba(0, 0, 0, 0.5);
  }
  ul.grid-content.has-four-column.no-sm-border.hpc-product-slider {
    padding-bottom: 0 !important;
  }
  .footer-nav-link-list li > a {
    line-height: 35px;
  }
  #footer3 {
    text-align: center;
    padding: 25px 0 0;
  }
  #footer3 .footer-nav-link-list {
    margin-bottom: 15px;
  }
  #footer3 .copyright {
    background: rgb(0 0 0 / 20%);
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 18px 0;
  }
  .new-wrapper-container {
    position: relative;
    padding-top: 182px;
  }
  .filter-nav-wrapper {
    background: #fff;
    position: fixed;
    width: 100%;
    top: 173px;
    left: 0;
    right: 0;
    padding: 0;
    height: 128px;
    z-index: 999;
  }
  .fnr-side-nav-btn span,
  .fnr-left {
    display: none !important;
  }
  .form-check-outer,
  .fnr-right,
  .sortby-row {
    flex-grow: 1 !important;
  }
  .form-check-outer .new-form-check {
    width: auto;
    flex: 1 1 auto;
    padding: 2.5px;
  }
  .new-form-check .form-check-label {
    min-width: 100%;
    padding: 9.62px 8px;
  }
  .pricing-select {
    margin-left: auto !important;
  }
  .fnr-dividor {
    margin: 8px 4px 8px 6.5px;
  }
  .fnr-side-nav-btn {
    padding: 9.62px 12px 9.62px 12px;
  }
  .fnr-side-nav-btn > img {
    margin-right: 6px;
  }
  .new-form-check .form-check-label > img {
    margin-right: 6px;
  }
  .filter-nav-column:not(:last-child):before {
    content: "";
    width: calc(100% + 34px);
    margin-left: -17px;
    height: 1px;
    background: #e4e4e4;
    position: absolute;
    top: 0;
  }
  .filter-nav-column:after {
    content: "";
    width: calc(100% + 34px);
    margin-left: -17px;
    height: 1px;
    background: #e4e4e4;
    position: absolute;
    bottom: 0;
  }
  .filter-nav-column {
    position: relative;
    padding: 17.5px 0;
  }
  .mob-filter-button {
    cursor: pointer;
    background: #fff;
    box-shadow: 0px 3px 20px rgb(0 0 0 / 7%);
    border-radius: 4px;
    padding: 12px 20px;
  }
  .mob-filter-button > img {
    margin-right: 10px;
  }
  .result-status-row {
    padding: 20px 0;
    font-size: 14px;
    line-height: 19px;
  }
  .pll-img-box {
    padding-bottom: 80px;
    position: relative;
  }
  .favicon-block {
    right: 20px;
    top: 20px;
  }
  .pll-text-box {
    padding: 30px;
  }
  .pll-price {
    font-size: 16px;
    line-height: 26px;
    margin-bottom: 14px;
  }
  .pll-text-box h5 {
    font-size: 14px;
    margin-bottom: 15px;
  }
  .pll-rating {
    margin-bottom: 16px;
  }
  .pll-sub-text {
    margin-bottom: 16px;
  }

  /*.product-gridview-list{width:calc(100% + 10px);margin-left:-5px;}
.pgl-item{padding:5px;}*/
  .pgl-item {
    width: 33.33%;
  }
  .pricing-dropdown {
    margin-right: 0;
    margin-left: auto;
  }
  .hpc-header h4 {
    font-size: 18px;
  }
  .hpc-header p {
    font-size: 16px;
    line-height: 23px;
  }
  .pll-img-box .add-button-outer {
    bottom: 15px !important;
    top: auto !important;
  }

  .pll-box .add-button-outer {
    bottom: 25px !important;
    top: auto !important;
    max-width: 400px;
  }

  .pll-img-box.active-list,
  .pll-text-box.active-list {
    filter: blur(1px);
  }
}

/* =================================================  
! Small devices (landscape phones, less than 768px)
================================================= */
@media (max-width: 768.98px) {
  .content-container {
    padding: 40px 0;
  }
  .member-left,
  .member-right {
    padding: 35px;
  }
  .signin-form-container {
    padding: 0;
  }
  .member-left h1 {
    margin-bottom: 40px;
  }
  .member-left h2 {
    font-size: 24px;
    line-height: 35px;
  }
  .dashboard-container {
    padding-top: 65px;
  }
  .breadcrumbs li {
    padding: 0;
  }
  .header-right {
    margin-right: 40px;
  }
  .navbar-brand {
    width: 90px;
  }
  .search-item {
    width: 50%;
  }
  .input-group input {
    padding: 10px;
  }
  .footer-nav-list:first-child:before {
    display: none;
  }
  .submenu-nev-item {
    margin-right: 30px;
    line-height: 40px;
  }
  .submenu-nev-item a {
    line-height: 40px;
    font-size: 14px;
  }
  .form-check-group .form-check {
    margin-bottom: 10px;
  }
  .seller-info .seller-img {
    position: relative;
    width: 55px;
  }
  .btn {
    font-weight: 600;
    padding: 6px 10px;
    min-width: 100px;
    font-size: 13px;
    line-height: 18px;
  }
  .form-control {
    font-size: 13px;
  }
  .form-check input[type="radio"] + label {
    padding-left: 30px;
    font-size: 14px !important;
    line-height: 16px !important;
  }
  .form-check input[type="radio"] + .form-check-label::before,
  .form-check input[type="radio"] + .form-check-label::after {
    width: 15px;
    height: 15px;
  }
  .search-item-content .input-group {
    max-width: 100% !important;
    margin-bottom: 20px;
  }
  .tab-modify-box {
    padding: 25px !important;
  }
  .contact-slide-inner {
    padding: 25px !important;
  }
  .tab-modify-slidebar {
    max-width: 100% !important;
  }
  .contact-slidebar-container {
    max-width: 100% !important;
  }
  .tab-modify-slidebar h3 {
    font-size: 24px;
    margin-bottom: 20px !important;
  }
  .contact-slide-inner h3 {
    font-size: 24px;
    margin-bottom: 20px !important;
  }
  .tab-modify-box .pannel-top {
    top: 24px !important;
    right: 20px !important;
    width: 16px;
  }
  .tab-modify-box .qps-group .qty,
  .tab-modify-box .qps-group .price {
    margin-right: 10px !important;
  }
  .btn-upload,
  .btn-upload {
    line-height: 16px !important;
    padding: 10px 5px;
  }
  .operation-list .timing-panel,
  .operation-list .operation-item .small {
    line-height: 25px;
    width: 100%;
  }
  .operation-list .operation-item + .operation-item {
    margin-top: 15px;
  }
  .operation-item-right {
    opacity: 1;
  }
  .schedule-time span.small {
    width: 100%;
    margin: 5px 0;
    text-align: left;
  }
  .form-check.type2 input[type="checkbox"] + label {
    font-size: 14px !important;
    line-height: 16px !important;
    margin-right: 25px;
    padding-left: 30px;
  }
  .form-check.type2 input[type="checkbox"] + .form-check-label::before,
  .form-check.type2 input[type="checkbox"] + .form-check-label::after {
    width: 16px;
    height: 16px;
  }
  .form-check.type2 input[type="checkbox"] + label {
    margin-right: 18px !important;
    padding-left: 25px;
  }
  .schedule-list .form-group {
    margin-bottom: 0 !important;
    width: 100%;
  }
  .table-responsive .table tr td {
    padding: 10px 20px;
  }
  .delete-this-field {
    display: flex;
  }
  .delete-this-field img {
    margin-left: 8px;
    width: 20px;
  }
  .add-more-field {
    color: #297ab9;
    margin-left: 0;
    width: 100%;
    margin-top: 15px;
    text-align: left;
  }
  .seller-info .seller-img {
    width: 60px !important;
    height: 60px;
  }
  .table-responsive .table tr td {
    white-space: nowrap;
  }
  .table-responsive .table thead tr {
    white-space: nowrap;
  }
  .table-responsive .table tr td.editable {
    min-width: 90px;
  }
  .map-product-popup {
    width: 300px;
    padding: 15px 15px 15px 0;
  }
  .product-popup-left {
    width: 100px;
  }
  .product-popup-right {
    width: calc(100% - 100px);
  }
  .map-marker-img {
    width: 60px;
    top: 80%;
    height: 65px;
  }
  ul.query-list li a {
    display: none;
  }
  .header-top ul.query-list li + li {
    margin-left: 15px;
  }
  .ec-img-box {
    width: 125px;
    margin: 0 auto 25px;
  }
  .empty-cart-content h4 {
    font-size: 22px;
  }
  .checkout-form-container {
    padding: 0;
  }
  .smp-footer {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .smpf-left,
  .smpf-right {
    width: 100%;
    padding: 0 15px;
  }
  .smpf-left {
    margin-bottom: 15px;
  }
  .smpf-left .btn {
    padding: 15px 25px;
    font-size: 15px;
  }
  .smpf-left a + a {
    margin: 10px 0 0 !important;
  }
  .table2 tr td {
    padding: 15px 15px 15px;
  }
  .table-box {
    margin-bottom: 25px;
  }
  .cc-right-content h3,
  .cc-left-content h3 {
    font-size: 16px;
  }
  .ccr-text-box {
    margin-bottom: 25px;
  }
  .smp-table-box .table2 {
    margin-bottom: 0;
  }
  tfoot tr td {
    padding: 25px 0 25px !important;
    font-size: 16px !important;
  }
  .mob-pb-0 {
    padding-bottom: 0 !important;
  }
  .sac-content-box {
    padding: 35px 0px;
  }
  .sac-img {
    width: 100px;
  }
  .sac-content-box h4 {
    font-size: 22px;
    line-height: 1.2;
  }
  .seprator {
    margin: 30px auto 40px;
  }
  .btn2 {
    padding: 16px 25px;
    font-size: 16px;
  }
  .sac-btn a + a {
    margin: 15px 0 0 !important;
  }
  .list-img {
    margin: 0 auto 25px;
  }
  .list-details {
    width: 100%;
  }
  .form-check-outer .form-check + .form-check {
    margin-left: 15px;
  }
  .tab-body {
    max-width: 100%;
  }
  .feedback_ratings {
    width: calc(100% + 30px);
    margin-left: -15px;
    margin-bottom: 30px;
  }
  .fr-left,
  .fr-right {
    padding: 0 15px;
  }
  .feedback_rating {
    font-size: 16px;
    width: 55px;
    height: 38px;
  }
  .fr-right .feedback_rating + .feedback_rating {
    margin-left: 10px;
  }
  .review-box {
    padding: 20px;
  }
  .review-box .star-list {
    font-size: 22px;
    margin-bottom: 15px;
  }
  .review-box p:not(:last-child) {
    margin-bottom: 15px;
  }
  .review-box + .review-box {
    margin-top: 25px;
  }
  .cr-progress-left {
    width: 100%;
  }
  .cr-progress-right {
    width: 100%;
    padding-left: 0;
    margin-top: 25px;
  }
  .product-details-container .tab-content > .tab-pane {
    display: block !important;
    opacity: 1;
    border-color: #ededed;
  }
  .product-details-container .card-body {
    padding: 15px;
  }
  .product-details-container .card-body {
    width: 100%;
  }

  /*REMOVE TAB ON MOBILE*/
  .untab .collapse:not(.show) {
    display: block !important;
  }
  .add-to-cart-dropdown-box.mob-sticky {
    height: 60px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99999;
    display: flex;
    align-items: center;
  }
  .product-details .mob-sticky .add-to-cart-dropdown.dropdown,
  .product-details .mob-sticky .add-to-cart-dropdown.dropup {
    width: 50%;
    max-width: 100%;
    flex-grow: 1;
    height: 60px;
  }
  .mob-sticky .wishlist-btn {
    line-height: 40px;
    display: flex;
    align-items: center;
    height: 60px;
    flex-grow: 1;
    width: 50%;
    padding: 10px;
    text-align: center;
    background: #fff;
  }
  .mob-sticky .add-to-cart-dropdown.dropdown > button,
  .mob-sticky .add-to-cart-dropdown.dropup > button {
    border-radius: 0;
  }
  .mob-sticky .wishlist-btn > img {
    margin-right: 10px;
  }
  .mob-sticky.add-to-cart-dropdown-box .dropdown-menu {
    margin-bottom: 0 !important;
  }
  .mob-sticky .atc-dropdown .drop-link {
    padding: 7px 35px;
  }
  .new-product-slider ul.slick-dots {
    bottom: -15px;
  }
  .product-container.mob-large-padd {
    padding-top: 50px;
  }
  .mb-product-rate {
    margin-bottom: 10px;
    align-items: center;
  }
  .mb-product-left p {
    font-size: 12px;
    line-height: 16px;
    color: #4f4f4f;
    display: flex;
    align-items: center;
  }
  .mb-product-left .star-icon {
    color: #ffc531;
    margin-left: 7px;
  }
  .mb-product-left .reviews-text {
    margin-left: 7px;
  }
  .mb-product-star .star-list {
    margin-bottom: 0;
  }
  .mb-product-heading h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 20px;
  }
  .np-img-box {
    border-radius: 5px;
    overflow: hidden;
  }
  .mb-border {
    width: calc(100% + 30px);
    height: 5px;
    background: rgb(224 224 224 / 50%);
    margin: 15px 0 15px -15px;
  }
  .product-list {
    border-bottom: none;
  }
  .product-price {
    font-weight: 600;
    font-size: 21px;
  }
  .product-description h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #000;
  }
  .avability {
    font-size: 12px;
    line-height: 16px;
    color: #898989;
  }
  .untab {
    padding-top: 0px;
  }
  .untab .card-header {
    background-color: transparent;
    border-bottom: none;
    padding: 0;
  }
  .untab .tab-content > .tab-pane {
    border: none;
    width: calc(100% + 30px);
    margin-left: -15px;
    padding: 0 15px;
    border-bottom: 5px solid rgb(224 224 224 / 50%);
    margin-bottom: 20px;
  }
  .untab .tab-content > .tab-pane:last-child {
    border-bottom: none;
  }
  .untab .card-body {
    padding: 15px 0;
  }
  .untab .card-header h5 {
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
  }
  .untab .seller-profile-name {
    margin-left: 0;
    font-size: 13px;
    color: rgb(0 0 0 / 85%);
  }
  .untab .cr-progress-left {
    border-bottom: 1px solid #ededed;
    padding-bottom: 20px;
  }
  .untab .cr-progress-right p {
    font-size: 14px;
    line-height: 160%;
    letter-spacing: 0.5px;
  }
  .untab .cr-progress-left .crp-item > span {
    font-size: 14px;
  }
  .reviews-text {
    margin-left: 6px;
  }
  .open-cart-menu .overlay {
    display: block;
    background: rgb(0 0 0 / 70%);
    z-index: 9999;
  }
  .add-to-cart-dropdown .dropdown-menu {
    width: 100vw;
    min-width: 100%;
    border-radius: 0;
    left: 5px !important;
    z-index: 9999;
    background: #fff; /*border:none;border-bottom:1px solid #D8D8D8;*/
    padding: 20px 0;
  }
  .atc-dropdown .drop-link {
    font-size: 14px;
    color: #333333;
  }
  .address-box .form-group.sidebar-btn,
  .account-details-box .form-group.sidebar-btn {
    margin-top: 35px;
  }
  .tab-header strong {
    margin-bottom: 20px;
  }
  .product-details-container .tab-content {
    padding: 0;
  }

  /*Responsive Table Start */
  .ob-body .table tbody th,
  .ob-body .table tbody tr {
    border: none !important;
  }
  .ob-body .table-responsive .table tr td:first-child {
    width: 100%;
  }
  .ob-body .table-responsive .small.table tr td:first-child span {
    height: auto;
    margin-left: auto;
  }
  .ob-body .table-responsive .table tr td:nth-child(2) {
    text-align: right;
  }
  .ob-body .table-wrap tr:last-child td:first-child:before {
    content: none !important;
  }
  .table_wrap .table2 tr td:first-child {
    width: auto;
  }
  .table-responsive.table_wrap .small.table tr td {
    padding: 15px 15px 15px;
  }
  .table_wrap form.promo-code-form {
    width: 100%;
    min-width: 100%;
  }
  .table_wrap form.promo-code-form .form-control {
    width: calc(100% - 40px);
    height: 40px;
    font-size: 12px;
  }
  .table_wrap .table2 tr td {
    text-align: right;
  }
  .table_wrap .coupon-text,
  .table_wrap .table2 tr td:nth-child(2) b {
    font-size: 14px;
  }
  .table_wrap .table2 b,
  .table_wrap .table2 tr td:nth-child(4) b {
    font-size: 14px;
    font-weight: 400;
  }
  .table_wrap .table-notes-text {
    padding-right: 15px;
    text-align: left;
  }
  .table_wrap .notes {
    text-align: left;
    font-size: 12px;
    line-height: 1.5;
  }
  .table_wrap .b-core-ui-select {
    width: 40px;
    height: 40px;
    margin-left: 8px;
  }
  .table_wrap .b-core-ui-select > img {
    width: 15px;
  }
  .table_wrap .table2 .table-heading,
  .table_wrap .coupon-text,
  .table_wrap .table2 tr td:nth-child(2) b {
    font-weight: 400;
  }
  .mob-pt-0 {
    padding-top: 0 !important;
  }
  .table_wrap .table2 .dropdown-menu {
    right: 0 !important;
    left: auto !important;
  }
  .table_wrap .table-dropdown-form {
    padding: 8px 12px 0px;
  }
  .table_wrap .close-icon img {
    width: 18px;
  }
  .table_wrap .drop-content-box {
    padding: 10px 0;
  }
  .table-wrap table,
  .table-wrap thead,
  .table-wrap tbody,
  .table-wrap th,
  .table-wrap td,
  .table-wrap tr {
    display: block;
  }
  .table-wrap thead tr {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
  .table-wrap th,
  .table-wrap td {
    border: none;
    border-bottom: 1px solid #eee !important;
    position: relative;
    padding-left: 38% !important;
    white-space: normal;
    text-align: left;
  }
  .table-wrap th:before,
  .table-wrap td:before {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 15px;
    width: 45%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    text-align: left;
    color: #000;
  }
  .table-wrap td:first-child,
  .table-wrap th:first-child {
    padding-top: 17px;
  }
  .table-wrap th:last-child,
  .table-wrap td:last-child {
    padding-bottom: 16px;
  }

  /* .table-wrap th:first-child:before,.table-wrap td:first-child:before{top:17px;} */
  .table-wrap th:before,
  .table-wrap td:before {
    content: attr(data-title);
  }
  .table tbody th,
  .table tbody tr {
    border-top: 1px solid #ddd;
  }
  .table tbody tr td {
    border: none;
  }
  .table_wrap .small .dropdown-menu {
    min-width: 100% !important;
  }
  .table_wrap .btn-group,
  .btn-group-vertical {
    position: static;
  }
  .table_wrap .table-dropdown .dropdown-menu {
    right: 0;
  }
  .table_wrap .table.small form {
    padding: 25px 20px 25px 25px !important;
  }
  .table_wrap .btn-upload strong {
    font-size: 12px;
  }
  .table_wrap.table-responsive .table tr td b {
    font-size: 14px;
  }
  .table_wrap .btn-upload > span {
    font-size: 9px;
  }
  .table_wrap .btn-upload > span > img {
    width: 14px;
  }
  .table_wrap .stars-icon {
    margin-left: auto;
    justify-content: flex-end;
  }
  .table_wrap.table-responsive .table tr td {
    white-space: normal;
  }
  .mob-pl-0 {
    padding-left: 0 !important;
  }
  .mb-pt-0 {
    padding-top: 0 !important;
  }
  td.mob-hide {
    display: none !important;
  }
  td b.grand-total {
    position: absolute;
    left: 15px;
    font-size: 16px !important;
    top: 50%;
    transform: translateY(-50%);
  }

  /*Responsive Table Start */
  .tab-header {
    padding-right: 20px;
  }
  ul.table-text-list li {
    width: 50%;
  }
  .table-text-group {
    padding-bottom: 20px;
  }
  .order-search-item-container {
    margin-bottom: 25px;
  }

  /*.basic-addon-box img + span{display:none!important;}*/
  .basic-addon-box {
    width: auto;
  }
  .has-map .header-right-content {
    width: auto;
    margin-left: 15px;
  }
  .new-search-row {
    width: auto;
    padding-left: 0;
    padding-right: 0px;
  }
  .md-search-icon {
    width: 20px;
    cursor: pointer;
  }
  .has-map.search-open:before {
    content: "";
    width: 100%;
    height: 100%;
    background: #00000080;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
  }
  .search-open .new-search-row .input-group {
    opacity: 1;
    visibility: visible;
    right: 0;
    width: 100%;
    border-radius: 0;
  }
  .new-search-row .input-group {
    position: fixed;
    opacity: 0;
    right: -300px;
    visibility: hidden;
    width: 215px;
    top: 120px;
    transition: 0.2s all ease-in;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .new-search-row img.close {
    right: 15px;
  }
  .showing-result {
    margin-left: 0;
    margin-bottom: 15px;
  }
  .custom-modal .modal-body {
    padding-top: 0 !important;
  }
  .mob-product-nav {
    display: -ms-flexbox !important;
    display: flex !important;
    justify-content: center;
    -ms-flex-align: center !important;
    align-items: center !important;
  }
  .modal-icon-box {
    width: 35px;
    margin-bottom: 30px;
    margin-top: 30px;
  }
  .mcb-left {
    margin-bottom: 15px;
  }
  .mcb-left,
  .mcb-right {
    width: 100%;
  }
  .mcb-left label {
    font-size: 14px;
  }
  .mcb-left label > span {
    padding-left: 5px;
  }
  .map-content-box + .map-content-box {
    margin-top: 25px;
  }
  .mob-product-nav + .map-content-box {
    margin-top: 30px;
  }
  .step-content-box {
    margin-top: 40px;
  }
  ul.import-details-list {
    margin-top: 45px;
  }
  .id-box b {
    font-size: 20px;
  }
  .step-content-box h5 {
    font-size: 18px !important;
  }

  /*filter-box css*/
  .mob-menu {
    height: 100%;
  }
  .mb-filter-head {
    height: 60px;
    padding: 0 20px;
    border-bottom: 1px solid #e0e0e0;
    padding-right: 50px;
  }
  .filter-text {
    font-weight: 600;
    font-size: 14px;
  }
  .filter-clear a {
    color: #297ab9;
  }
  .mob-menu-body {
    height: calc(100vh - 158px);
    overflow-y: auto;
  }
  .mb-filter {
    height: 100%;
  }
  .mb-filter-left {
    width: 157px;
    background: #f5f5f5;
  }
  .mb-filter-left .nav-tabs {
    flex-flow: column;
    border-bottom: none;
  }
  .mb-filter-left .nav-tabs button {
    background: transparent;
    border-radius: 0;
    padding: 10px 20px;
    text-align: left;
    color: #333;
    border: 0;
    font-size: 14px;
  }
  .mb-filter-left .nav-tabs .nav-link.active {
    border: 0;
    color: #297ab9;
    font-weight: 600;
  }
  .mb-filter-right {
    width: calc(100% - 157px);
    padding: 20px;
  }
  .mob-filter-footer {
    position: fixed;
    bottom: 0;
    height: 60px;
    width: 100%;
    left: 0;
    right: 0;
    border-top: 1px solid #e0e0e0;
  }
  .footer-btn {
    width: 50%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
  }
  .filter-btn-apply {
    background: #297ab9;
    color: #fff;
  }
  .filter-tab-content .form-check {
    margin-right: 0;
    margin-bottom: 20px;
  }
  .form-check-nob {
    position: absolute;
    right: 0;
    margin-bottom: 0;
    font-size: 12px;
    color: rgb(0 0 0 / 50%);
    top: 50%;
    transform: translateY(-50%);
  }
  .filter-tab-content
    .form-check
    input[type="checkbox"]
    + .form-check-label::before {
    border: 1px solid #4d4d4d;
    border-radius: 3px;
    top: 2px;
  }
  .filter-tab-content
    .form-check
    input[type="checkbox"]
    + .form-check-label::after {
    top: 2px;
  }
  .filter-tab-content .form-check input[type="checkbox"] + label {
    color: #000;
  }

  /*.filter-tab-content .form-check input[type="radio"]+.form-check-label::before{width:20px;height:20px;border-radius:50%;top:-2px;}*/
  .filter-tab-content
    .form-check
    input[type="radio"]
    + .form-check-label::after {
    background: #fff;
    width: 8px;
    height: 8px;
    top: 4px;
    left: 6px;
  }
  .pickup-map a {
    color: #297ab9;
    text-decoration: underline;
    font-size: 16px;
  }
  .price-range-slider {
    margin-bottom: 20px;
  }
  .price-range-slider .irs--flat .irs-line {
    height: 2px;
    color: #e4e4e4;
  }
  .price-range-slider .irs--flat .irs-bar {
    height: 2px;
    background: #297ab9;
  }
  .price-range-slider .irs--flat .irs-handle {
    width: 20px;
    height: 20px;
    background: #fff;
    border: 2px solid #297ab9;
    border-radius: 50%;
    top: 15px;
    cursor: pointer;
  }
  .price-range-slider .irs--flat .irs-handle > i:first-child {
    display: none !important;
  }
  .price-range-slider .irs--flat .irs-min,
  .price-range-slider .irs--flat .irs-max {
    display: none;
  }
  .price-range-slider .irs--flat .irs-from,
  .price-range-slider .irs--flat .irs-to,
  .price-range-slider .irs--flat .irs-single {
    display: none;
  }
  .price-box .input-group {
    background: #f5f5f5;
    border: 1px solid #e4e4e4;
    height: 29px;
    width: 78px;
    color: #000;
  }
  .price-box .form-control {
    height: 100%;
    padding: 6px;
  }
  .price-box p {
    margin-bottom: 0;
  }
  .filter-tab-content .form-check img {
    margin-right: 5px;
  }
  .filter-modify-sidebar {
    width: 100%;
    max-width: 100% !important;
    height: calc(100vh - 38px);
    top: 38px;
  }
  .tab-modify-slidebar .tab-header a {
    top: 15px;
    right: 5px;
  }
  .grid-content.has-four-column .grid-item {
    width: 50%;
    padding: 15px; /*border:1px solid #E0E0E0;*/
    margin: 0;
    margin-bottom: 0;
    border-left: 0; /*border-bottom:0;*/
    display: flex;
    flex-flow: column;
  }
  .grid-content.has-four-column .grid-item:nth-child(even) {
    border-right: 0;
  }
  .grid-content.has-four-column .grid-hover-content {
    padding-top: 20px;
    margin-top: auto;
  }
  .grid-content.has-four-column .grid-price h4 {
    font-size: 16px;
    margin-right: 0;
  }
  .grid-content.has-four-column .grid-price .avability {
    font-size: 12px;
  }
  .grid-content.has-four-column .grid-details .grid-details-header h3 {
    line-height: 22px;
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    min-height: 44px;
  }
  .grid-content.has-four-column .grid-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .main-content .grid-content {
    padding-top: 0;
  }
  .fiter-list-container .list-img {
    width: 102px;
  }
  .fiter-list-container .list-details {
    width: calc(100% - 102px);
    padding-left: 20px;
  }
  .fiter-list-container .list-details-header h3 {
    font-size: 14px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .fiter-list-container .list-details-text {
    display: none;
  }
  .fiter-list-container .list-price h4 {
    font-size: 16px;
  }
  .fiter-list-container .list-price {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .fiter-list-container .list-price .avability {
    margin-bottom: 0;
  }
  .fiter-list-container .list-dropdown-box {
    flex-direction: row-reverse;
  }
  .fiter-list-container .list-dropdown-box .border-btn {
    margin-left: 10px;
  }
  .fiter-list-container ul.dots-list {
    margin-bottom: 18px;
  }
  .grid-icon-box {
    position: absolute;
    bottom: 28px;
    right: 28px;
  }
  .grid-hover-content .add-to-cart-dropdown .dropdown-menu,
  .list-dropdown-box .add-to-cart-dropdown .dropdown-menu {
    width: 100%;
  }
  .list-box {
    position: relative;
  }
  .list-box:before {
    content: "";
    width: calc(100% + 30px);
    height: 1px;
    background: #e0e0e0;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    right: 0;
  }
  .list-box:before {
    top: 0;
  }
  .list-price {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .list-details-header p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .list-bottom-details {
    padding-left: 15px;
    margin-top: 15px;
  }
  .list-bottom-details .list-price .avability {
    margin-bottom: 0;
  }
  .post-list li {
    width: 100%;
  }
  .post-container-content p {
    font-size: 16px;
    line-height: 26px;
  }
  .cta-content h3 {
    font-size: 16px;
    margin-bottom: 10px;
  }
  .cta-content p {
    font-size: 12px;
  }
  .cta-btn .btn {
    font-size: 12px;
    line-height: 1;
  }
  .grey-cta-container {
    padding: 28px 0 25px;
  }
  .grey-cta-box {
    padding-right: 20%;
  }
  .cta-btn {
    margin-top: 15px;
  }
  .tbc-btn {
    margin-top: 30px;
  }
  .ci-img-box {
    width: 100%;
    margin-left: 0;
  }
  .ci-img-box:before {
    content: none !important;
  }
  .hero-img-outer {
    margin-right: 0;
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .hero-img {
    max-width: 350px;
    width: 80%;
    margin-right: -25vw;
  }
  .blue-cta-container {
    padding: 28px 0 25px;
  }
  #footer1 {
    padding: 0;
  }
  #footer1 .footer-nav li {
    padding: 17.5px 0;
  }
  #footer1 .footer-content-left {
    padding: 12.5px 0;
  }
  #footer1 .footer-content-right {
    padding-bottom: 19px;
    position: relative;
    z-index: 1;
  }
  #footer1 .footer-content-right:before {
    content: "";
    position: absolute;
    width: calc(100% + 30px);
    margin-left: -15px;
    height: 100%;
    background: rgb(0 0 0 / 20%);
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: -1;
  }
  .hero-container {
    height: auto;
    padding: 0px 0 95px;
  }
  .intro-content .input-group .input-group-append {
    left: auto;
    top: calc(100% + 15px);
    width: 100%;
    height: 100%;
  }

  /*.intro-content{max-width:100%;}*/
  .intro-content h2 {
    font-size: 40px;
    line-height: 60px;
    margin-bottom: 8px;
  }

  /*.intro-content p{font-size:16px;line-height:1.5;}*/
  .intro-content .input-group {
    background: #fff;
    border-radius: 4px;
    margin-top: 28px;
  }
  .hero-container:before {
    display: none;
  }
  .hero-img-outer:before {
    display: block;
  }
  .add-shadow:after {
    mix-blend-mode: multiply;
    opacity: 0.3;
  }
  .tbc-text p,
  .post-heading-text p {
    font-size: 16px;
    line-height: 160%;
    margin-bottom: 25px;
  }
  .blue-cta-box .cta-content {
    max-width: 260px;
  }
  .hpc-heading-box h5 > span {
    display: block;
    font-size: 16px;
  }
  .hpc-heading-box > h5 {
    font-size: 16px;
  }
  .showall-link {
    font-size: 14px;
  }
  .tbc-img {
    padding-bottom: 59%;
  }
  .pll-img-box {
    width: 130px;
  }
  .pll-text-box {
    width: calc(100% - 130px);
  }
  .pll-text-box h5 + p {
    display: none;
  }
  .pll-text-box {
    padding: 18px;
  }
  .pll-item + .pll-item {
    margin-top: 10px;
  }
  .pgl-item {
    width: 50%;
  }
  .content-container.home-prduct-container {
    padding: 40px 0 0px;
  }
  .hpc-header h4 {
    font-size: 16px;
    margin-bottom: 0;
  }
  .hpc-header p {
    font-size: 16px;
    line-height: 160%;
    color: rgb(41 122 185 / 85%);
  }

  .empty-product-container {
    min-height: auto;
  }
  .epc-icon {
    width: 100px;
    margin: 0 auto;
  }
  .epc-box h4 {
    font-size: 20px;
    line-height: 25px;
  }
}

/* ======================================================
! Extra small devices (portrait phones, less than 576px)
====================================================== */
@media (max-width: 579.98px) {
  .member-left,
  .member-right {
    padding: 35px 25px;
  }
  .member-left h2 {
    font-size: 22px;
    line-height: 1.2;
    margin-bottom: 10px;
  }
  ul.check-list {
    margin-top: 20px;
  }
  ul.check-list li {
    line-height: 1.2;
  }
  ul.check-list li:not(:last-child) {
    margin-bottom: 15px;
  }
  .otp-box input {
    margin-right: 13px;
    text-align: center;
  }
  .mr-box h2 {
    font-size: 19px;
  }
  .mr-head svg {
    width: 20px;
  }
  #main {
    padding-bottom: 78px;
  }
  .search-bar-content .input-group:first-child {
    width: 100%;
  }
  .search-bar-content {
    width: calc(100% - 60px);
  }
  .view-type {
    width: 60px;
    display: none;
  }
  .search-bar-content .input-group:last-child {
    border-left: none;
    border-top: 1px solid #dbe3ff;
  }
  .search-bar-container {
    height: auto;
  }
  .list-img img,
  .grid-img img {
    margin-bottom: 25px;
  }
  .list-details {
    padding-left: 0;
    flex-flow: column;
  }
  .list-details-header {
    width: 100%;
    padding: 0 0 0 15px;
  }
  .list-dropdown-box {
    margin-top: 15px;
  }
  .list-dropdown-box .border-btn {
    margin-left: 0;
  }
  .result-item-number {
    padding-right: 0;
  }
  .list-price {
    margin-left: 0;
    text-align: left;
    padding: 0;
    margin-top: 15px;
    text-align: left !important;
  }
  .list-price h4,
  .grid-price h4 {
    margin-bottom: 0;
  }
  .list-price .avability {
    font-size: 12px;
    line-height: 16px;
  }
  .search-bar-content .input-group-text {
    padding-left: 15px;
  }
  .search-bar-content .input-group-text img {
    width: 18px;
  }
  .search-bar-content .input-group input {
    height: 40px;
  }
  .grid-item {
    width: 100%;
  }
  .grid-img {
    margin-bottom: 15px;
  }
  .search-bar-content {
    width: 100%;
  }
  .result-item-number .view-type {
    display: block;
    margin-top: 15px;
  }
  .result-item-number .view-type li {
    float: left;
  }
  .search-item {
    width: 100%;
  }
  .operation-list .operation-item {
    font-size: 13px;
  }
  .operation-list .operation-item .day {
    width: 45px;
  }
  .operation-list .timing-panel,
  .operation-list .operation-item .small {
    line-height: 18px;
  }
  .product-list ul .dropdown-menu.large {
    width: 310px !important;
  }
  .submenu-nev-item {
    margin: 0;
    padding: 0px 10px;
  }
  .submenu-nev-item a {
    font-size: 13px;
  }
  .submenu-nav-list {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .header-main .progressbar-box .progressbar {
    display: none !important;
  }
  .progressbar-container {
    display: block;
  }
  ul.progressbar li {
    padding: 0 50px 0 10px;
  }
  ul.progressbar li:not(:last-child):before {
    width: 40px;
  }
  .new-header .header-right .hdr-info {
    display: none !important;
  }
  .new-header .header-right img {
    margin-right: 11px;
  }
  .new-header .hdr-logout .dropdown-menu {
    right: -18px !important;
    left: auto;
  }
  .success-modal-popup .modal-content {
    padding: 20px 20px;
  }
  .smp-icon {
    width: 75px;
    margin: 0 auto 15px;
  }
  .smp-box h4 {
    font-size: 18px;
  }
  .smp-box h6 {
    font-size: 15px;
  }
  .cart-container {
    min-height: auto;
  }
  .table_wrap .table2 .dropdown-menu {
    min-width: 280px;
  }
  .address-box .ab-left,
  .address-box .ab-right {
    width: 100%;
  }
  .address-box .ab-left {
    margin-bottom: 25px;
  }
  .address-box .form-group.sidebar-btn {
    width: 100%;
    padding-right: 15px;
  }
  .custom-modal .modal-body {
    text-align: center;
  }
  .modal-icon-box {
    margin: 30px auto;
  }
  .modal-btn-box .btn + .btn {
    margin: 15px 0 0;
  }
  .modal-btn-box {
    display: flex;
    flex-flow: column;
    align-items: center;
  }
  .custom-modal .modal-title {
    font-size: 16px;
    line-height: 1.2;
  }
  .custom-modal .modal-header {
    padding: 15px 15px;
    height: 60px;
  }
  .custom-modal .close {
    right: 15px;
    width: 22px;
    top: 15px;
  }
  .custom-modal .modal-body {
    padding: 35px 15px;
  }
  .custom-modal .modal-footer {
    padding: 15px;
  }
  .file-upload-modal .modal-btn-box {
    margin-top: 60px;
  }
  .filter-tags {
    width: 100%;
  }
  .basic-addon-box img + span {
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    max-width: 190px;
  }
  .grid-icon-box {
    position: absolute;
    bottom: 14px;
    right: 14px;
  }
  .product-color span,
  .size-config span {
    width: 50px;
  }
  .size-choose button + button {
    margin-left: 5px;
  }
  .size-choose button {
    padding: 8px 18px;
  }
  .color-choose div + div {
    margin-left: 5px;
  }
  .grey-cta-container .cta-bg {
    right: -30.5%;
    width: 82%;
  }
  .blue-cta-container .cta-bg {
    left: -6%;
    width: 144%;
  }
  .hpc-product-list-container {
    border-top: 1px solid #e0e0e0;
  }
  .add-to-cart-dropdown button > img {
    position: relative;
    right: auto;
    top: auto;
    transform: none;
    display: inline-block;
  }
  .product-gridview-list .slick-list {
    padding-right: 111px !important;
  }
  .add-button-outer {
    font-size: 12px;
    line-height: 18px;
  }
  .pgl-item {
    width: 100%;
  }
  .product-gridview-slider.product-gridview-list {
    width: calc(100% + 37px);
    margin-left: -18.5px;
  }
  .product-gridview-slider .slick-list.draggable {
    padding: 30px 0 30px 12px;
  }
  .mob-full-width {
    width: calc(100% + 30px) !important;
    margin-left: -15px !important;
  }
}
@media (min-width: 579.98px) {
  .no-sm-border .grid-item {
    border: 0 !important;
  }
}
@media (max-width: 480.98px) {
  .feedback_ratings {
    margin-bottom: 25px;
  }
  .feedback_rating {
    font-size: 14px;
    width: 42px;
    height: 35px;
  }
  .review-box h6,
  .review-box p {
    font-size: 14px;
  }
  .sidebar {
    width: 100%;
    left: -100%;
  }
  ul.import-details-list li {
    width: 100%;
    padding: 0;
  }
  ul.import-details-list li:not(:last-child) {
    margin-bottom: 15px;
  }
  ul.import-details-list {
    margin-top: 30px;
  }
  .mob-filter-button {
    padding: 12px 10px;
  }
  .new-form-check .form-check-label {
    padding: 9.62px 4px;
  }
  .mob-filter-button > img {
    margin-right: 5px;
  }
}
@media (max-width: 390.98px) {
  .intro-content h2 {
    font-size: 30px;
    line-height: 1.4;
  }
  .intro-content p {
    font-size: 14px;
    line-height: 1.5;
  }
  .obh-right {
    text-align: left;
  }
  .tbc-text p,
  .post-heading-text p {
    font-size: 14px;
    line-height: 160%;
  }
  .hpc-heading-box h5 > span {
    display: block;
    font-size: 14px;
  }
  .hpc-heading-box > h5 {
    font-size: 14px;
  }
  .pll-box .add-button-outer {
    bottom: 60px !important;
  }
  .filter-nav.d-flex.flex-wrap {
    flex-wrap: nowrap !important;
    text-align: center;
    white-space: nowrap;
    overflow-x: auto;
    padding: 10px 15px;
  }
  .new-form-check .form-check-label {
    min-width: 98px;
  }
  .mob-filter-button {
    min-width: 100px;
  }
  .filter-nav-row {
    width: calc(100% + 30px);
    margin-left: -15px;
  }
  .filter-nav-column {
    padding: 12.5px 0;
  }
  .sortby-row {
    padding-left: 15px;
  }
}
