.seller-info .seller-img.custom-seller-img {
  width: 120px !important;
  height: auto !important;
}

.editable .star-icon {
  margin-left: 15px !important;
}

.table-responsive.expand-table {
  overflow-x: unset !important;
}

.ab-left.shipping-address {
  margin-bottom: 53px;
}

.table-responsive .small.table tr td:first-child span.wishlist-product-image {
  width: 54px;
}

.custom-options {
  padding: 13px 7px 5px !important;
}

.table2 tr td.tax-total {
  padding: 16px 5px 20px;
}

.mr-box h2 {
  line-height: 1.5 !important;
}

.table2 thead th.custom-table-head {
  padding: 0px 5px 15px;
}

.table-responsive.overflow-auto {
  overflow: auto;
}

.table-responsive.overflow-visible {
  overflow: visible;
}

.dropdown-menu.show.custom-styles {
  position: absolute;
  transform: translate3d(0px, -192px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.country-code {
  width: 100% !important;
  height: 50px !important;
  border: 1px solid #e0e0e0 !important;
  border-radius: 8px !important;
  font-size: 16px !important;
  padding: 5px 55px !important;
  color: #333 !important;
  letter-spacing: 0.5px !important;
}

.mobile-error {
  font-size: 12px;
  line-height: 16px;
  font-weight: 400;
  letter-spacing: 0.4px;
  color: #ff0c3e;
  margin-bottom: 0;
}

.setting-dropdown {
  width: auto;
  min-width: 10em;
  display: block;
  max-width: 20em;
  top: auto;
  left: 0px;
  margin-left: 0px;
  margin-top: 0px;
  z-index: 1001;
}

.custom-update {
  right: -150px !important;
}

.custom {
  right: -135px !important;
}

.update {
  top: 17% !important;
}

.back-icon {
  cursor: pointer;
}

.form-check
  input[type="checkbox"].form-check-input.custom-checkbox:checked
  + .form-check-label::before {
  border-color: #297ab9 !important;
  background: #297ab9 !important;
}

.hdr-logout .logout-profile-img {
  border-radius: 20%;
}

.seller-profile-img {
  border-radius: 10px;
}

.edit-image {
  display: none;
}

.form-control:disabled,
.form-control[readonly] {
  background-color: #fff;
}

.mt-4 {
  margin-top: 2.4rem !important;
}
.Toastify__toast-container {
  width: auto !important;
}
.alert.alert-white {
  border-radius: 4px !important;
}
.fa-exclamation-circle.alert-red {
  color: #ba0303 !important;
}

.question-box {
  border: 0;
}

button:focus.question-box {
  outline: 0px;
  outline: none;
  background-color: none;
}

.input-data {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.input-data:read-only {
  color: #797979 !important;
}
.fetch-btn {
  font-size: 13px;
  letter-spacing: 0.5px;
  color: #828282 !important;
  text-transform: uppercase;
}

/* .input-group {
  height: 50px;
} */
.question-mark {
  display: none;
}

.custom-pop-up.show {
  display: block;
  padding-right: 16px;
}

.hide {
  display: block;
}
.table-responsive .table tr td:first-child span {
  background-color: #fff;
}
.modal-content {
  padding: 0px 20px;
}
.reset {
  margin-right: 480px;
  cursor: pointer;
}

.active.custom .nav-link {
  color: #297ab9 !important;
}

.custom-dropdown {
  z-index: 1;
  transition: all 0.2s ease-in;
  opacity: 10;
  visibility: visible;
  top: calc(100% - 55px);
}

.modal.custom-pop-up {
  z-index: 999999999;
}

.refund-red {
  color: red;
}

.fa-star.custom-star {
  color: rgb(255, 165, 30);
}

.Toastify__toast-container {
  z-index: 1000000000 !important;
}
.reviews-text.custom-rating {
  cursor: pointer !important;
  top: 2px;
  position: relative;
}

.seller-profile-name {
  margin-left: 0 !important;
}

.table-dropdown button.dispute-btn {
  font-size: 16px !important;
  color: #fff !important;
  background-color: #297ab9 !important;
  border-color: #297ab9 !important;
}

.resolved-text.green-imp {
  color: #27ae60 !important ;
}

.symbol {
  display: inline-block;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;
  position: relative;
  border-radius: 0.42rem;
}

.symbol.symbol-40 > img {
  width: 100%;
  height: 40px;
}
@media (min-width: 1400px) {
  .symbol > img {
    width: 100%;
    height: 50px;
  }
}
@media (min-width: 1200px) {
  .symbol > img {
    width: 100%;
    height: 50px;
  }
}
@media (min-width: 992px) {
  .symbol > img {
    width: 100%;
    height: 50px;
  }
}
@media (min-width: 768px) {
  .symbol > img {
    width: 100%;
    height: 50px;
  }
}
@media (min-width: 576px) {
  .symbol > img {
    width: 100%;
    height: 50px;
  }
}
.symbol > img {
  width: 100%;
  height: 50px;
}
.symbol > img {
  display: -webkit-flex;
  display: flex;
  border-radius: 0.42rem;
}

.table-dropdown button.dispute-btn:hover {
  /* color: #fff !important; */
  background-color: #205e8f !important;
  border-color: #297ab9 !important;
}

ul.tpi-list.custom-img li > img {
  width: 30px !important;
  margin-bottom: 12px;
}

.country-code-input {
  max-width: 40% !important;
}

.table-dropdown button.dispute-btn {
  font-size: 16px !important;
  color: #fff !important;
  background-color: #297ab9 !important;
  border-color: #297ab9 !important;
}

.resolved-text.green-imp {
  color: #27ae60 !important ;
}
.table-dropdown button.dispute-btn:hover {
  /* color: #fff !important; */
  background-color: #205e8f !important;
  border-color: #297ab9 !important;
}

ul.tpi-list.custom-img li > img {
  width: 30px !important;
  margin-bottom: 12px;
}

.card-body h6 {
  font-size: 16px;
  margin-bottom: 0px;
  color: #4f4f4f;
}

.card-body p {
  color: #4f4f4f;
}

@media (min-width: 768px) {
  .mobile-num {
    flex: 0 0 60% !important;
    max-width: 75% !important;
  }
}

@media (max-width: 768px) {
  .home-filter {
    margin: 0px !important;
    margin-top: 15px !important;
    margin-left: 0px !important;
    width: 100%;
  }
}

.custom-policy {
  margin-top: 20px;
}

/* .react-datepicker__input-container {
  width: 308px !important;
} */

.custom-col {
  width: 340px;
}

/* .tab-modify-slidebar {
  max-width: 800px !important;
} */

.new-header ul.navbar-nav .nav-link {
  color: #000;
}

.custom-top {
  padding: 10px 0px !important;
}

.search {
  border: 0px !important;
}
h4 {
  line-height: 36px;
  letter-spacing: 0.2px !important;
}

.custom-input.on .form-control,
.custom-input.show .form-control {
  padding: 5px 15px;
}

.loader-icon,
.loader-icon-blue {
  height: 15px;
}

.termsConditions {
  display: inline;
  color: #297ab9;
  text-decoration: underline;
}

.form-group > input.password + span.custom-hide-show {
  z-index: 0 !important;
}

.form-group > input.error + span.custom-hide-show {
  top: 26px !important;
}

.image {
  text-align: left;
}
.btn-upload,
.btn-upload:hover {
  line-height: 48px;
}
@media (max-width: 991.98px) {
  .map {
    height: auto !important;
  }
}
.grid-price .avability {
  padding-top: 8px;
}
.product-popup-left {
  margin-right: 15px;
}
.submenu-nev-item a {
  color: #297ab9;
}
.autocomplete-dropdown-container {
  width: 100%;
  position: absolute;
  left: 0;
  top: 100%;
  background: #fff;
  max-height: 300px;
  z-index: 999;
  padding: 0;
  overflow-y: auto;
  box-shadow: 0 5px 5px rgb(0 0 0 / 10%);
}
.autocomplete-dropdown-container .suggestion-item,
.autocomplete-dropdown-container .suggestion-item--active {
  padding: 5px 15px;
}

.markerText {
  font-size: 16px;
  font-weight: 400 !important;
  margin-bottom: 0px !important;
}
@media (max-width: 991.98px) {
  .member-container {
    height: auto;
    flex-flow: column;
    padding-top: 0px;
  }
}
.grid-img {
  height: 260px !important;
}

.thumbnail-list .thumbnail-item {
  width: 80px;
}
.hours-show {
  transform: translate3d(-138px, -281px, 0px);
  top: 0px;
  left: 0px;
}
.product-hors {
  padding-right: 0px !important;
}
/* ul:not([class]) > li:before {
  background: #fff;
} */

.slick-list {
  height: 100%;
}
.product-img {
  height: 100% !important;
}
.home-services-list {
  position: relative;
}
.thumbnail-list {
  margin-top: 34px;
}

.thumbnail-item > img {
  max-height: 100% !important;
}
.product-slide > img {
  max-height: 100% !important;
  cursor: pointer;
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-slide > div {
  height: 100%;
}
.product-slide {
  max-height: 100%;
}
.slick-track {
  height: 100%;
}

/* .grid-img img {
  max-height: 250px !important;
} */
.listImage {
  max-height: 40px !important;
}

.btn-cancelled {
  color: #297ab9 !important;
}
.result-item-number .countNumber {
  margin-top: 40px !important;
}

.dropdown-menu.deliveryOptions.show {
  position: absolute;
  transform: translate3d(0px, 26px, 0px);
  top: 0px;
  left: 0px;
  will-change: transform;
}

.b-disable {
  opacity: 0.2;
}
.empty-cart-content h4 {
  font-weight: 600;
  font-size: 24px;
  line-height: 30px !important;
  color: #297ab9;
}
.ReactModal__Overlay {
  z-index: 9999999 !important;
}

.table2 tr td:first-child span {
  display: inline !important;
}
.table-responsive .small.table tr td:first-child span {
  display: inline;
}
.has-map .header-right-content {
  width: 100px !important;
  margin-left: auto;
}
.form-check-outer .form-check input[type="checkbox"] + label {
  color: #000;
  font-size: 14px !important;
}
.highIndex {
  z-index: 999999999 !important;
}
@media (max-width: 768px) {
  .mobile-filters-home.form-check {
    width: 100%;
    margin: 0px;
    margin-top: 15px;
  }
}
@media (max-width: 768px) {
  .mobile-filters-home.form-check {
    width: 100%;
    margin: 0px;
    margin-top: 15px;
  }
}
.contact-slidebar-container {
  z-index: 9999999999;
}
.tags span {
  margin-right: 6px;
}
.btn-success:not(:disabled):not(.disabled).active,
.btn-success:not(:disabled):not(.disabled):active,
.show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #2979b9 !important;
  border-color: #2979b9 !important;
}
.btn-success.disabled,
.btn-success:disabled {
  color: #fff;
  background-color: #8b8b8b !important;
  border-color: #f8d1d1 !important;
}
.page-item.disabled .page-link {
  color: #6c757d !important;
}
.grid-hover-content {
  padding-top: 25px;
  /* padding-right: 15px; */
}
select#sortBy {
  max-width: 150px !important;
}
.list-box {
  padding-right: 15px;
}
.sm-link {
  font-size: 14px !important;
}
.ism-link {
  font-size: 14px !important;
}

@media (max-width: 579.98px) {
  #main {
    padding-bottom: 83px;
  }
}
#main {
  min-height: calc(100vh- 210px);
}
body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}
.price-mm-box .form-control {
  background: #fff;
  border-radius: 2px;
  border: 1px solid rgba(228, 228, 228, 0.8);
  border-left: auto;
  padding: 10px;
  height: 42px;
}
.autocomplete-dropdown-container {
  margin-top: 6px !important;
  top: 100% !important;
}
/* .autocomplete-div {
  margin-bottom: 20px !important;
} */
.seller-info .seller-img.custom-seller-img.sellerVideo {
  width: 240px !important;
}

.seller-img .editable {
  top: -8px;
  right: -8px;
}
/* .slick-dots li.slick-active button,
.slick-dots li:hover button {
  opacity: 0 !important;
  background-color: #000 !important;
} */
.slick-dots li button {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
}

.slick-dots li button {
  width: 0 !important;
  height: 0 !important;
  color: #297ab9 !important;
}
.mdc-media-box {
  padding-bottom: 0;
}
.pricing {
  border-bottom: 1px solid #fff;
}
.pricing:hover {
  color: inherit;
}
/* .form-group {
  margin-bottom: 1px;
} */
@media (min-width: 1000px) {
  .basic-addon-box {
    width: 500px;
  }
}
.grey-cta-container {
  color: #000;
}
.grid-details .grid-details-header h3 {
  min-height: 30px;
}
.hero-img-outer:before {
  width: auto !important;
}
.intro-content .form-control::placeholder {
  color: #000;
}
.intro-content .input-group img {
  opacity: 1;
}
.grey-cta-inner > div {
  width: 100%;
  height: 100%;
}
.cta-inner > div {
  width: 100%;
  height: 100%;
  text-align: center;
}
.cta-inner > div svg {
  width: 100% !important;
}

@media (max-width: 768.98px) {
  .add-to-cart-dropdown .dropdown-menu {
    width: 100vw;
    min-width: 100%;
    border-radius: 5px;
    left: 0px !important;
    z-index: 9999;
    background: #fff;
    padding: 10px 0;
  }
}
.color-change {
  color: #000 !important;
}
.day {
  color: #000 !important;
}
.date {
  color: #000 !important;
}
.autocomplete-dropdown-container {
  border-radius: 4px !important;
}
.input-group {
  margin-bottom: 5px;
}
.vid-width {
  width: 480px;
}
@media (max-width: 768px) {
  .product-details {
    padding: 0px 0 20px 0;
  }
  .breadcrumbs li a {
    margin-top: 40px !important;
    padding: 0 !important;
  }
  .vid-width {
    width: 280px;
  }
}
.rate-product {
  cursor: pointer;
  font-size: 12px;
  font-weight: 400;
  color: #297ab9;
  letter-spacing: 0.5;
}
.rate-product:hover {
  text-decoration: underline;
}
.toggled-tab .dashboard-container .tab-modify-slidebar {
  z-index: 999;
}
.link-text {
  color: #297ab9;
}
.link-text:hover {
  text-decoration: underline;
}
@media (max-width: 768.98px) {
  .hero-img {
    margin-right: -20vw !important;
  }
}
body.toggled-tab::before {
  z-index: 999 !important;
}
@media (max-width: 1199.98px) {
  .new-header .carets {
    display: block;
  }
}
@media (max-width: 767px) {
  .new-header .carets {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.product-gridview-slider .slick-list {
  padding: 30px 0;
}
.slick-track {
  width: 2492px;
  transform: translate3d(-1068px, 0, 0);
}
.add-custom-slick-arrow .slick-prev:hover,
.add-custom-slick-arrow .slick-next:hover {
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: blur(16px);
  filter: drop-shadow(0px 2px 30px rgba(0, 0, 0, 0.07));
}
/* .slick-slide {
  width: 356px !important;
} */
@media (max-width: 590px) {
  .slick-slide {
    width: 270px !important;
  }
}

.pgl-sub-text span {
  transition: all 0.2s ease-in;
}
.pgl-sub-text span:hover {
  color: #0c4475 !important;
}

.input-group input:focus {
  background: transparent !important;
}

[aria-label="Previous page"],
a[aria-label="Next page"] {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #0c4475;
  display: flex;
  align-items: center;
}
[aria-label="Previous page"] {
  padding-right: 25px;
}
[aria-label="Next page"] {
  padding-left: 25px;
}

@media (max-width: 768px) {
  [aria-label="Previous page"] {
    padding-right: 2px;
  }
  [aria-label="Next page"] {
    padding-left: 2px;
  }
}

.reviews-text {
  vertical-align: -webkit-baseline-middle;
}
@media (min-width: 767px) {
  .input-group-inside {
    position: relative;
  }
}
